import React  from 'react';
import { useMsal, useAccount } from "@azure/msal-react";
import jQuery from 'jquery';
import * as Utils from '../lib/utils';

import { MsalContext } from "@azure/msal-react";

window.jQuery = jQuery;
require("jsgrid");

class SalaryHistory extends React.Component{
    static contextType = MsalContext;

    constructor(props) {
        super(props);
        // console.log("MsalContext", MsalContext);

        this.gridRef = React.createRef();

        const urlParams = Utils.getParams();
        // console.log("urlParams", urlParams);

        this.state = {
            employeeId : urlParams.id
        }
    }

    render() {
        return "Viewing history for " + this.state.employeeId;
    }
}  

export default SalaryHistory;