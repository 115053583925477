import React, { useState, useMemo, useEffect }  from 'react';
import { useMsal, useAccount } from "@azure/msal-react";
import { MaterialReactTable } from 'material-react-table';
import jQuery from 'jquery';
import * as Utils from '../lib/utils';
import { protectedResources } from "../config/authConfig";
import CONFIG from '../config';

window.jQuery = jQuery;
require("jsgrid");


const debounce = function(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}

const Budget = () => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [dimensions, setDimensions] = useState({ 
      height: window.innerHeight,
      width: window.innerWidth
    });
    
    const [tableData, setTableData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);    
    const [rowCount, setRowCount] = useState(0);

    const urlParams = Utils.getParams();
    const effectiveDate = urlParams.effectiveDate;
    const adjustmentUuid = urlParams.adjustmentUuid;
    const adjustmentStatus = urlParams.status;
    // console.log("urlParams", urlParams);

    const fetchData = async () => {
      if (!tableData.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      try {

        const jsonResponse = await Utils.getData(
          ('/api/budget'), 
          instance, 
          account
        );

        setTableData(jsonResponse);
        setRowCount(jsonResponse.length);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };

    useEffect(() => {
      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      const debouncedHandleResize = debounce(function handleResize() {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth
        })
      }, 500)

      //binds a resize listener for a debounced (less frequent) resize effect
      window.addEventListener('resize', debouncedHandleResize);
      
      //unbinds listener to prevent memory leaks
      return _ => {
        window.removeEventListener('resize', debouncedHandleResize)   
      };
    });

    const triggerLink = (linkId, noToken)=>{
      instance.acquireTokenSilent({
        scopes: protectedResources["apiCompensation"].scopes,
        account: account
      }).then((msalAuthorization)=>{
        //add the token
        let selectedLink = document.getElementById(linkId).href
        if(!noToken){
          selectedLink = selectedLink + "&token=" + (msalAuthorization ? msalAuthorization.accessToken : "")
        } 
        
        //set the link handler
        document.getElementById("linkHandler").href = selectedLink;
        
        //click the link
        document.getElementById("linkHandler").click()
      });
    }  
    

    //should be memoized or stable
    const columns = useMemo(
      () => [
        {
          accessorKey: 'view_employee',
          header: 'View Adjustments',
          Cell: ({ cell, row }) => {
            // console.log("cell", cell, row);
            return <div>
              <a 
                href={'/compensation?effectiveDate='+row.original.effective_date.substring(0,10)+'&managerHrisId=' + row.original.employee_hris_id}>
                  View Adjustments
                </a>
              </div>
          },
          enableEditing: false,
          columnDefType: 'display',
          enablePinning: false
        },
        {
          accessorFn: (row) => `${Utils.maskDateAs("mdy", new Date(row.effective_date))}`,
          accessorKey: 'effective_date',
          header: 'Effective Date',
          enableEditing: false,
        },
        {
          accessorFn: (row) => `${Utils.decodeHTML(row.first_name)} ${Utils.decodeHTML(row.last_name)}`,
          accessorKey: 'manager_name',
          header: 'Manager Name',
          enableEditing: false
        },
        {
          accessorFn: (row) => {
            // console.log("row", row)
            let employeeListing = row.adjustment_employee_list;
            employeeListing.sort();
            return Utils.decodeHTML(employeeListing.join(", "));
          },
          accessorKey: 'employee_list',
          header: 'Employees',
          enableEditing: false,
        },        
        {
          accessorKey: 'budget',
          header: 'Total Budget',
          enableEditing: true,
        },
        {
          accessorKey: 'adjustment_reports',
          header: '# of Requests',
          enableEditing: false,
        },
      ],
      [],
    );


    //saves the manager to the DB and updates the client view
    function saveBudget(action, originalRow, employee){
      // console.log("action", action, "originalRow", originalRow, "employee", employee);
      let data = {
        manager_id: originalRow.id,
        adjustment_uuid: "",
        manager_budget: employee.budget,
        effective_date: originalRow.effective_date.substring(0,10)
      };

      Utils.postData( "/api/budget", data, instance, account ).then((data) => { 
        return false;
      });

      return false;
    }


    // const uploadManagerBudgets = () => {
    //   const formData = new FormData();
    //   formData.append('file', selectedFile);
  
    //   Utils.postFile(
    //     "/api/budget/upload", 
    //     formData,
    //     instance,
    //     account
    //   ).then((result) => {
    //     alert("Salary locations uploaded");
    //     closeBulkModal();
    //     fetchData();
    //   });
    //   return false;
    // }    


    const handleSaveRow = async ({ exitEditingMode, row, values, table }) => {
      // let data = table.getState().tableData; //deprecated
      let data = tableData;
      data[row.index].budget = values.budget;
      setTableData([...data]);
      saveBudget("edit", row.original, values);
      exitEditingMode();
    };
    
    const tableHeightValue =  window.innerHeight > 183 ? window.innerHeight - 183 : 300;
    const tableHeightCSS = tableHeightValue + "px";

    return <MaterialReactTable 
        renderTopToolbarCustomActions={({ table }) => {
          return <div>
            <span className="sectionTableHeader">Manager Budgets</span>
            &nbsp;&nbsp;&nbsp;
            <a 
                id={"downloadBudgets"}
                href={ CONFIG.API_HOST + "/api/budget?d=true&f=xl" }
                onClick={(event)=>{
                  event.preventDefault()
                  triggerLink("downloadBudgets");
                  return false;
                }}
            >
              Download Budgets
            </a> 

            &nbsp;&nbsp;&nbsp;
            <a href="#" onClick={()=>{
                return false;
            }}>Upload Budgets</a>    
  
            <a href="#" id="linkHandler" style={{ visibility:"hidden" }}></a>
          </div>
        }}
        columns={columns} 
        data={tableData}
        globalFilterFn="contains" 
        enableStickyHeader
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: 'error',
                children: 'Error loading data',
              }
            : undefined
        }        
        muiTableContainerProps={{ sx: { height: tableHeightCSS } }}
        rowCount={rowCount}
        enableEditing
        editDisplayMode="row"
        onEditingRowSave={handleSaveRow}        
        state={{
          isLoading,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
          tableData: tableData
        }}
      />;
};

export default Budget;