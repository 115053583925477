import React, { useState, useMemo, useEffect }  from 'react';
import Modal from 'react-modal';
import { useMsal, useAccount } from "@azure/msal-react";
import { MaterialReactTable } from 'material-react-table';
import Datetime from 'react-datetime';
import moment from 'moment';
import jQuery from 'jquery';
import * as Utils from '../lib/utils';

import "react-datetime/css/react-datetime.css";
// import "../index.css";

window.jQuery = jQuery;
require("jsgrid");

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height:"400px",
    width: "600px"
  },
};

const debounce = function(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}


const Reviewers = () => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [dimensions, setDimensions] = useState({ 
      height: window.innerHeight,
      width: window.innerWidth
    });
    
    const [tableData, setTableData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);    
    const [rowCount, setRowCount] = useState(0);

    const [modalReviewerIsOpen, setModalReviewerIsOpen] = useState(false);
    const [revieweeValue, setRevieweeValue] = useState("");
    const [reviewerValue, setReviewerValue] = useState("");

    const [selectedRevieweeType, setSelectedRevieweeType] = useState("title");
    const [selectedReviewerType, setSelectedReviewerType] = useState("title");

    let subtitle;

    function openNewReviewerModal(employeeObject, reviewersObject) {
      // console.log("employeeObject", employeeObject);
      // setSelectedReviewerEmployee(employeeObject);
      // setSelectedReviewers(reviewersObject)
      setModalReviewerIsOpen(true);
    }     
    function afterOpenReviewerModal() {
      // references are now sync'd and can be accessed.
      // subtitle.style.color = '#f00';
    }
    function closeReviewerModal() {
      setModalReviewerIsOpen(false);
    }

    const fetchData = async () => {
      if (!tableData.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      try {

        const jsonResponse = await Utils.getData(
          ('/api/reviewer_rules'), 
          instance, 
          account
        );

        setTableData(jsonResponse);
        setRowCount(jsonResponse.length);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };

    useEffect(() => {
      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      const debouncedHandleResize = debounce(function handleResize() {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth
        })
      }, 500)

      //binds a resize listener for a debounced (less frequent) resize effect
      window.addEventListener('resize', debouncedHandleResize);
      
      //unbinds listener to prevent memory leaks
      return _ => {
        window.removeEventListener('resize', debouncedHandleResize)   
      };
    });



    //should be memoized or stable
    const columns = useMemo(
      () => [   
        {
          accessorKey: 'delete_reviewer',
          header: 'Delete Reviewer',
          Cell: ({ cell, row }) => {
            return <div><a href="#" onClick={(event)=>{
              event.preventDefault();
              // console.log("EVENT", event, cell, row );
              if(window.confirm("Are you sure you would like to delete this group location?")){
                deleteReviewerRule(cell.row.original.id);
              }
              return false;
            }}>Delete</a></div>
          },
          enableEditing: false,
          columnDefType: 'display',
          enablePinning: false
        },         
        {
          accessorKey: 'reviewee_type',
          header: 'Reviewee Type',
          enableEditing: true
        },
        {
          accessorKey: 'reviewee_value',
          header: 'Reviewee Value',
          enableEditing: true
        },
        {
          accessorKey: 'reviewer_type',
          header: 'Reviewer Type',
          enableEditing: true
        },
        {
          // accessorFn: (row)=>`${
          //   row.reviewer_value === "title" ? 
          //     (<div>{row.employee_name} ({row.reviewer_value})</div>) :          
          //     (<div>{row.reviewer_value} ({row.employee_name})</div>)              
          // }`,      
          accessorKey: 'reviewer_value',
          header: 'Reviewer Value',
          enableEditing: true,
          Cell: ({ cell, row }) => {
            if(row.original.reviewer_value === "title"){
              return <div>{row.original.employee_name} ({row.original.reviewer_value})</div>              
            }else{
              return <div>{row.original.reviewer_value} ({row.original.employee_name})</div>              
            }
          },          
        },
      ],
      [],
    );
    

    const deleteReviewerRule = (reviewerRuleId) => {

      Utils.deleteData(
        "/api/reviewer_rules/" + reviewerRuleId, 
        instance, 
        account
      ).then((data) => {
        alert(data.message);

        if(data.result === "SUCCESS"){
          fetchData();
        }
      });

  }

    const handleSelectRevieweeType = (option)=> {
      // console.log("option", option);
      const selectedManager = option.value
      setSelectedRevieweeType(selectedManager);
      return false;
    };

    const handleSelectReviewerType = (option)=> {
      // console.log("option", option);
      const selectedManager = option.value
      setSelectedReviewerType(selectedManager);
      return false;
    };

    const addNewReviewer = ()=>{
      // console.log("revieweeValue", revieweeValue);
      // console.log("reviewerValue", reviewerValue);
  
      // console.log("selectedRevieweeType", selectedRevieweeType);
      // console.log("selectedReviewerType", selectedReviewerType);

      Utils.postData(
        ('/api/reviewer_rules'),
        {
          reviewee_type : selectedRevieweeType,
          reviewee_value : revieweeValue,
          reviewer_type : selectedReviewerType,
          reviewer_value : reviewerValue
        }, 
        instance, 
        account
      ).then((result)=>{

        closeReviewerModal();
        fetchData();

      });

      return false;
    };

    
    //saves the manager to the DB and updates the client view
    const saveReviewer = (originalRow, reviewer) => {
      // console.log("originalRow", originalRow, "reviewer", reviewer);
      let data = {};

      Utils.putData( ("/api/reviewer_rules/"+originalRow.id), reviewer, instance, account ).then((data) => { 

        fetchData();

        return false;
      });

      return false;
    }

    const handleSaveRow = async ({ exitEditingMode, row, values, table }) => {
      // let data = table.getState().tableData; //deprecated
      let data = tableData;
      setTableData([...data]);
      saveReviewer(row.original, values);
      exitEditingMode();
    };
    
    const tableHeightValue =  window.innerHeight > 183 ? window.innerHeight - 183 : 300;
    const tableHeightCSS = tableHeightValue + "px";

    return <div>
        <Modal
          isOpen={modalReviewerIsOpen}
          onAfterOpen={afterOpenReviewerModal}
          onRequestClose={closeReviewerModal}
          style={modalStyles}
          contentLabel="Manage Reviewers"
          ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
        >
          <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
            Add a new reviewer
          </h2>
          <div className="modalBody">
            <div>
              <span className="add_reviewer_title">Reviewee Type</span>
              <select name='reviewee_type' value={selectedRevieweeType} onChange={(event)=>{
                  event.preventDefault();
                  // console.log("event", event.target.value)
                  handleSelectRevieweeType(event.target);
                  return false;
              }}>
                <option key={Utils.guid()} value="title">Title</option>
                <option key={Utils.guid()} value="budgeted office">Budgeted Office</option>
                <option key={Utils.guid()} value="other">Other</option>
              </select>
            </div>
            <div>
              <span className="add_reviewer_title">Reviewee Value</span>
              <input name="reviewee_value" id="reviewee_value" defaultValue={revieweeValue} onChange={evt => setRevieweeValue(evt.target.value)} />
            </div>
            <div>
              <span className="add_reviewer_title">Reviewer Type</span>
              <select name='reviewer_type' value={selectedReviewerType} onChange={(event)=>{
                  event.preventDefault();
                  // console.log("event", event.target.value)
                  handleSelectReviewerType(event.target);
                  return false;
              }}>
                <option key={Utils.guid()} value="title">Title</option>
                <option key={Utils.guid()} value="discipline leader">Discipline Leader</option>
                <option key={Utils.guid()} value="assitant discipline leader">Assitant Discipline Leader</option>
                <option key={Utils.guid()} value="group leader">Group Leader</option>
                <option key={Utils.guid()} value="assitant group leader">Assitant Group Leader</option>
              </select>
            </div>
            <div>
             <span className="add_reviewer_title">Reviewer Value</span>
             <input name="reviewer_value" id="reviewer_value" defaultValue={reviewerValue} onChange={evt => setReviewerValue(evt.target.value)} />
            </div>
            <div>&nbsp;</div>
          </div>
          <div className="modalFooter">
            <button onClick={closeReviewerModal}>Close</button>
            <button onClick={addNewReviewer}>Save</button>
          </div>
        </Modal>        

        <MaterialReactTable 
          columns={columns} 
          data={tableData}
          globalFilterFn="contains" 
          enableStickyHeader
          enableEditing
          positionToolbarAlertBanner="bottom"
          renderTopToolbarCustomActions={({ table }) => {
            return <div>
              <button onClick={openNewReviewerModal}>
                Add new reviewer
              </button>
             </div> 
          }}
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: 'error',
                  children: 'Error loading data',
                }
              : undefined
          }
          muiTableContainerProps={{ sx: { height: tableHeightCSS } }}
          rowCount={rowCount}
          editDisplayMode="row"
          onEditingRowSave={handleSaveRow}        
          state={{
            isLoading,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            tableData: tableData
          }}
        />
      </div>;
};

export default Reviewers;