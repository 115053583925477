import React, { useState, useMemo, useEffect }  from 'react';
import { useMsal, useAccount } from "@azure/msal-react";
import jQuery from 'jquery';
import * as Utils from '../lib/utils';
import { protectedResources } from "../config/authConfig";
import CONFIG from "../config/index.js";

window.jQuery = jQuery;
require("jsgrid");
  
// const hostUrl = (window.location.host.indexOf("localhost") > -1 ? "http://" : "https://") + window.location.host.replace("3000", "8080");
// console.log("CONFIG", CONFIG)

const EmployeeReports = (props) => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
 
  const [tableData, setTableData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);    
  const [rowCount, setRowCount] = useState(0);

  const [msalAuthorization, setMsalAuthorization] = useState("");
  
  // console.log("props", props);
  let employeeHrisId = null;
  if(props && props.userAccount && props.userAccount.employee_hris_id){
    employeeHrisId = props.userAccount.employee_hris_id;
  }

  const fetchData = async () => {
    if (!tableData.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    try {

      const authResp = await instance.acquireTokenSilent({
        scopes: protectedResources["apiCompensation"].scopes,
        account: account
      });
      setMsalAuthorization(authResp);
      
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  
  const triggerLink = (linkId, noToken)=>{
    
    instance.acquireTokenSilent({
      scopes: protectedResources["apiCompensation"].scopes,
      account: account
    }).then((msalAuthorization)=>{
      //add the token
      let selectedLink = document.getElementById(linkId).href
      if(!noToken){
        selectedLink = selectedLink + "&token=" + (msalAuthorization ? msalAuthorization.accessToken : "")
      } 
      
      //set the link handler
      document.getElementById("linkHandler").href = selectedLink;
      
      //click the link
      document.getElementById("linkHandler").click()
    });
  }  
  
    
  // console.log("instance", instance, "account", account, "msalAuthorization", msalAuthorization);  

  return <div>
    <h1>Employee Reports</h1>
    
    <br />
    {
      employeeHrisId ? <div>
        <a 
          id={"changesAndPromotions" + employeeHrisId}
          onClick={(event)=>{
            event.preventDefault()
            triggerLink("changesAndPromotions" + employeeHrisId);
            return false;
          }}
          href={CONFIG.API_HOST + '/api/manager/teamReport/' + employeeHrisId + "?d=true"}
        >
          Download Employee Salary Range Placement Report
        </a>
      </div> : ""
    }
    
    
    <br />
    <br />
    
    <a href="#" id="linkHandler" style={{ visibility:"hidden" }}></a>
    
    {/* <div>
      <div>
        Request new report types by contacting 
        &nbsp;<a href="mailto://softwareengineeringsupport@geoengineers.com">softwareengineeringsupport@geoengineers.com</a>
      </div>
    </div> */}
  </div>;
};


export default EmployeeReports;