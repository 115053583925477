import React, { useState, useMemo, useEffect }  from 'react';
import Modal from 'react-modal';
import { useMsal, useAccount } from "@azure/msal-react";
import { MaterialReactTable } from 'material-react-table';
import jQuery from 'jquery';

import * as Utils from '../lib/utils';
import CONFIG from '../config';

window.jQuery = jQuery;
require("jsgrid");

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const modalBulkStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const debounce = function(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}

// const data = [];
//nested data is ok, see accessorKeys in ColumnDef below  
const SalaryGroupLocations = () => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});

    const [dimensions, setDimensions] = useState({ 
      height: window.innerHeight,
      width: window.innerWidth
    });

    // const [selectedYear, setSelectedYear] = useState((new Date()).getFullYear());

    const [tableData, setTableData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);    
    const [rowCount, setRowCount] = useState(0);

    const [selectedGroup, setSelectedGroup] = useState({});

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalAction, setModalAction] = useState("");
    const [modalBody, setModalBody] = useState("");
    const [modalTitle, setModalTitle] = useState("");

    const [groupLocationId, setGroupLocationId] = useState(null);
    const [locationsData, setLocationsData] = useState(null);

    const [modalBulkIsOpen, setBulkIsOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const urlParams = Utils.getParams();
    const groupId = urlParams.group_id;
    const selectedYear = urlParams.year;

    let subtitle;
    
    
    function openBulkModal() {
      setBulkIsOpen(true);
    }     
    function afterOpenBulkModal() {
      // references are now sync'd and can be accessed.
      // subtitle.style.color = '#f00';
    }
    function closeBulkModal() {
      setBulkIsOpen(false);
    }


    function openModal(action, userInfo) {
      setModalAction(action);
      setModalText(action, userInfo);
      setIsOpen(true);
    } 
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
      // subtitle.style.color = '#f00';
    }
    function closeModal() {
      setIsOpen(false);
    }


    function setModalText(action, locationInfo) {
      // console.log("ACTION", action, locationInfo);

      let header = "Add a location.";
      let body = <div>
        <div>
          <span className="locationGroupLabel">Location: </span> 
          <select 
            name="locationId" id="locationId"
            onChange={evt => setGroupLocationId(evt.target.value)} 
          >
            <option key={Utils.guid()} value="0">--Select a Location--</option>
            {locationsData.map((locationItem)=>{
              return <option key={Utils.guid()} value={locationItem.id}>{locationItem.city}, {locationItem.state}</option>
            })}
          </select>
        </div>
      </div>;

      setModalTitle(header);
      setModalBody(body);
    
      return false;
    }


    const handleTableData = async (groupId, year) =>{
      const jsonResponse = await Utils.getData(
        ('/api/salary_range_group_location?group_id='+ groupId + '&year='+year), 
        instance, 
        account
      );
      
      setTableData(jsonResponse);
      setRowCount(jsonResponse.length);
    }

    const fetchData = async () => {
      if (!tableData.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      try {
        const jsonLocationsResponse = await Utils.getData(
          ('/api/location'), 
          instance, 
          account
        );
        setLocationsData(jsonLocationsResponse);

        handleTableData(groupId, selectedYear);

        const groupResponse = await Utils.getData(
          ('/api/salary_range_group?year='+selectedYear+'&group_id='+groupId), 
          instance, 
          account
        );
        setSelectedGroup(groupResponse.length ? groupResponse[0] : {});

      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };

    useEffect(() => {
      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      const debouncedHandleResize = debounce(function handleResize() {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth
        })
      }, 500)

      //binds a resize listener for a debounced (less frequent) resize effect
      window.addEventListener('resize', debouncedHandleResize);
      
      //unbinds listener to prevent memory leaks
      return _ => {
        window.removeEventListener('resize', debouncedHandleResize)   
      };
    });

    //should be memoized or stable
    const columns = useMemo(
      () => [
        {
          accessorKey: 'delete_location',
          header: 'Delete Location',
          Cell: ({ cell, row }) => {
            return <div><a href="#" onClick={(event)=>{
              event.preventDefault();
              // console.log("EVENT", event, cell, row );
              if(window.confirm("Are you sure you would like to delete this group location?")){
                deleteGroupLocation(cell.row.original.id);
              }
              return false;
            }}>Delete</a></div>
          },
          enableEditing: false,
          columnDefType: 'display',
          enablePinning: false
        },         
        {
          accessorKey: 'id',
          header: 'Location Id',
          enableEditing: false
        },
        {
          accessorKey: 'city',
          header: 'City',
          enableEditing: true
        },
        {
          accessorKey: 'state',
          header: 'State',
          enableEditing: true
        }
      ],
      [],
    );


    //saves the group to the DB and updates the client view
    const saveGroupLocation = (action) => {
      // console.log("action", action, groupLocationId)

      Utils.postData( "/api/salary_range_group_location", {
        group_id : groupId,
        location_id : groupLocationId
      }, instance, account ).then((data) => { 
        
        handleTableData(groupId, selectedYear);
        closeModal();

        return false;
      });

      return false;
    }

    const deleteGroupLocation = (groupLocationId) => {

        Utils.deleteData(
          "/api/salary_range_group_location/" + groupLocationId, 
          instance, 
          account
        ).then((data) => {
          alert(data.message);

          if(data.result === "SUCCESS"){
            fetchData();
          }
        });

    }



    const uploadSalaryLocations = () => {
      const formData = new FormData();
      formData.append('file', selectedFile);
  
      Utils.postFile(
        "/api/salary_range_group_location/upload", 
        formData,
        instance,
        account
      ).then((result) => {
        alert("Salary locations uploaded");
        closeBulkModal();
        fetchData();
      });
      return false;
    }    


    const tableHeightValue =  window.innerHeight > 183 ? window.innerHeight - 183 : 300;
    const tableHeightCSS = tableHeightValue + "px";

    const thisYear = (new Date()).getFullYear(); 
    const yearsArray = [];
    for(let i=2022;i<=(thisYear+1);i++){
      yearsArray.push(i);
    }
    yearsArray.reverse();

    // console.log("selectedGroup", selectedGroup);

    return <div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={modalStyles}
          contentLabel="Add Salary Group Location"
          ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
        >
          <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
            {modalTitle}
          </h2>
          <div className="modalBody">
            {modalBody}
          </div>
          <div className="modalFooter">
            <button onClick={()=>{
              if(groupLocationId){
                saveGroupLocation(modalAction);
              }else{
                alert("Please select a location.")
              }
            }}>OK</button>
            <button onClick={closeModal}>Cancel</button>
          </div>
        </Modal>

        <Modal
          isOpen={modalBulkIsOpen}
          onAfterOpen={afterOpenBulkModal}
          onRequestClose={closeBulkModal}
          style={modalBulkStyles}
          contentLabel="Add Salary Group Range"
          ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
        >
          <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
            Bulk Upload
          </h2>
          <div className="modalBody">
            Please provide a CSV data file.
            
            <form>
              <input
                type="file"
                onChange={(e) => {
                  // console.log("e", e);
                  setSelectedFile(e.target.files[0])
                }}
              />
            </form>

          </div>
          <div>&nbsp;</div>
          <div className="modalFooter">
            <button onClick={()=>{

              uploadSalaryLocations();

            }}>OK</button>
            <button onClick={closeBulkModal}>Cancel</button>
          </div>
        </Modal>   

        <MaterialReactTable 
          columns={columns} 
          data={tableData}
          globalFilterFn="contains" 
          enableStickyHeader
          renderTopToolbarCustomActions={({ table }) => {
            return <div> 
              <span className="sectionTableHeader">Salary Group Locations</span>
              &nbsp;&nbsp;&nbsp;  

              <button onClick={()=>{ window.location.href = "/salaryGroups" }}>&lt;-- Go Back</button>
              &nbsp;&nbsp;&nbsp;

              <button
                onClick={(event)=>{

                  openModal("add", account);
                  
                  return false;
                }}
              >
                Add Salary Location
              </button>
              
              &nbsp;&nbsp;&nbsp;

              <button
                onClick={(event)=>{
                  // console.log("SHOW BULK UPLOADER", event);
                  openBulkModal();
                  return false;
                }}
              >
                Upload Salary Group Locations
              </button>

              &nbsp;&nbsp;&nbsp;
              
              Viewing&nbsp;
                {selectedYear}&nbsp;
                {
                  selectedGroup && 
                  selectedGroup.group_department!== "EXECUTIVES" && 
                  selectedGroup.group_department !== "PIPELINES" ? 
                    selectedGroup.group_department : 
                    ""
                }&nbsp;
                {selectedGroup ? selectedGroup.group_name : ""}
            </div>;
          }}  
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: 'error',
                  children: 'Error loading data',
                }
              : undefined
          }        
          muiTableContainerProps={{ sx: { height: tableHeightCSS } }}
          rowCount={rowCount}
          state={{
            isLoading,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            tableData: tableData
          }}
        />      
    </div>;
};

export default SalaryGroupLocations;