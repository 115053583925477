// import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
      clientId: "2316f85f-b415-40aa-8f0d-3ca01cf313b8",
      authority: "https://login.microsoftonline.com/9c984c59-1346-40ee-833f-330437a233ce", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: "/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
      postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
      navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
      cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
      // loggerOptions: {
      //     loggerCallback: (level, message, containsPii) => {
      //         if (containsPii) {
      //             return;
      //         }
      //         // eslint-disable-next-line
      //         switch (level) {
      //             case LogLevel.Error:
      //                 console.error(message);
      //                 return;
      //             case LogLevel.Info:
      //                 console.info(message);
      //                 return;
      //             case LogLevel.Verbose:
      //                 console.debug(message);
      //                 return;
      //             case LogLevel.Warning:
      //                 console.warn(message);
      //                 return;
      //         }
      //     }
      // }
  }
};

/**
* Scopes you add here will be prompted for user consent during sign-in.
* By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
* For more information about OIDC scopes, visit: 
* https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
*/
export const loginRequest = {
  scopes: ["User.Read"]
};


/**
* Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
* https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
*/
export const protectedResources = {
  graphMe: {
      endpoint: "https://graph.microsoft.com/v1.0/me",
      scopes: ["User.Read", "GroupMember.Read.All"],
  },
  //SET THIS UP IN PORTAL
  apiCompensation: {
      endpoint: "http://localhost:3000/",
      scopes: ["api://1c50723c-6911-436b-860a-c57bf5dbc5f1/access_as_user"], // e.g. api://xxxxxx/access_as_user
  }
}