import React, { useState, useMemo, useEffect }  from 'react';
import Modal from 'react-modal';
import { useMsal, useAccount } from "@azure/msal-react";
import { MaterialReactTable } from 'material-react-table';
import Datetime from 'react-datetime';
import moment from 'moment';
import jQuery from 'jquery';
import * as Utils from '../lib/utils';

import "react-datetime/css/react-datetime.css";

window.jQuery = jQuery;
require("jsgrid");

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height:"400px",
    width: "600px"
  },
};

const debounce = function(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}


const Approvals = (props) => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [dimensions, setDimensions] = useState({ 
      height: window.innerHeight,
      width: window.innerWidth
    });
    
    const [tableData, setTableData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);    
    const [rowCount, setRowCount] = useState(0);

    // console.log("account", account, props.userAccount, props);

    const urlParams = Utils.getParams();
    const managerId = urlParams.managerId;

    const fetchData = async () => {
      if (!tableData.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      try {
        // console.log("managerId", managerId);
        // console.log("props.userAccount", props.userAccount);    

        const approvalManagerId = managerId 
          && props 
          && props.userAccount 
          && props.userAccount.access_level 
          && props.userAccount.access_level.indexOf("ADMIN") > -1 ? 
            managerId : (props.userAccount ? props.userAccount.id : null);
        
        if(approvalManagerId){
          const jsonResponse = await Utils.getData(
            ("/api/employee/"+approvalManagerId+"/directReportAdjustments"), 
            instance, 
            account
          );
  
          let tableDataResponse = [];
          for(var i=0;i<jsonResponse.length;i++){
            // console.log("jsonResponse[]", i, jsonResponse[i]);
            if(jsonResponse[i].total_direct_reports > 0){
              tableDataResponse.push(jsonResponse[i]);
            }
          }
  
          setTableData(tableDataResponse);
          setRowCount(tableDataResponse.length);
        }

      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };

    useEffect(() => {
      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.userAccount]);

    useEffect(() => {
      const debouncedHandleResize = debounce(function handleResize() {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth
        })
      }, 500)

      //binds a resize listener for a debounced (less frequent) resize effect
      window.addEventListener('resize', debouncedHandleResize);
      
      //unbinds listener to prevent memory leaks
      return _ => {
        window.removeEventListener('resize', debouncedHandleResize)   
      };
    });



    //should be memoized or stable
    const columns = useMemo(
      () => [
        {
          accessorKey: 'employee_full_name',
          accessorFn: (row) => `${Utils.decodeHTML(row.first_name)} ${Utils.decodeHTML(row.last_name)}`,
          header: 'Employee Name',
        },
        // {
        //   accessorKey: 'is_approved',
        //   accessorFn: (row) => `${Utils.decodeHTML(row.first_name)} ${Utils.decodeHTML(row.last_name)}`,
        //   header: 'Employee Name',
        // },
        {
          accessorKey: 'approve_reports',
          header: 'Reports\' Adjustments Approved',
          // muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
          Cell: ({ cell, row, column, table }) => {
            // console.log("ARGS", cell, row, column, table);
            // const currentTableData = table.getState().tableData;

            let responseJsx = "N/A";
            if(row.original.no_approval_needed){
              responseJsx = "Approval not required."
            }

            // if(!row.original.no_approval_needed && row.original.total_requests > 0){
              responseJsx = <input 
                type="checkbox"
                defaultChecked={row.original.is_approved} 
                onClick={(e)=>{
                  // console.log("EVENT", e, e.target.checked);
    
                  let data = table.getState().tableData;
                  data[row.index]["is_manager_approved"] = e.target.checked;
                  setTableData([...data]);
    
                  const managerApprovedBody = {
                    approval_id : cell.row.original.approval_id,
                    manager_hris_id : cell.row.original.manager_hris_id,
                    employee_hris_id : cell.row.original.employee_hris_id,
                    adjustment_id : cell.row.original.adjustment_id,
                    is_approved : e.target.checked
                  };

                  Utils.postData(
                    "/api/employee_adjustment/manager_approved", 
                    managerApprovedBody,
                    instance,
                    account
                  ).then((data) => {
    
                    fetchData();
                    alert("Manager approval set.")
              
                  })
    
                  return false;
                }}
              />;
            // }
            
            return responseJsx;
          },
          enableEditing: false,
          columnDefType: 'display',
          enablePinning: false
        },        
      ],
      [],
    );
    
    const handleSaveRow = ()=>{
      alert("Save not defined")
      return false;
    };

    const tableHeightValue =  window.innerHeight > 183 ? window.innerHeight - 183 : 300;
    const tableHeightCSS = tableHeightValue + "px";
    // console.log("tableData", tableData);

    return <div>
        <MaterialReactTable 
          columns={columns} 
          data={tableData}
          globalFilterFn="contains" 
          enableStickyHeader
          // enableEditing
          positionToolbarAlertBanner="bottom"
          renderTopToolbarCustomActions={({ table }) => {
            return null;
          }}
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: 'error',
                  children: 'Error loading data',
                }
              : undefined
          }
          muiTableContainerProps={{ sx: { height: tableHeightCSS } }}
          rowCount={rowCount}
          renderEmptyRowsFallback={({ table }) => (
            <div style={{ 
              textAlign:"center",
              margin: "10px", 
              fontWeight: "bold"
             }}>No managers to approve.</div>
          )}
          state={{
            isLoading,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            tableData: tableData
          }}
        />
      </div>;
};

export default Approvals;