import React, { useState, useMemo, useEffect }  from 'react';
import Modal from 'react-modal';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { useMsal, useAccount } from "@azure/msal-react";
import { MaterialReactTable } from 'material-react-table';
import jQuery from 'jquery';
import * as Utils from '../lib/utils';

window.jQuery = jQuery;
require("jsgrid");

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const debounce = function(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}



//example of creating a mui dialog modal for creating new rows
export const CreateNewLocationModal = ({ open, columns, onClose, onSubmit }) => {
  // console.log("COLUMNS", columns);
  // console.log("open", open);

  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      // console.log("acc", acc);
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {}),
  );

  const handleSubmit = () => {
    //put your validation logic here

    // console.log("values", values)
    let message = ""
    if(!values.city){
      message += "A city must be entered to continue.\r\n";
    }
    if(!values.state){
      message += "A state must be entered to continue.\r\n";
    }
    if(!values.is_remote){
      message += "Please set \"Is remote?\" to true or false.\r\n";
    }
    if(!values.is_closed){
      message += "Please set \"Is closed?\" to true or false.\r\n";
    }

    if(message){
      alert(message);
    }else{
      onSubmit(values);
      onClose(setValues);  
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Create New Location</DialogTitle>
      <DialogContent>
        <form 
          onSubmit={(e) => e.preventDefault()} 
          onKeyUp={(e)=>{
            // console.log("TEST", e, e.which);
            if(e.which === 13){
              handleSubmit();
            }
        }}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
            {columns.map((column) => {
              // console.log("column", column);
              return column.accessorKey !== "id" && column.accessorKey !== "delete_location" ? <TextField
                key={Utils.guid()}
                label={column.header}
                name={column.accessorKey}
                onChange={(e) => {
                  // console.log("values", values, "e.target.name", e.target.name, "e.target.value", e.target.value)
                  values[e.target.name] = e.target.value;
                  setValues(values);
                }}
              /> : null;
            })}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={()=>{
          onClose(setValues)
        }}>Cancel</Button>
        <Button 
          color="secondary" 
          onClick={handleSubmit} 
          variant="contained"
        >
          Create New Location
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Location = () => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [dimensions, setDimensions] = useState({ 
      height: window.innerHeight,
      width: window.innerWidth
    });
    
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);    
    const [rowCount, setRowCount] = useState(0);

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [locationsData, setLocationsData] = useState({});


    useEffect(() => {
      const debouncedHandleResize = debounce(function handleResize() {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth
        })
      }, 500)

      //binds a resize listener for a debounced (less frequent) resize effect
      window.addEventListener('resize', debouncedHandleResize);
      
      //unbinds listener to prevent memory leaks
      return _ => {
        window.removeEventListener('resize', debouncedHandleResize)   
      };
    });


    const fetchData = async () => {
        if (!locationsData.length) {
          setIsLoading(true);
        } else {
          setIsRefetching(true);
        }
    
        try {
    
          const jsonLocationsResponse = await Utils.getData(
            ('/api/location'), 
            instance, 
            account
          );
          setLocationsData(jsonLocationsResponse);
          setRowCount(jsonLocationsResponse.length);

        } catch (error) {
          setIsError(true);
          console.error(error);
          return;
        }
    
        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
    };
    
    
    useEffect(() => {
        fetchData();
    }, []);


    //should be memoized or stable
    const columns = useMemo(
      () => [
        {
            accessorKey: 'delete_location',
            header: 'Delete Location',
            Cell: ({ cell, row }) => {
                // console.log("cell", cell, "row", row);
                return <div><a href="#" onClick={(event)=>{
                  event.preventDefault();

                  if(window.confirm("WARNING: This will remove this location from the system.")){
                      deleteLocation(cell.row.original.id);
                  }

                  return false;
                }}>Delete</a></div>
            },
            enableEditing: false,
            columnDefType: 'display',
            enablePinning: false
        },      
        {
            accessorKey: 'id',
            header: 'Location ID',
            enableEditing: false
        },
        {
            accessorKey: 'city',
            accessorFn: (row)=>`${row.city ? row.city : ""}`,
            header: 'Location City',
            enableEditing: true
        },
        {
            
            accessorKey: 'state',
            accessorFn: (row)=>`${row.state ? row.state : ""}`,
            header: 'Location State',
            enableEditing: true
        },
        {
            accessorKey: 'is_remote',
            accessorFn: (row) => `${row.is_remote ? "true" : "false"}`,
            header: 'Is Remote Only?',
            enableEditing: true,
            muiEditTextFieldProps: {
              select: true, //change to select for a dropdown
              children: ["true", "false"].map((boolVal) => (
                <MenuItem key={Utils.guid()} value={boolVal}>
                  {boolVal}
                </MenuItem>
              )),
            },
        },        
        {
            accessorKey: 'is_closed',
            accessorFn: (row) => `${row.is_closed ? "true" : "false"}`,
            header: 'Is Closed?',
            enableEditing: true,
            muiEditTextFieldProps: {
              select: true, //change to select for a dropdown
              children: ["true", "false"].map((boolVal) => (
                <MenuItem key={Utils.guid()} value={boolVal}>
                  {boolVal}
                </MenuItem>
              )),
            },
        }
      ],
      [],
    );

    //saves the manager to the DB and updates the client view
    function saveLocation(action, originalRow, location, callback){
      // console.log("action", action, "originalRow", originalRow, "location", location);
      let method = "postData";
      let data = {
        city: location.city,
        state: location.state,
        is_closed: location.is_closed,
        is_remote: location.is_remote
      };
      // console.log(data);
      if(action==="edit"){
        data.id = originalRow.id;
        method = "putData";
      }

      // console.log("DATA to POST/PUT", data)

      Utils[method]( "/api/location", data, instance, account ).then((data) => { 
        if(callback){
          callback(data)
        }
        return false;
      });

      return false;
    }


    const deleteLocation = (locationId) =>{
        Utils.deleteData(
          "/api/location/" + locationId, 
          instance, 
          account
        ).then((data) => {
          // console.log("data", data)
          alert(data.message);

          if(data.result === "SUCCESS"){
            fetchData();
          }
        });
    }


    const handleSaveRow = async ({ exitEditingMode, row, values, table }) => {
      // let data = table.getState().tableData; //deprecated
      let data = locationsData;      
      data[row.index].city = values.city;
      setLocationsData([...data]);
      saveLocation("edit", row.original, values, ()=>{
        fetchData();
      });
      exitEditingMode();
    };

    const handleCreateNewRow = (values) => {
      // console.log("values", values);
      if(values.city){

        saveLocation(
          "add", 
          {
            city : values.city,
            state : values.state,
            is_remote : values.is_remote,
            is_closed : values.is_closed
          }, 
          values, 
          (responseData)=>{
            // console.log("responseData", responseData);
            let newData = Utils.copy(locationsData);
            newData.push(responseData);
            setLocationsData(newData);
          }
        );
  
      }else{
        alert("Locations MUST include a city")
      }
    };

    const tableHeightValue =  window.innerHeight > 183 ? window.innerHeight - 183 : 300;
    const tableHeightCSS = tableHeightValue + "px";

    return <div>
        <MaterialReactTable 
          columns={columns} 
          data={locationsData}
          globalFilterFn="contains" 
          enableStickyHeader
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: 'error',
                  children: 'Error loading data',
                }
              : undefined
          }    
          renderTopToolbarCustomActions={() => (
            <div>
              <span className="sectionTableHeader">Locations | </span>
              &nbsp;&nbsp;&nbsp;              
              <Button
                color="secondary"
                onClick={() => setCreateModalOpen(true)}
                variant="contained"
              >
                Create New Location
              </Button>              
            </div>
          )}          
          muiTableContainerProps={{ sx: { height: tableHeightCSS } }}
          rowCount={rowCount}
          enableEditing
          onEditingRowSave={handleSaveRow}        
          state={{
            isLoading,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            tableData: locationsData
          }}
        />
        <CreateNewLocationModal
          columns={columns}
          open={createModalOpen}
          onClose={(setValues) => {
            setValues({
              city: "",
              state: "",
              is_remote: "",
              is_closed: ""
            })
            setCreateModalOpen(false);
          }}
          onSubmit={handleCreateNewRow}
        />        
      </div>;
};

export default Location;