import React, { useState, useEffect }  from 'react';
import { useMsal, useAccount } from "@azure/msal-react";
import jQuery from 'jquery';

import * as Utils from '../lib/utils';

window.jQuery = jQuery;
require("jsgrid");


//nested data is ok, see accessorKeys in ColumnDef below  
const Employee = (props) => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [employeeData, setEmployeeData] = useState(null);
  const [managersData, setManagersData] = useState(null);
  const [directReportsData, setDirectReportsData] = useState(null);
  const [salaryHistoryData, setSalaryHistoryData] = useState(null);

  const [employeeSalary, setEmployeeSalary] = useState(null);
  
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);

  const urlParams = Utils.getParams();
  
  // console.log("urlParams", urlParams);

  useEffect(() => {
    const fetchData = async () => {
      if (!employeeData || !managersData || !directReportsData) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      try {
          const jsonEmployeeResponse = await Utils.getData(
            ('/api/employee/' + urlParams.id), 
            instance, 
            account
          );
          // console.log("RESPONSE JSON employeeUrl:", jsonEmployeeResponse);

          const jsonManagersResponse = await Utils.getData(
            ('/api/employee/' + urlParams.id + '/managers'), 
            instance, 
            account
          );
          // console.log("RESPONSE JSON managersUrl:", jsonManagersResponse);   

          const jsonReportsResponse = await Utils.getData(
            ('/api/employee/' + urlParams.id + '/directReports'), 
            instance, 
            account
          );
          // console.log("RESPONSE JSON directReportsUrl:", jsonReportsResponse);

          const jsonSalaryHistoryResponse = await Utils.getData(
            ('/api/salary_history/' + urlParams.id), 
            instance, 
            account
          );
          // console.log("RESPONSE JSON jsonSalaryHistoryResponse:", jsonSalaryHistoryResponse);

          setEmployeeData(jsonEmployeeResponse);
          setManagersData(jsonManagersResponse);
          setDirectReportsData(jsonReportsResponse);
          setSalaryHistoryData(jsonSalaryHistoryResponse);

          setEmployeeSalary(jsonEmployeeResponse.current_ft_annualized_salary)

      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  let orgHeiarchy = [];
  let nextId = 0;
  
  //get managers from CEO down
  // console.log("managersData", managersData);

  const renderEmployeeName = (userText, employee, loggedInUser)=>{
    // console.log("USER DETAILS:", userText, employee, loggedInUser);
    if(
      (
        loggedInUser.permitted_hris_users 
        && loggedInUser.permitted_hris_users.indexOf(employee.employee_hris_id) > -1
      )
      || (
        loggedInUser.access_level
        && loggedInUser.access_level.indexOf("ADMIN") > -1
      ) 
    ){
      return <a href={"/employee?id=" + employee.id}>{userText}</a>;
    }else{
      return userText
    }
  }
  // console.log("props", props);

  if(managersData && managersData.length){
      for(var i=0;i<managersData.length;i++){
          const thisUser = managersData.find((element)=>{ return element.manager_hris_id === nextId });
          // console.log("thisUser", thisUser, "managersData", managersData)
          
          orgHeiarchy.push(
              <div className="managersNode" key={Utils.guid()}>
                  <div className={"managerDetails" + (i===managersData.length-1 ? " bold" : "")}>
                      { 
                        renderEmployeeName(
                          (
                            Utils.decodeHTML(thisUser.first_name) + " " + 
                            Utils.decodeHTML(thisUser.last_name) + 
                            (i === managersData.length-1 ? " (Selected User)" : "")
                          ),
                          thisUser,
                          props && props.userAccount ? props.userAccount : {}
                        ) 
                      }
                  </div>
                  {(i !== managersData.length-1 || (directReportsData && directReportsData.length) ? (<div> --&gt;</div>) : "")}
              </div>
          );
          nextId = thisUser.employee_hris_id;
      }    
  }
  //last item currently in list is selected employee

  //get direct reports if there are any
  if(directReportsData && directReportsData.length){
      const handleDirectReports = (reportsData) => {
        // console.log("reportsData",reportsData)
        let reportUsers = [];
        for(var i=0;i<reportsData.length;i++){
            reportUsers.push(
              <div key={Utils.guid()} className="directReportUser">                
                { 
                  renderEmployeeName(
                    (Utils.decodeHTML(reportsData[i].first_name) + " " + Utils.decodeHTML(reportsData[i].last_name)),
                    reportsData[i],
                    props && props.userAccount ? props.userAccount : {}
                  ) 
                }
              </div>);
        }
        return <div key={Utils.guid()} className="directReportsNode">{reportUsers}</div>
      }
      orgHeiarchy.push(handleDirectReports(directReportsData));
  }

  // console.log("employeeData", employeeData);
  const dateOfHireObject = employeeData && employeeData.date_of_hire ? new Date(employeeData.date_of_hire) : new Date();
  const dateOfHireString = Utils.maskDateAs("mdy", dateOfHireObject);
  
  //if not authorized, show message indicating so
  //otherwise, show loading until employee profile fetches
  // const employeeHrisId = props && props.userAccount && props.userAccount.employee_hris_id ? props.userAccount.employee_hris_id : null;
  const employeeHrisId = employeeData ? employeeData.employee_hris_id : -1;

  return employeeData && employeeData.result === "UNAUTHORIZED" ? <div>Unauthorized</div> :
    (!isLoading && employeeData ? 
      (<div>
        {employeeHrisId ? <div><a style={{fontWeight:"bold"}} href={"/compensation?managerHrisId="+employeeHrisId}>Go to my team's compensation page</a><br /></div> : null}
        <div className={"header"}>Employee Profile:</div> 
        <div><b>Employee Name:</b> {Utils.decodeHTML(employeeData.first_name)} {Utils.decodeHTML(employeeData.last_name)}</div>
        <div><b>Employee HRIS ID:</b> {employeeData.employee_hris_id}</div>
        <div><b>Position Title:</b> {employeeData.position_title}</div>
        <div><b>Location:</b> {employeeData.location === "Remote" ? employeeData.remote_city : employeeData.location}, {employeeData.resident_state}</div>
        <div><b>Date of Hire:</b> {dateOfHireString}</div>
        <div><b>Hours/Week:</b> {employeeData.hours_week}</div>
        {/* <div><b>Labor Name:</b> {employeeData.labor_name}</div> */}
        <div><b>FLSA Status:</b> {employeeData.exempt_nonexempt === "Exempt" ? "Salaried" : "Hourly"}</div>
        {/* <div><b>Education:</b> {employeeData.education}</div> */}
        <div><b>Employment Status:</b> {employeeData.status}</div>

        <div>&nbsp;</div>
        {/* <div><b>Salary Department:</b> {employeeData.group_department}</div> */}
        <div><b>Salary Range:</b> &nbsp;
          {employeeData.minimum ? Utils.moneyMask(employeeData.minimum) : "N/A"} 
          &nbsp; - {employeeData.midpoint ? Utils.moneyMask(employeeData.midpoint) : "N/A"} 
          &nbsp; - {employeeData.maximum ? Utils.moneyMask(employeeData.maximum) : "N/A"}</div>
        <div>&nbsp;</div>

        <div><b>Organization Structure:</b></div>
        <div className="orgHeiarchy">{orgHeiarchy}</div>

        <div>&nbsp;</div>

        <div><b>Current Salary:</b> {Utils.moneyMask(employeeSalary)}</div>
        <div>&nbsp;</div>
        <div>
            <span className={"header"}>Salary History:</span> 
            <div>
              <span className={"salaryHistoryItem header"}>Position</span>
              <span className={"salaryHistoryItem header"}>Effective Date</span>
              <span className={"salaryHistoryItem header"}>FT Annualized Salary</span>
            </div>
            {
              salaryHistoryData ? salaryHistoryData.map((adjustmentEntry)=>{
                const dateItem = new Date(adjustmentEntry.salary_change_effective_date);
                const dateString = Utils.addZ(dateItem.getMonth()+1) + "/" + Utils.addZ(dateItem.getDate()) + "/" + dateItem.getFullYear();
                return <div key={Utils.guid()}>
                  <span className={"salaryHistoryItem"}>{adjustmentEntry.position_name}</span>
                  <span className={"salaryHistoryItem"}>{dateString}</span>
                  <span className={"salaryHistoryItem"}>{Utils.moneyMask(parseInt(adjustmentEntry.ft_annualized_salary))}</span>
                </div>
              }) : null
            }
        </div>
      </div>) : <div>Loading...</div>
    );
};

export default Employee;