import React, { useState, useMemo, useEffect }  from 'react';
import { useMsal, useAccount } from "@azure/msal-react";
import jQuery from 'jquery';
import * as Utils from '../lib/utils';

// import "../index.css";

window.jQuery = jQuery;
require('bootstrap-toggle');

require("jsgrid");

const Home = () => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
  
    return <div className="grid-default-dimensions">
        Loading Application... 
        <br />
        <br />
        For technical support issues, please contact <a href="mailto://softwareengineeringsupport@geoengineers.com">softwareengineeringsupport@geoengineers.com</a>.
        
    </div>;
}  

export default Home;