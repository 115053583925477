import React, { useState, useMemo, useEffect }  from 'react';
import Modal from 'react-modal';
import { useMsal, useAccount } from "@azure/msal-react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Datetime from 'react-datetime';
import jQuery from 'jquery';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html';

import * as Utils from '../lib/utils';
import CONFIG from '../config';

// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-datetime/css/react-datetime.css";
// import "../index.css";
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  },
};

const requestCompensationModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height:"400px",
    width: "600px"
  },
};

const dateModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height:"400px",
    width: "600px"
  },
};

window.jQuery = jQuery;
require("jsgrid");

const HR = () => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [activeUser, setActiveUser] = useState([]);
  const [userData, setUserData] = useState([]);
  const [pendingAdjustmentsData, setPendingAdjustmentsData] = useState({});
  const [reviewAdjustmentsData, setReviewAdjustmentsData] = useState({});
  const [finalizedAdjustmentsData, setFinalizedAdjustmentsData] = useState({});
  const [closedAdjustmentsData, setClosedAdjustmentsData] = useState({});
  
  
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);    
  const [rowCount, setRowCount] = useState(0);

  const [modalUserIsOpen, setUserIsOpen] = useState(false);
  const [modalUserAction, setModalUserAction] = useState("");
  const [modalUserBody, setModalUserBody] = useState("");
  const [modalSelectedUser, setModalSelectedUser] = useState(null);
  const [modalUserTitle, setModalUserTitle] = useState("");

  const [modalEmail, setModalEmail] = useState("");
  const [modalFirstName, setModalFirstName] = useState("");
  const [modalLastName, setModalLastName] = useState("");
  const [modalAccessLevel, setModalAccessLevel] = useState("");
  const [modalReviewContact, setModalReviewContact] = useState(false);

  const [modalCompensationIsOpen, setCompensationIsOpen] = useState(false);
  const [compensationOpenDate, setCompensationOpenDate] = useState("");
  const [compensationEffectiveDate, setCompensationEffectiveDate] = useState("");
  const [compensationDeadlineDate, setCompensationDeadlineDate] = useState("");
  
  const [overrideCurrentAdjustments, setOverrideCurrentAdjustments] = useState(false);
  
  const [modalEmployeeListIsOpen, setEmployeeListIsOpen] = useState(false);
  const [adjustmentEmployeeList, setAdjustmentEmployeeList] = useState([]);

  const [modalRequestResultsIsOpen, setRequestResultsIsOpen] = useState(false);
  const [ineligibleEmployees, setIneligibleEmployees] = useState([]);

  const [modalPreviewIsOpen, setPreviewIsOpen] = useState(false);

  const [modalEmailIsOpen, setEmailIsOpen] = useState(false);
  const [emailSubject, setEmailSubject] = useState("Compensation system request from HR");
  const [activeAdjustment, setActiveAdjustment] = useState(null);
  const [adjustmentStatus, setAdjustmentStatus] = useState("pending")
  const [adjustmentManagers, setAdjustmentManagers] = useState(null);

  const [modalDateIsOpen, setDateIsOpen] = useState(false);
  const [modalDateValue, setModalDateValue] = useState(null);
  const [modalDateType, setModalDateType] = useState(null);
  const [modalDateAdjustment, setModalDateAdjustment] = useState(null);
  

  const thisYear = (new Date()).getFullYear();

  // console.log("ACCOUNT", account);

  const initialEmailTemplate = "<p>Hello,</p>"+
    "<p>Click on this link #COMPENSATIONLINK# to access our Employee Compensation System (ECS) and make salary adjustment or promotion recommendations for your employees you feel must occur prior to "+thisYear+". Per the guide attached in the email from Jeanna Schmidt (CHRO), you must click ‘Submit Recommendation’ for all your employees whether they receive any salary increase or not and complete this task <strong>no later than (ENTER DAY) (ENTER MONTH) (ENTER DATE)th</strong>.&nbsp;&nbsp;</p>"+
    "<p>If you have questions regarding salary adjustment or promotion recommendations process in the system, reach out to me—"+account.name+" at "+account.username+". For error messages or other system issues, reach out to the Software Engineering Support team at softwareengineeringsupport@geoengineers.com.</p>"+
    "<p>Regards, <br><em>"+account.name+"<br>GeoEngineers, Inc.</em></p>";
  
  const finalizedEmailTemplate = "<p>Hello,</p>"+
    "<p>Please find confirmation of approved recommendations here: #COMPENSATIONLINK#. Approved promotions and salary adjustments go into effect<br>on #EFFECTIVEDATE# and show up in paychecks on (ENTER DAY), (ENTER MONTH) (ENTER DATE)th (so be sure to inform the appropriate employees of approved changes before then).</p>"+
    "<p>If you have questions regarding salary adjustment or promotion recommendations process in the system, reach out to me—"+account.name+" at "+account.username+". For error messages or other system issues, reach out to the Software Engineering Support team at softwareengineeringsupport@geoengineers.com.</p>"+
    "<p>Regards, <br><em>"+account.name+"<br>GeoEngineers, Inc.</em></p>";
  
  const [emailContents, setEmailContents] = useState(initialEmailTemplate);
  const [selectedManagers, setSelectedManagers] = useState([])

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(emailContents)
      )
    )
  )

  let subtitle;

  const onEditorStateChange = (editorStateEvent)=> {
    setEditorState(editorStateEvent);
  };


  function openUserModal(action, userInfo) {
    setModalUserAction(action);
    setUserModalText(action, userInfo);
    setUserIsOpen(true);
  }
  function setUserModalText(action, userInfo) {
    // console.log("ACTION", action, userInfo);

    let header = "";
    let body = action !== "delete" ? 
      (<div>
        <div>
          <span className="hrUserInputLabel">Email: </span>
          <input name="email" id="email" defaultValue={userInfo ? userInfo.email : ""} onChange={evt => setModalEmail(evt.target.value)} />
          <br />
          <span className="hrUserInputLabel">First Name: </span>
          <input name="firstName" id="firstName" defaultValue={userInfo ? userInfo.first_name : ""} onChange={evt => setModalFirstName(evt.target.value)} />
          <br />
          <span className="hrUserInputLabel">Last Name: </span>
          <input name="lastName" id="lastName" defaultValue={userInfo ? userInfo.last_name : ""} onChange={evt => setModalLastName(evt.target.value)} />
          <br />
          <span className="hrUserInputLabel">Access Level: </span>
          <input name="accessLevel" id="accessLevel" defaultValue={userInfo ? userInfo.access_level : ""} onChange={evt => setModalAccessLevel(evt.target.value)} />
          <br />
          <span className="hrUserInputLabel">Is Review Contact? </span>
          <input type="checkbox" name="isReviewContact" id="isReviewContact" defaultChecked={userInfo ? userInfo.review_contact : false} onChange={(evt) => {
            setModalReviewContact(evt.target.checked);
          }} />
        </div>
      </div>) : 
      ("Delete the following user: " + userInfo.email + "?");

    if(action==="add"){
      header = "Add a user.";
    }else if(action==="edit"){
      header = "Edit user.";
    }else if(action==="delete"){
      header = "Delete user?";
    }

    setModalEmail(userInfo ? userInfo.email : "");
    setModalFirstName(userInfo ? userInfo.first_name : "");
    setModalLastName(userInfo ? userInfo.last_name : "");
    setModalAccessLevel(userInfo ? userInfo.access_level : "");
    setModalReviewContact(userInfo ? userInfo.review_contact : false);

    setModalSelectedUser(userInfo);
    setModalUserTitle(header);
    setModalUserBody(body);
  }
  function afterOpenUserModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closeUserModal() {
    setUserIsOpen(false);
  }

  

  function openEmployeeListModal() {
    setEmployeeListIsOpen(true);
  }
  function afterOpenEmployeeListModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closeEmployeeListModal() {
    setEmployeeListIsOpen(false);
  }



  function openCompensationModal() {
    setCompensationIsOpen(true);
  }
  function afterOpenCompensationModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closeCompensationModal() {
    setCompensationIsOpen(false);
  }


  function openRequestResultsModal() {
    setRequestResultsIsOpen(true);
  }
  function afterOpenRequestResultsModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closeRequestResultsModal() {
    setRequestResultsIsOpen(false);
  }


  function openPreviewModal(adjustmentItem, managersList, status) {
    setPreviewIsOpen(true);
  }
  function afterOpenPreviewModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closePreviewModal() {
    setPreviewIsOpen(false);
  }


  function openEmailModal(adjustmentItem, managersList, status) {
    // console.log("SETTING ACTIVE ADJUSTMENT TO: ", adjustmentItem, managersList, status);

    const days = ["Sunday", "Monday", "Tuesday", "Wednesday","Thursday", "Friday","Saturday"]
    const date = new Date(adjustmentItem.effective_date);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    const dayOfWeek = date.getDay();
    const cleanDate = days[dayOfWeek] + ", " + month + " " + day + ", " + year;
    // console.log("cleanDate", cleanDate);

    let thisEmail = "";
    if(status === "pending"){
      thisEmail = initialEmailTemplate;
    }else if(status === "closed"){
      thisEmail = finalizedEmailTemplate;
    }else{
      thisEmail = finalizedEmailTemplate;
    }

    //Saturday September 30th, 2023u
    thisEmail = thisEmail.replace("#EFFECTIVEDATE#", cleanDate);
    setEmailSubject("Compensation system request from HR");
    setEmailContents(thisEmail);
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(thisEmail)
        )
      )
    )    
    setAdjustmentStatus(status);
    setActiveAdjustment(adjustmentItem);
    setAdjustmentManagers(managersList);
    setEmailIsOpen(true);
  }
  function afterOpenEmailModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closeEmailModal() {
    setEmailIsOpen(false);
  }


  function openDateModal(adjustmentItem, dateType, status) {
    let dateValue = null;
    if(dateType === "Deadline"){
      dateValue = adjustmentItem.deadline_date;
    }
    if(dateType === "Effective"){
      dateValue = adjustmentItem.effective_date;      
    }
    if(dateType === "Open"){
      dateValue = adjustmentItem.open_date;      
    }
    
    // console.log("adjustmentItem", adjustmentItem, "dateType", dateType, "dateValue", dateValue);

    setModalDateType(dateType);
    setModalDateValue(dateValue);
    setModalDateAdjustment(adjustmentItem);
    setDateIsOpen(true);
  }
  function afterOpenDateModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closeDateModal() {
    setDateIsOpen(false);
  }


  //saves the user to the DB and updates the client view
  function saveSelectedUser(action, user){
    // console.log("DATA", action, user);
    // if(user && user.access_level === "SUPERADMIN"){
    //   alert("\"SUPERADMIN\" accounts cannot be updated or deleted.  Please contact the site administration to update or remove SUPERADMIN accounts.");
    // }else{
      //DO THE ACTION
      let method;
      let data;

      if(action === "add"){
        method="postData";
        data = {
          email: modalEmail,
          first_name: modalFirstName,
          last_name: modalLastName,
          review_contact: modalReviewContact,
          access_level: modalAccessLevel
        };
      }

      if(action === "edit"){
        method="putData";
        data = {
          id: user.id,
          email: modalEmail,
          first_name: modalFirstName,
          last_name: modalLastName,
          review_contact: modalReviewContact,
          access_level: modalAccessLevel
        };
      }

      if(action === "delete"){
        method="deleteData";  
        data = { id: user.id };
      }

      let localUsers = Utils.copy(userData);

      let fetchAction = action === "delete" ? 
        Utils[method]("/api/user/"+user.id, instance, account) : 
        Utils[method]("/api/user", data, instance, account);
      //update the user on the server
      
      fetchAction.then((data) => {
          // console.log(data); // JSON data parsed by `data.json()` call
          
          //find the user in the local list, update as needed
          let rowMatched=false;
          for(var i=0;i<localUsers.length;i++){
            if(user && localUsers[i].id===user.id){
              if(action==="edit"){
                localUsers[i] = data;
              }
              if(action==="delete"){
                localUsers.splice(i, 1);
              }
              rowMatched=true;
              break;
            }
          }

          //add a user to the local list
          if(!rowMatched){
            localUsers.push(data)
          }

          setUserData(localUsers);
          fetchData();
          closeUserModal();

        });
    // }
    return false;
  }


  const fetchData = async () => {
    if (!userData.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    try {
      
      const jsonUsersResponse = await Utils.getData(
        ('/api/user?type=admins'), 
        instance, 
        account
      );

      setUserData(jsonUsersResponse);
      let selectedUser = null
      for(var i=0;i<jsonUsersResponse.length;i++){
        if(accounts[0].username === jsonUsersResponse[i].email){
          selectedUser = jsonUsersResponse[i];
        }
      }
      setActiveUser(selectedUser);

      setRowCount(jsonUsersResponse.length);

      const jsonPendingAdjustmentsResponse = await Utils.getData(
        ('/api/pending'), 
        instance, 
        account
      );
      setPendingAdjustmentsData(jsonPendingAdjustmentsResponse);

      const jsonReviewAdjustmentsResponse = await Utils.getData(
        ('/api/review'), 
        instance, 
        account
      );
      setReviewAdjustmentsData(jsonReviewAdjustmentsResponse);

      const jsonFinalizedAdjustmentsResponse = await Utils.getData(
        ('/api/finalized'), 
        instance, 
        account
      );
      setFinalizedAdjustmentsData(jsonFinalizedAdjustmentsResponse);

      const jsonClosedAdjustmentsResponse = await Utils.getData(
        ('/api/closed'), 
        instance, 
        account
      );
      setClosedAdjustmentsData(jsonClosedAdjustmentsResponse);


    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }

    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };


  useEffect(() => {
    
    fetchData();

  }, []);


  const requestCompensations = () =>{
  
    if(compensationEffectiveDate){
      Utils.postData(
        "/api/employee_adjustment/all", 
        {
          override_current_adjustments: overrideCurrentAdjustments,
          open_date: compensationOpenDate,
          effective_date: compensationEffectiveDate,
          deadline_date: compensationDeadlineDate 
        },
        instance, 
        account
      ).then((data) => {
  
        closeCompensationModal();
        fetchData();
        alert(data.message);

      }).catch((error)=>{
        
        console.log("ERROR", error);
        alert("There was an error creating the requested compensation.");
        
      });
    }else{
      alert("Opening compensation requires an effective date to be set.")
    }
  
    return false;
  }    


  const closeAdjustmentByDateAndStatus = (effectiveDate, status) =>{

    // console.log("INSTANCE", instance, account);

    Utils.postData(
      "/api/employee_adjustment/close?effective_date=" + effectiveDate + "&status=" + status,
      {},
      instance, 
      account
    ).then((data) => {

      fetchData();

      if(data && data.result && data.result ==="FAILED"){
        alert(data.message);
      }else{
        alert("Adjustment successfully closed");
      }

    });
    
    return false;
  }


  const deleteAdjustmentByDateAndStatus = (effectiveDate, status) =>{
      Utils.deleteData(
        "/api/employee_adjustment?effective_date=" + effectiveDate + "&status=" + status, 
        instance, 
        account
      ).then((data) => {
  
        fetchData();

        if(data && data.result && data.result ==="FAILED"){
          alert(data.message);
        }else{
          alert("Adjustment successfully deleted");
        }

      });
  }

  
  const handleSelectManager = (e, target)=> {
    // console.log("e", e);
    // console.log("target", target);
    // console.log("value", target.value);
    const options = [...e.target.selectedOptions];
    const values = options.map(option => option.value);
    setSelectedManagers(values);
    return false;
  };


  const emailManagers = () =>{
    // console.log("adjustmentManagers", adjustmentManagers);
    const thisManagers = adjustmentManagers.filter((managerItem)=>{
      return selectedManagers.indexOf(managerItem.id.toString()) > -1;
    });
    // console.log("thisManagers", thisManagers);
    // console.log("DEV MODE, ONLY USING JSTOPCHICK@GEOENGINEERS.COM FOR TESTING PURPOSES")
    // console.log("selectedManagers", selectedManagers);
    // console.log("thisManagers.length", thisManagers.length);

    if(thisManagers.length){
      Utils.postData(
        "/api/compensation/email", 
        {
          adjustment_uuid : activeAdjustment.adjustment_uuid,
          manager_list :  thisManagers,
          email_body : draftToHtml(convertToRaw(editorState.getCurrentContent())),
          email_subject: emailSubject,
          status: adjustmentStatus,
          effective_date: activeAdjustment.effective_date
        },
        instance, 
        account
      ).then((data) => {
        alert("Managers emailed.");
        closeEmailModal();
      });
  
    }else{
      alert("Please select at least one manager to continue.");
    }

  }
  

  // console.log("instance", instance, "accounts", accounts, "inProgress", inProgress);
  // console.log("userData", userData)

  const processIneligibleEmployees = (adjustmentsArray)=>{
    // console.log("adjustmentsArray", adjustmentsArray);

    let employeeList = [];

    // console.logs("adjustmentsArray", adjustmentsArray);

    for(var i=0;i<adjustmentsArray.length;i++){
      // console.log("This adjustment:", adjustmentsArray[i]);
      //check if there are indeed ineligible employees
      if(
        adjustmentsArray[i]
        && adjustmentsArray[i].adjustment_details
        && adjustmentsArray[i].adjustment_details.ineligible_employees
      ){

      for(var j=0;j<adjustmentsArray[i].adjustment_details.ineligible_employees.length;j++){
        // console.log("Ineligible Employees: ", adjustmentsArray[i].adjustment_details.ineligible_employees[j]);
        //if the ineligable employee isn't already in the list, add them
          if(!employeeList.find((employeeItem)=>{
            return employeeItem.first_name === adjustmentsArray[i].adjustment_details.ineligible_employees[j].first_name
              && employeeItem.first_name === adjustmentsArray[i].adjustment_details.ineligible_employees[j].last_name
          })){

            // console.log("adjustmentsArray[i]", i, adjustmentsArray[i])

            //only show employees with a matching status (can be seen in the employee_list_status in this object
            if(
              adjustmentsArray[i].employee_list && adjustmentsArray[i].employee_list.indexOf(
                adjustmentsArray[i].adjustment_details.ineligible_employees[j].employee_hris_id
              ) > -1
            ){
              employeeList.push(adjustmentsArray[i].adjustment_details.ineligible_employees[j]);
            }

          }

        }
      }
    }

    return employeeList;
  }

  
  const matchedAccount = userData.find((userObject)=>{
    return userObject.email === accounts[0].username;
  });
  // console.log("matchedAccount", matchedAccount);
  const authorized = accounts && accounts.length && accounts[0].username && matchedAccount && matchedAccount.email === accounts[0].username;
  // console.log("pendingAdjustmentsData", pendingAdjustmentsData);
  const sampleUrl = "https://comp-tool.geoengineers.com/compensation?managerHrisId=-1&status=" + adjustmentStatus;
  // console.log("activeUser", activeUser);

  return authorized ? (<div>
    <Modal
      isOpen={modalUserIsOpen}
      onAfterOpen={afterOpenUserModal}
      onRequestClose={closeUserModal}
      style={modalStyles}
      contentLabel="User Details"
      ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
    >
      <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
        {modalUserTitle}
      </h2>
      <div className="modalBody">
        {modalUserBody}
      </div>
      <div className="modalFooter">
        <button onClick={()=>{
          saveSelectedUser(modalUserAction, modalSelectedUser);
        }}>{modalUserAction === "delete" ? "Delete" : "Save"}</button>
        <button onClick={closeUserModal}>Cancel</button>
      </div>
    </Modal> 

    <Modal
      isOpen={modalEmployeeListIsOpen}
      onAfterOpen={afterOpenEmployeeListModal}
      onRequestClose={closeEmployeeListModal}
      style={modalStyles}
      contentLabel="Adjustment Employees"
      ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
    >
      <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
        Viewing Adjustment Employees
      </h2>
      <div className="modalBody" style={{ maxHeight: "300px", overflowY: "scroll" }}>
        {adjustmentEmployeeList.map((employeeItem)=>{
          return <div key={Utils.guid()}>
            <span>{Utils.decodeHTML(employeeItem.last_name)}, {Utils.decodeHTML(employeeItem.first_name)}</span>
          </div>
        })}
      </div>
      <div className="modalFooter">
        <button onClick={closeEmployeeListModal}>Close</button>
      </div>
    </Modal> 
       
    <Modal
      isOpen={modalCompensationIsOpen}
      onAfterOpen={afterOpenCompensationModal}
      onRequestClose={closeCompensationModal}
      style={requestCompensationModalStyles}
      contentLabel="Request Adjustments"
      ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
    >
      <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
        Open Compensation
      </h2>
      <div className="modalBody">
        Opening Date: 
        <Datetime 
            dateFormat="MM-DD-YYYY" 
            timeFormat={false}
            closeOnSelect={true} 
            onChange={e => {
              // console.log(e, e.format("MM-DD-YYYY"));
              setCompensationOpenDate(e.format("MM-DD-YYYY"))
            }} 
        /><br />
        Effective Date: 
        <Datetime 
            dateFormat="MM-DD-YYYY" 
            timeFormat={false}
            closeOnSelect={true} 
            onChange={e => {
              // console.log(e, e.format("MM-DD-YYYY"));
              setCompensationEffectiveDate(e.format("MM-DD-YYYY"))
            }} 
        /><br />
        Deadline Date: 
        <Datetime 
            dateFormat="MM-DD-YYYY" 
            timeFormat={false}
            closeOnSelect={true} 
            onChange={e => {
              // console.log(e, e.format("MM-DD-YYYY"));
              setCompensationDeadlineDate(e.format("MM-DD-YYYY"))
            }} 
        />
        <br />
        Override current open adjustments: 
        <input 
          type='checkbox' 
          checked={overrideCurrentAdjustments}
          id='overrideCurrentAdjustments' 
          name='overrideCurrentAdjustments' 
          onChange={e => {
            // console.log("e.target.value", e.target.checked, e.target);
            setOverrideCurrentAdjustments(e.target.checked)
          }} /><br />
      </div>
      <div className="modalFooter">
        <button onClick={()=>{
          // console.log("VALUES:", compensationEffectiveDate, overrideCurrentAdjustments);
          
          requestCompensations();

        }}>OK</button>
        <button onClick={closeCompensationModal}>Cancel</button>
      </div>
    </Modal> 

    <Modal
      isOpen={modalRequestResultsIsOpen}
      onAfterOpen={afterOpenRequestResultsModal}
      onRequestClose={closeRequestResultsModal}
      style={modalStyles}
      contentLabel="Request Results"
      ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
    >
      <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
        Employees Excluded from Compensation Request
      </h2>
      <div className="modalBody modalRequestResultBody">
        <div>
          <span className="hrLabelPendingRequests wide"><b>Employee Number</b></span>
          <span className="hrLabelPendingRequests wide"><b>First Name</b></span>
          <span className="hrLabelPendingRequests wide"><b>Last Name</b></span>
          <span className="hrLabelPendingRequests"><b>Date of Hire</b></span>
        </div>
        {
            ineligibleEmployees ? 
            ineligibleEmployees.sort((a,b)=>{
              var textA = a.first_name.toUpperCase();
              var textB = b.first_name.toUpperCase();              
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            }
        ).map((employeeObject)=>{
          return <div key={Utils.guid()}>
            <span className="hrLabelPendingRequests wide">{employeeObject.employee_hris_id}</span>
            <span className="hrLabelPendingRequests wide">{Utils.decodeHTML(employeeObject.first_name)}</span>
            <span className="hrLabelPendingRequests wide">{Utils.decodeHTML(employeeObject.last_name)}</span>
            <span className="hrLabelPendingRequests">{Utils.maskDateAs("mdy", new Date(employeeObject.date_of_hire.substring(0,10) + " 12:00:00"))}</span>
          </div>;
        }) : "No exclusions for this adjustment"}
      </div>
      <div className="modalFooter">
        <button onClick={()=>{
          closeRequestResultsModal()
        }}>OK</button>
      </div>
    </Modal>

    <Modal
      isOpen={modalEmailIsOpen}
      onAfterOpen={afterOpenEmailModal}
      onRequestClose={closeEmailModal}
      style={modalStyles}
      contentLabel="Send Emails"
      ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
    >
      <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
        Send compensation email to managers
      </h2>
      <div className="modalBody">

        <span style={{ fontWeight:"bold",fontSize:"10pt", fontStyle:"italic" }}>
          Note: Use Ctrl+Click to select and deselect.
        </span><br />
        <select 
          multiple={true} 
          name='managers_list' 
          value={selectedManagers} 
          onChange={(event)=>{
            event.preventDefault();
            // console.log("EVENT", event, event.target);
            handleSelectManager(event, event.target);
            return false;
          }}
          style={{
            width: "300px"
          }}
        >
          {/* <option key={Utils.guid()} value="-1">--All Managers--</option> */}
          {adjustmentManagers ? adjustmentManagers.map((adjustmentManagerItem)=>{
            return <option key={Utils.guid()} value={adjustmentManagerItem.id}>
              {Utils.decodeHTML(adjustmentManagerItem.last_name)}, {Utils.decodeHTML(adjustmentManagerItem.first_name)}
            </option>;
          }) : null}
        </select><br /><br />

        Subject: 
        <TextField 
          type="text" 
          name="emailSubject" 
          id="emailSubject" 
          className="emailSubjectInput" 
          value={emailSubject} 
          fullWidth={true}
          onChange={(event)=>{setEmailSubject(event.target.value);}}
        />

        <Editor
          id="emailContents"
          editorState={editorState}
          wrapperClassName="hr-editor-wrapper"
          editorClassName="hr-editor-input"
          onEditorStateChange={onEditorStateChange}
        />
        
      </div>
      <div className="modalFooter">
        
        <button onClick={()=>{
          openPreviewModal();
          return false;
        }}>Preview Email</button>

        &nbsp;&nbsp;&nbsp;
      
        <button onClick={()=>{
          let confirmationMessage = "This will email the selected managers with a request for compensation updates.  Continue?";
          if(adjustmentStatus==="finalized"){
            confirmationMessage = "This will email the selected managers confirming their adjustments are finalized.  Continue?"
          }

          if(window.confirm(confirmationMessage)){
            // console.log("EVENT", event, pendingAdjustmentItem);
            emailManagers();
          }

          return false;
        }}>Send Emails</button>
        
        <button onClick={closeEmailModal}>Cancel</button>
      
      </div>
    </Modal> 


    <Modal
      isOpen={modalDateIsOpen}
      onAfterOpen={afterOpenDateModal}
      onRequestClose={closeDateModal}
      style={dateModalStyles}
      contentLabel="Change Date"
      ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
    >
      <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
        Set {modalDateType} Date
      </h2>
      <div className="modalBody">
        {modalDateType} Date: {modalDateValue}
        <Datetime 
            value={new Date(modalDateValue + " 12:00:00")}
            dateFormat="MM-DD-YYYY" 
            timeFormat={false}
            closeOnSelect={true} 
            onChange={e => {
              // console.log(e, e.format("MM-DD-YYYY"));
              setModalDateValue(e.format("MM-DD-YYYY"))
              return false;
            }} 
        />
      </div>
      <div className="modalFooter">
        <button onClick={()=>{
          // console.log("modalDateType",modalDateType);
          // console.log("modalDateValue",modalDateValue);
          // console.log("modalDateAdjustment",modalDateAdjustment);

          Utils.postData(
            "/api/employee_adjustment/set_dates", 
            {
              open_date: modalDateAdjustment.open_date,
              effective_date: modalDateAdjustment.effective_date,
              deadline_date: modalDateAdjustment.deadline_date,
              new_date_type : modalDateType,
              new_date_value : modalDateValue
            },
            instance, 
            account
          ).then((data) => {
      
            // console.log("DATA", data);
            closeDateModal();
            fetchData();

          }).catch((error)=>{
            
            console.log("ERROR", error);
            alert("There was an error creating the requested compensation.");
            
          });

          return false;
        }}>OK</button>
        <button onClick={closeDateModal}>Cancel</button>
      </div>
    </Modal> 


    <Modal
      isOpen={modalPreviewIsOpen}
      onAfterOpen={afterOpenPreviewModal}
      onRequestClose={closePreviewModal}
      style={modalStyles}
      contentLabel="Preview email"
      ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
    >
      <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
        Email Preview
      </h2>
      <div className="modalBody previewEmailBlock">
      
        <Editor 
          toolbarHidden
          editorState={
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(
                  draftToHtml(
                    convertToRaw(
                      editorState.getCurrentContent()
                    )
                  ).replace(/#COMPENSATIONLINK#/g, 
                    sampleUrl+
                    (adjustmentStatus==="finalized"?("&effectiveDate="+(activeAdjustment?activeAdjustment.effective_date:"")):"")
                  )
                )
              )
            )
          } 
          readOnly={true} 
        /> 
      </div>
      <div className="modalFooter">
        <button onClick={closePreviewModal}>Close</button>
      </div>
    </Modal> 


    <div>
      <div className={'bold header'}>Access Controls</div>
      <div className={'bold'}>You are logged in as {accounts ? accounts[0].username : "UNKNOWN"}</div>
      <div>&nbsp;</div>
      <div>
        <div key={Utils.guid()} className={'bold'}>Users List</div>
        <div key={Utils.guid()}>&nbsp;</div>
        {userData.map((account)=>{
          return <div key={Utils.guid()}>
            <span onClick={()=>{
              openUserModal("edit", account)
            }} className="iconContainer"><EditIcon /></span>
            <span onClick={()=>{
              openUserModal("delete", account)
            }} className="iconContainer"><DeleteIcon /></span>
            <span className="emailContainer">{account.email} ({account.access_level})</span>
          </div>;
        })}
        <div key={Utils.guid()}>&nbsp;</div>
        <div>
          <button onClick={()=>{
              openUserModal("add", null)
            }}>Add New Administrator</button>
        </div>
      </div>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
    </div>
    <div className={'bold header'}>Adjustments</div>
    <div>
      <a href='/compensation'>Show all open adjustments</a>      
      <br />
      <br />
      {pendingAdjustmentsData && pendingAdjustmentsData.length ? (<div>
        Non-Submitted Compensation Adjustments: 
        {pendingAdjustmentsData.map((pendingAdjustmentItem)=>{
          return <div key={Utils.guid()}>
            <span className='hrViewPendingRequests'><a href={'/adjustments?status=pending&effective_date=' + pendingAdjustmentItem.effective_date}>View</a></span>
            <span className='hrViewPendingRequests wide'><a href="#" onClick={(event)=>{
              event.preventDefault();
              // console.log("pendingAdjustmentItem", pendingAdjustmentItem);

              Utils.getData(
                ('/api/compensation/managers?status=pending&effective_date='+pendingAdjustmentItem.effective_date), 
                instance, 
                account
              ).then((adjustmentManagers)=>{
                let filteredManagers = [];
                let invalidManagers = [];
                for(var i=0;i<adjustmentManagers.length;i++){
                  // console.log("adjustmentManagers", adjustmentManagers[i]);
                  if(adjustmentManagers[i].email){
                    filteredManagers.push(adjustmentManagers[i]);
                  }else{
                    invalidManagers.push(adjustmentManagers[i].first_name + " " + adjustmentManagers[i].last_name);
                  }
                }

                let managerMessage = "The following managers are no longer valid.  Please review they are still members of the GeoEngineers Inc. team: " + invalidManagers.join("\n") + "\nPress OK to continue without these users.";

                if(invalidManagers.length && window.confirm(managerMessage)){
                  openEmailModal(pendingAdjustmentItem, filteredManagers, "pending");
                }else{
                  openEmailModal(pendingAdjustmentItem, filteredManagers, "pending");
                }
              });
              
              return false;
            }}>Email Managers</a></span>            
            <span className='hrLabelDateRequests'>
              Opening Date: <a href="#" onClick={()=>{
                openDateModal(pendingAdjustmentItem, "Open", "pending");
                return false;
              }}>{Utils.maskDateAs("mdy", new Date(pendingAdjustmentItem.open_date + " 12:00:00"))}</a><br />
              Effective Date:  <a href="#" onClick={()=>{
                openDateModal(pendingAdjustmentItem, "Effective", "pending");
                return false;
              }}>{Utils.maskDateAs("mdy", new Date(pendingAdjustmentItem.effective_date + " 12:00:00"))}</a><br />
              Deadline Date:  <a href="#" onClick={()=>{
                openDateModal(pendingAdjustmentItem, "Deadline", "pending");
                return false;
              }}>{pendingAdjustmentItem.deadline_date ? Utils.maskDateAs("mdy", new Date(pendingAdjustmentItem.deadline_date + " 12:00:00")) : "No Deadline"}</a>
            </span>
            <span className='hrLabelUuidRequests'>
              <a href="#" onClick={(event)=>{
                event.preventDefault();

                Utils.getData(
                  ('/api/employee_adjustment?status=pending&effective_date=' + pendingAdjustmentItem.effective_date), 
                  instance, 
                  account
                ).then((jsonEmployeeList)=>{
                  setAdjustmentEmployeeList(jsonEmployeeList);
                  openEmployeeListModal();
                });

                return false;
              }}>Employees</a>&nbsp;
            </span>
            <span className='hrLabelEmployeesRequests'>Total Employees: {pendingAdjustmentItem.num_employees}</span>
            <span className='hrViewPendingRequests'>
              <a href="#" onClick={(event)=>{
                event.preventDefault();

                Utils.getData(
                  ('/api/employee_adjustment/details?status=pending&effective_date=' + pendingAdjustmentItem.effective_date), 
                  instance, 
                  account
                ).then((jsonAdjustmentLogResponse)=>{
                  // console.log("jsonAdjustmentLogResponse", jsonAdjustmentLogResponse);
                  //get logged exclusions
                  const processEmployees = processIneligibleEmployees(jsonAdjustmentLogResponse);
                  setIneligibleEmployees(processEmployees);
                  openRequestResultsModal();
                });

                return false;
              }}>Exclusions</a>&nbsp;
            </span>
            <span className='hrViewPendingRequests'><a href="#" onClick={(event)=>{
              event.preventDefault();
              if(window.confirm("WARNING: This is archive/close all compensation adjustments connected to this request.")){
                // console.log("EVENT", event, pendingAdjustmentItem);
                closeAdjustmentByDateAndStatus(pendingAdjustmentItem.effective_date, "pending");
              }
              return false;
            }}>Close</a></span>             
            <span className='hrViewPendingRequests'><a href="#" onClick={(event)=>{
              event.preventDefault();
              if(window.confirm("WARNING: This is remove all compensation adjustments connected to this request.")){
                // console.log("EVENT", event, pendingAdjustmentItem);
                deleteAdjustmentByDateAndStatus(pendingAdjustmentItem.effective_date, "pending");
              }
              return false;
            }}>Delete</a></span>             
          </div>;
        })}
        <div>&nbsp;</div>
      </div>) : null}
    </div>

    <div>
      {reviewAdjustmentsData && reviewAdjustmentsData.length ? (<div>
        Submitted Compensation Adjustments: 
        {reviewAdjustmentsData.map((reviewAdjustmentItem)=>{
          return <div key={Utils.guid()}>
            <span className='hrViewPendingRequests'><a href={'/adjustments?status=review&effective_date=' + reviewAdjustmentItem.effective_date}>View</a></span>
            <span className='hrViewPendingRequests wide'><a href="#" onClick={(event)=>{
              event.preventDefault();
              // console.log("reviewAdjustmentItem", reviewAdjustmentItem);

              Utils.getData(
                ('/api/compensation/managers?status=review&effective_date='+reviewAdjustmentItem.effective_date), 
                instance, 
                account
              ).then((adjustmentManagers)=>{
                let filteredManagers = [];
                let invalidManagers = [];
                for(var i=0;i<adjustmentManagers.length;i++){
                  // console.log("adjustmentManagers", adjustmentManagers[i]);
                  if(adjustmentManagers[i].email){
                    filteredManagers.push(adjustmentManagers[i]);
                  }else{
                    invalidManagers.push(adjustmentManagers[i].first_name + " " + adjustmentManagers[i].last_name);
                  }
                }

                let managerMessage = "The following managers are no longer valid.  Please review they are still members of the GeoEngineers Inc. team: " + invalidManagers.join("\n") + "\nPress OK to continue without these users.";

                if(invalidManagers.length && window.confirm(managerMessage)){
                  openEmailModal(reviewAdjustmentItem, adjustmentManagers, "review");
                }else{
                  openEmailModal(reviewAdjustmentItem, adjustmentManagers, "review");
                }
              });
              
              return false;
            }}>Email Managers</a></span>            
            <span className='hrLabelDateRequests'>
              Opening Date: {Utils.maskDateAs("mdy", new Date(reviewAdjustmentItem.open_date + " 12:00:00"))}<br />
              Effective Date: {Utils.maskDateAs("mdy", new Date(reviewAdjustmentItem.effective_date + " 12:00:00"))}<br />
              Deadline Date: {reviewAdjustmentItem.deadline_date ? Utils.maskDateAs("mdy", new Date(reviewAdjustmentItem.deadline_date + " 12:00:00")) : "No Deadline"} 
            </span>
            <span className='hrLabelUuidRequests'>
              <a href="#" onClick={(event)=>{
                event.preventDefault();

                Utils.getData(
                  ('/api/employee_adjustment?status=review&effective_date=' + reviewAdjustmentItem.effective_date), 
                  instance, 
                  account
                ).then((jsonEmployeeList)=>{
                  setAdjustmentEmployeeList(jsonEmployeeList);
                  openEmployeeListModal();
                });

                return false;
              }}>Employees</a>&nbsp;
            </span>
            <span className='hrLabelEmployeesRequests'>Total Employees: {reviewAdjustmentItem.num_employees}</span>
            <span className='hrViewPendingRequests'>
              <a href="#" onClick={(event)=>{
                event.preventDefault();

                Utils.getData(
                  ('/api/employee_adjustment/details?status=review&effective_date=' + reviewAdjustmentItem.effective_date), 
                  instance, 
                  account
                ).then((jsonAdjustmentLogResponse)=>{
                  // console.log("jsonAdjustmentLogResponse", jsonAdjustmentLogResponse);
                  //get logged exclusions
                  const processEmployees = processIneligibleEmployees(jsonAdjustmentLogResponse);
                  setIneligibleEmployees(processEmployees);
                  openRequestResultsModal();
                });

                return false;
              }}>Exclusions</a>&nbsp;
            </span>
            <span className='hrViewPendingRequests'><a href="#" onClick={(event)=>{
              event.preventDefault();
              if(window.confirm("WARNING: This is archive/close all compensation adjustments connected to this request.")){
                // console.log("EVENT", event, pendingAdjustmentItem);
                closeAdjustmentByDateAndStatus(reviewAdjustmentItem.effective_date, "review");
              }
              return false;
            }}>Close</a></span>
            <span className='hrViewPendingRequests'><a href="#" onClick={(event)=>{
              event.preventDefault();
              if(window.confirm("WARNING: This is remove all compensation adjustments connected to this request.")){
                // console.log("EVENT", event, pendingAdjustmentItem);
                deleteAdjustmentByDateAndStatus(reviewAdjustmentItem.effective_date, "review");
              }
              return false;
            }}>Delete</a></span>                
          </div>;
        })}
        <div>&nbsp;</div>
      </div>) : null}
    </div>

    <div>
      {finalizedAdjustmentsData && finalizedAdjustmentsData.length ? (<div>
        Finalized Compensation Adjustments: 
        {finalizedAdjustmentsData.map((finalizedAdjustmentItem)=>{
          return <div key={Utils.guid()}>
            <span className='hrViewPendingRequests'><a href={'/adjustments?status=finalized&effective_date=' + finalizedAdjustmentItem.effective_date}>View</a></span>
            <span className='hrViewPendingRequests wide'><a href="#" onClick={(event)=>{
              event.preventDefault();
              // console.log("finalizedAdjustmentItem", finalizedAdjustmentItem);

              Utils.getData(
                ('/api/compensation/managers?status=finalized&effective_date='+finalizedAdjustmentItem.effective_date), 
                instance, 
                account
              ).then((adjustmentManagers)=>{
                let filteredManagers = [];
                let invalidManagers = [];
                for(var i=0;i<adjustmentManagers.length;i++){
                  // console.log("adjustmentManagers", adjustmentManagers[i]);
                  if(adjustmentManagers[i].email){
                    filteredManagers.push(adjustmentManagers[i]);
                  }else{
                    invalidManagers.push(adjustmentManagers[i].first_name + " " + adjustmentManagers[i].last_name);
                  }
                }

                let managerMessage = "The following managers are no longer valid.  Please review they are still members of the GeoEngineers Inc. team: " + invalidManagers.join("\n") + "\nPress OK to continue without these users.";

                if(invalidManagers.length && window.confirm(managerMessage)){
                  openEmailModal(finalizedAdjustmentItem, adjustmentManagers, "finalized");
                }else{
                  openEmailModal(finalizedAdjustmentItem, adjustmentManagers, "finalized");
                }
              });
              
              return false;
            }}>Email Managers</a></span>            
            <span className='hrLabelDateRequests'>
              Opening Date: {Utils.maskDateAs("mdy", new Date(finalizedAdjustmentItem.open_date + " 12:00:00"))}<br />
              Effective Date: {Utils.maskDateAs("mdy", new Date(finalizedAdjustmentItem.effective_date + " 12:00:00"))}<br />
              Deadline Date: {finalizedAdjustmentItem.deadline_date ? Utils.maskDateAs("mdy", new Date(finalizedAdjustmentItem.deadline_date + " 12:00:00")) : "No Deadline"} 
            </span>
            <span className='hrLabelPendingRequests'>
              <a href="#" onClick={(event)=>{
                event.preventDefault();

                Utils.getData(
                  ('/api/employee_adjustment?status=finalized&effective_date=' + finalizedAdjustmentItem.effective_date), 
                  instance, 
                  account
                ).then((jsonEmployeeList)=>{
                  setAdjustmentEmployeeList(jsonEmployeeList);
                  openEmployeeListModal();
                });

                return false;
              }}>Employees</a>&nbsp;              
            </span>
            <span className='hrLabelEmployeesRequests'>Total Employees: {finalizedAdjustmentItem.num_employees}</span>
            <span className='hrViewPendingRequests'><a href="#" onClick={(event)=>{
              event.preventDefault();

              Utils.getData(
                ('/api/employee_adjustment/details?status=finalized&effective_date=' + finalizedAdjustmentItem.effective_date), 
                instance, 
                account
              ).then((jsonAdjustmentLogResponse)=>{
                // console.log("jsonAdjustmentLogResponse", jsonAdjustmentLogResponse);
                //get logged exclusions
                const processEmployees = processIneligibleEmployees(jsonAdjustmentLogResponse);
                setIneligibleEmployees(processEmployees);
                openRequestResultsModal();
              });

              return false;
            }}>Exclusions</a>&nbsp;</span>   
            
            {activeUser.access_level === "SUPERADMIN" ? <span className='hrViewPendingRequests'><a href="#" onClick={(event)=>{
              event.preventDefault();
              if(window.confirm("WARNING: This is archive/close all compensation adjustments connected to this request.")){
                // console.log("EVENT", event, pendingAdjustmentItem);
                closeAdjustmentByDateAndStatus(finalizedAdjustmentItem.effective_date, "finalized");
              }
              return false;
            }}>Close</a></span> : null}
          </div>;
        })}
        <div>&nbsp;</div>
      </div>) : null} 
    </div>

    <div>
      {closedAdjustmentsData && closedAdjustmentsData.length ? (<div>
        Closed Compensation Adjustments:
        {closedAdjustmentsData.map((closedAdjustmentItem)=>{
          return <div key={Utils.guid()}>
            <span className='hrViewPendingRequests'><a href={'/adjustments?status=closed&effective_date=' + closedAdjustmentItem.effective_date}>View</a></span>
            <span className='hrViewPendingRequests wide'><a href="#" onClick={(event)=>{
              event.preventDefault();
              // console.log("closedAdjustmentItem", closedAdjustmentItem);

              Utils.getData(
                ('/api/compensation/managers?status=closed&effective_date='+closedAdjustmentItem.effective_date), 
                instance, 
                account
              ).then((adjustmentManagers)=>{
                let filteredManagers = [];
                let invalidManagers = [];
                for(var i=0;i<adjustmentManagers.length;i++){
                  // console.log("adjustmentManagers", adjustmentManagers[i]);
                  if(adjustmentManagers[i].email){
                    filteredManagers.push(adjustmentManagers[i]);
                  }else{
                    invalidManagers.push(adjustmentManagers[i].first_name + " " + adjustmentManagers[i].last_name);
                  }
                }

                let managerMessage = "The following managers are no longer valid.  Please review they are still members of the GeoEngineers Inc. team: " + invalidManagers.join("\n") + "\nPress OK to continue without these users.";

                if(invalidManagers.length && window.confirm(managerMessage)){
                  openEmailModal(closedAdjustmentItem, adjustmentManagers, "closed");
                }else{
                  openEmailModal(closedAdjustmentItem, adjustmentManagers, "closed");
                }
              });
              
              return false;
            }}>Email Managers</a></span>            
            <span className='hrLabelDateRequests'>
              Opening Date: {Utils.maskDateAs("mdy", new Date(closedAdjustmentItem.open_date + " 12:00:00"))}<br />
              Effective Date: {Utils.maskDateAs("mdy", new Date(closedAdjustmentItem.effective_date + " 12:00:00"))}<br />
              Deadline Date: {closedAdjustmentItem.deadline_date ? Utils.maskDateAs("mdy", new Date(closedAdjustmentItem.deadline_date + " 12:00:00")) : "No Deadline"} 
            </span>
            <span className='hrLabelPendingRequests'>
              <a href="#" onClick={(event)=>{
                event.preventDefault();

                Utils.getData(
                  ('/api/employee_adjustment?status=closed&effective_date=' + closedAdjustmentItem.effective_date), 
                  instance, 
                  account
                ).then((jsonEmployeeList)=>{
                  setAdjustmentEmployeeList(jsonEmployeeList);
                  openEmployeeListModal();
                });

                return false;
              }}>Employees</a>&nbsp;              
            </span>
            <span className='hrLabelEmployeesRequests'>Total Employees: {closedAdjustmentItem.num_employees}</span>
            <span className='hrViewPendingRequests'><a href="#" onClick={(event)=>{
              event.preventDefault();

              Utils.getData(
                ('/api/employee_adjustment/details?status=closed&effective_date=' + closedAdjustmentItem.effective_date), 
                instance, 
                account
              ).then((jsonAdjustmentLogResponse)=>{
                // console.log("jsonAdjustmentLogResponse", jsonAdjustmentLogResponse);
                //get logged exclusions
                const processEmployees = processIneligibleEmployees(jsonAdjustmentLogResponse);
                setIneligibleEmployees(processEmployees);
                openRequestResultsModal();
              });

              return false;
            }}>Exclusions</a>&nbsp;</span>
          </div>;
        })}
        <div>&nbsp;</div>
      </div>) : null} 
    </div>

    <div>
      
      <button onClick={()=>{
        const year = (new Date()).getFullYear();

          openCompensationModal();

      }}>Create Compensation Requests For All Employees</button>
    </div>
    <div>&nbsp;</div>
    <div>
      <button onClick={()=>{
        if(window.confirm("Warning: This will reset all manager approvals!")){
          Utils.deleteData(
            "/api/employee/mananger_approvals", 
            instance, 
            account
          ).then((data) => {
            alert("Approvals have been reset.");
          });  
        }
      }}>Reset all manager approvals</button>
    </div>
  </div>) : (<div>Unavailable</div>);
};

export default HR;