import React, { useState, useMemo, useEffect }  from 'react';
import Modal from 'react-modal';
import { useMsal, useAccount } from "@azure/msal-react";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { MenuItem, touchRippleClasses } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SaveIcon from '@mui/icons-material/Save';
import LayersIcon from '@mui/icons-material/Layers';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import GridOnIcon from '@mui/icons-material/GridOn';
import GridOffIcon from '@mui/icons-material/GridOff';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import moment from 'moment';
import jQuery, { event } from 'jquery';

import * as Utils from '../lib/utils';

import { protectedResources } from "../config/authConfig";
import CONFIG from '../config';

window.jQuery = jQuery;
require("jsgrid");

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const modalInstructionStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


const debounce = function(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}

//calculates row field values when a change is made in the form
const runSalaryCalculations = (r, suppressNaN) =>{
  const baseSalary = r.base_salary ? parseInt(r.base_salary) : 0;
  
  // console.log("r", r);
  // console.log("baseSalary", baseSalary);
  // console.log("annual_salary", r.annual_salary)
  // console.log("action_2_percent_increase", r.action_2_percent_increase);
  // console.log("current_ft_annualized_salary", r.current_ft_annualized_salary);

  r.raw_salary_increase_amount = r.hours_week >= 40 && baseSalary ? 
    (baseSalary*(r.action_2_percent_increase*0.01)) : 
    (
      r.annual_salary ? 
        (r.annual_salary*(r.action_2_percent_increase*0.01)) : 
        (r.current_ft_annualized_salary*(r.action_2_percent_increase*0.01))
    )

  r.new_ft_annualized_salary_increase_amount = r.hours_week >= 40 && baseSalary ? 
      (baseSalary + r.raw_salary_increase_amount) : 
      (r.current_ft_annualized_salary + r.raw_salary_increase_amount);      

  r.new_salary_increase_amount_lt_40 = r.hours_week < 40 && r.annual_salary ? 
      (parseFloat(r.annual_salary) + parseFloat(r.raw_salary_increase_amount)) : 
      (
        r.hours_week < 40 && baseSalary ? 
          (parseFloat(baseSalary) + parseFloat(r.raw_salary_increase_amount)) :
          ""
      );

  r.amount_to_deduct_from_budget = r.raw_salary_increase_amount;
  
  //ROUND THIS VALUE AT THE COLUMN ACCESSOR
  r.new_ft_annualized_salary_no_manager_premium_recommendation = r.hours_week < 40 && r.annual_salary ?
    ( r.new_salary_increase_amount_lt_40 ? ((r.new_salary_increase_amount_lt_40 * 40) / r.hours_week) : "" ) : 
    (
      r.manager_premium && r.base_salary && r.base_salary !== "0" ? 
        (parseFloat(r.raw_salary_increase_amount) + parseFloat(r.base_salary)) : 
        (parseFloat(r.raw_salary_increase_amount) + parseFloat(r.current_ft_annualized_salary))
    )
  
  const managerPremium = r.adjustment_premium ? parseFloat(r.adjustment_premium)  : 0;
  
  r.new_ft_annualized_salary_with_manager_premium_recommendation = 
    (
      (managerPremium*0.01*r.new_ft_annualized_salary_no_manager_premium_recommendation)+
      r.new_ft_annualized_salary_no_manager_premium_recommendation
    )
          
  // console.log("row", r, "r.base_salary", r.base_salary);

  if(r.current_position_salary_range_minimum || !suppressNaN){
    const salaryValueIfBase = r.base_salary && parseFloat(r.base_salary) && r.base_salary > 0 && r.hours_week < 40 && r.current_ft_annualized_salary ? 
      r.current_ft_annualized_salary : r.base_salary; 

    r.current_year_salary_placement_in_range = r.base_salary && parseFloat(r.base_salary) && r.base_salary > 0 ? 
      (
        (parseFloat(salaryValueIfBase)-r.current_position_salary_range_minimum)/
        (r.current_position_salary_range_maximum-r.current_position_salary_range_minimum)
      ) : 
      (
        (parseFloat(r.current_ft_annualized_salary)-r.current_position_salary_range_minimum)/
        (r.current_position_salary_range_maximum-r.current_position_salary_range_minimum)
      );   
  }else{
    r.current_year_salary_placement_in_range = "";
  }

  if((r.action_1_adjustment_or_promotion === "Promotion" && r.promotion_salary_range_minimum) || r.current_position_salary_range_minimum || !suppressNaN){
    r.new_current_year_salary_placement_in_range = r.action_1_adjustment_or_promotion === "Promotion" ? 
      (
        (r.new_ft_annualized_salary_no_manager_premium_recommendation-r.promotion_salary_range_minimum)/
        (r.promotion_salary_range_maximum-r.promotion_salary_range_minimum)
      ) : 
      (
        (r.new_ft_annualized_salary_no_manager_premium_recommendation-r.current_position_salary_range_minimum)/
        (r.current_position_salary_range_maximum-r.current_position_salary_range_minimum)
      );   
  }else{
    r.new_current_year_salary_placement_in_range = "";
  }
  
  // console.log("R", r)
  
  return r;
};

//nested data is ok, see accessorKeys in ColumnDef below  
const Compensation = (props) => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [dimensions, setDimensions] = useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  });

  
  const hasDisclaimerShown = localStorage.getItem("compensation-hasDisclaimerShown");
  const [disclaimerShown, setDisclaimerShown] = useState(hasDisclaimerShown ? hasDisclaimerShown : "no");

  // const [compCycleDetails, setCompCycleDetails] = useState({});
  const [tableData, setTableData] = useState([]);
  const [userHasAccess, setUserHasAccess] = useState(false);
  const [accessMessage, setAccessMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);    
  const [rowCount, setRowCount] = useState(0);
  const [dataIsFetched, setDataIsFetched] = useState(false);

  const [budgetObject, setBudgetObject] = useState({ budget : 0 });
  const [teamBudget, setTeamBudget] = useState(0);
  const [totalSpend, setTotalSpend] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const [isFinalized, setIsFinalized] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  const [ineligibleEmployees, setIneligibleEmployees] = useState([])

  const [promotionAdvancements, setPromotionAdvancements] = useState([]);

  const [showAllReports, setShowAllReports] = useState(false);
  const [showOnlyReviews, setShowOnlyReviews] = useState(false);
  const [hideReviewees, setHideReviewees] = useState(false);
  const [showMissingRangeReports, setShowMissingRangeReports] = useState(false);
  
  const [modalDisclaimerIsOpen, setDisclaimerIsOpen] = useState(disclaimerShown === "no");
  const [modalInstructionsIsOpen, setInstructionsIsOpen] = useState(false);
  const [modalAffirmIsOpen, setAffirmIsOpen] = useState(false);

  const [affirmUsersArray, setAffirmUsersArray] = useState([]);
  const [lockAdjustmentsData, setLockAdjustmentsData] = useState({});

  const [modalAction3IsOpen, setAction3IsOpen] = useState(false);
  const [modalAction3Value, setModalAction3Value] = useState("")
  const [modalAction3Item, setModalAction3Item] = useState({})
  const [activeComments, setActiveComments] = useState([]);
  const [commentsMode, setCommentsMode] = useState("add");
  const [commentsIndex, setCommentsIndex] = useState(-1);

  const [modalHrNotesIsOpen, setHrNotesIsOpen] = useState(false);
  const [modalHrNotesValue, setModalHrNotesValue] = useState("")
  const [modalHrNotesItem, setModalHrNotesItem] = useState({})
  const [activeHrNotes, setActiveHrNotes] = useState([]);

  const [modalLogIsOpen, setLogIsOpen] = useState(false);
  const [selectedAdjustment, setSelectedAdjustment] = useState({});
  const [adjustmentLogs, setAdjustmentLogs] = useState([])

  const [selectedReviewerEmployee, setSelectedReviewerEmployee] = useState({});
  const [modalReviewersIsOpen, setModalReviewersIsOpen] = useState(false);
  const [selectedReviewers, setSelectedReviewers] = useState(false);

  const [closedAdjustmentsData, setClosedAdjustmentsData] = useState({});

  
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10
  });
  const [sorting, setSorting] = useState([]);
  const [columnPinning, setColumnPinning] = useState({
    'left':['employee_full_name']
  });

  // console.log("columnPinning", columnPinning);

  const [columnVisibility, setColumnVisibility] = useState([]);
  const [density, setDensity] = useState([]);

  const [isEnableEditingOn, setIsEnableEditingOn] = useState(true);
  
  const urlParams = Utils.getParams();
  const adjustmentUuid = urlParams.adjustmentUuid ? urlParams.adjustmentUuid : "";
  const adjustmentStatus = urlParams.status ? urlParams.status : "";
  const effectiveDate = urlParams.effectiveDate ? urlParams.effectiveDate : "";
  const managerHrisId = urlParams.managerHrisId ? urlParams.managerHrisId : "";
  const adjustmentId = urlParams.adjustmentId ? urlParams.adjustmentId : "";
  
  const [showOnlyReports, setShowOnlyReports] = useState(managerHrisId || adjustmentId ? false : true);
  const [viewingAs, setViewingAs] = useState({});

  const year = (new Date()).getFullYear();

  let subtitle;

  function openDisclaimerModal() {
    setDisclaimerIsOpen(true);
  } 
  function afterOpenDisclaimerModal() {
    localStorage.setItem("compensation-hasDisclaimerShown", "yes");
  }
  function closeDisclaimerModal() {
    setDisclaimerShown("yes");
    setDisclaimerIsOpen(false);
  }

  function openInstructionsModal() {
    setInstructionsIsOpen(true);
  } 
  function afterOpenInstructionsModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closeInstructionsModal() {
    setInstructionsIsOpen(false);
  }

  function openAffirmModal(callback) {
    setAffirmIsOpen(true);
  } 
  function afterOpenAffirmModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closeAffirmModal() {
    setAffirmIsOpen(false);
  }


  function openAction3Modal(mode, objectDetails, commentsObject, index) {
    // console.log("mode", mode, "objectDetails", objectDetails, "commentsObject", commentsObject, "index", index);
    setCommentsMode(mode);
    setCommentsIndex(index);
    setActiveComments(commentsObject);
      //These notes are now action 4, this should be renamed/updated
    setModalAction3Value(mode === "edit" ? commentsObject[index].comment : "");
    setModalAction3Item(objectDetails);
    setAction3IsOpen(true);
  } 
  function afterOpenAction3Modal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closeAction3Modal() {
    setAction3IsOpen(false);
  }


  function openHrNotesModal(objectDetails, notesObject) {
    setActiveHrNotes(notesObject);
    setModalHrNotesValue(objectDetails.administrator_notes);
    setModalHrNotesItem(objectDetails);
    setHrNotesIsOpen(true);
  } 
  function afterOpenHrNotesModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closeHrNotesModal() {
    setHrNotesIsOpen(false);
  }
  
  
  function openLogModal(objectDetails, logItems) {
    setSelectedAdjustment(objectDetails);
    setAdjustmentLogs(logItems);
    setLogIsOpen(true);
  } 
  function afterOpenLogModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closeLogModal() {
    setLogIsOpen(false);
  }
  
  
  
  function openReviewersModal(selectedReviewee, reviewersResult) {
    setSelectedReviewers(reviewersResult);
    setModalReviewersIsOpen(true);
  }     
  function afterOpenReviewersModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closeReviewersModal() {
    setModalReviewersIsOpen(false);
  }


  const fetchData = async () => {
    if (!tableData.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    try {
      //requires adjustment ID and manager ID, otherwise don't do anything
      //status 1 is "OPEN", status 2 is "COMPLETE", "COMPLETE" records should NEVER be editable directly
      let selectedManagerHrisId;
      if(managerHrisId){
        selectedManagerHrisId = managerHrisId;
      } 
      // else if(props && props.userAccount && props.userAccount.employee_hris_id){
      //   selectedManagerHrisId = props.userAccount.employee_hris_id;
      // }

      setIsEnableEditingOn(false);

      // console.log((
      //   '/api/compensation/employees' +
      //   '?viewer_email=' +  accounts[0].username + 
      //   "&status=" + adjustmentStatus + 
      //   "&show_finalized=true" + 
      //   "&effective_date=" + effectiveDate + 
      //   "&adjustment_id=" + adjustmentId + 
      //   "&manager_hris_id=" + (!showOnlyReports && selectedManagerHrisId ? selectedManagerHrisId : "") + 
      //   "&only_direct_reports=" + showOnlyReports + 
      //   "&show_all_reports=" + (showOnlyReviews ? true : showAllReports) + //show everyone if show only reviews is checked, to get all reviews as well
      //   "&recursive_reports=" + (showOnlyReviews ? true : showAllReports) + //show all if show all reports or reviews is clicked
      //   "&show_only_reviews=" + showOnlyReviews + 
      //   "&hide_reviewees=" + hideReviewees + 
      //   "&missing_ranges=" + showMissingRangeReports
      // ));

      const jsonResponse = await Utils.getData(
        (
          '/api/compensation/employees' +
          '?viewer_email=' +  accounts[0].username + 
          "&status=" + adjustmentStatus + 
          "&show_finalized=true" + 
          "&effective_date=" + effectiveDate + 
          "&adjustment_id=" + adjustmentId + 
          "&manager_hris_id=" + (!showOnlyReports && selectedManagerHrisId ? selectedManagerHrisId : "") + 
          "&only_direct_reports=" + showOnlyReports + 
          "&show_all_reports=" + (showOnlyReviews ? true : showAllReports) + //show everyone if show only reviews is checked, to get all reviews as well
          "&recursive_reports=" + (showOnlyReviews ? true : showAllReports) + //show all if show all reports or reviews is clicked
          "&show_only_reviews=" + showOnlyReviews + 
          "&hide_reviewees=" + hideReviewees + 
          "&missing_ranges=" + showMissingRangeReports
        ), 
        instance, 
        account
      );
      setIsEnableEditingOn(true);

      
      if(props && props.userAccount && managerHrisId && adjustmentStatus !== "finalized" && Array.isArray(jsonResponse) && jsonResponse.length === 0){
        if(
          props 
          && props.userAccount 
          && props.userAccount.access_level 
          && props.userAccount.access_level.indexOf("ADMIN") > -1
        ){
          window.location = "/hr";
        }else{
          // console.log("IGNORING REDIRECT!")
          window.location = "/compensation?status=finalized&managerHrisId="+managerHrisId;
        }         
        return false;
      }

      if((jsonResponse && jsonResponse.length && Array.isArray(jsonResponse)) || showMissingRangeReports){
        setUserHasAccess(true);

        setTableData(jsonResponse);
        setDataIsFetched(true);

        //this is a cheap approach, we check the first entry since locks are based on the manager ID, 
        //but the status is stored per row in case we ever need to use this per row in the future
        const isLocked = jsonResponse[0] && (jsonResponse[0].employee_adjustment_status === 2 || jsonResponse[0].employee_adjustment_status === 4) ? 
          true : false;
        setIsLocked(isLocked);

        const isFinalized = jsonResponse[0] && jsonResponse[0].employee_adjustment_status === 4 ? 
          true : false;
        setIsFinalized(isFinalized);

        const isClosed = jsonResponse[0] && jsonResponse[0].employee_adjustment_status === 5 ? 
          true : false;
        setIsClosed(isClosed);


        handleTotalSpend(jsonResponse);
        setRowCount(jsonResponse.length);  

        const jsonAdjustmentDetailsResponse = await Utils.getData(
          (
            '/api/employee_adjustment/details?manager_hris_id=' + managerHrisId
          ), 
          instance, 
          account
        ); 
        
        let ineligibleArray = [];
        for(var i=0;i<jsonAdjustmentDetailsResponse.length;i++){
          if(
            jsonAdjustmentDetailsResponse[i].adjustment_details && 
            jsonAdjustmentDetailsResponse[i].adjustment_details.ineligible_employees && 
            jsonAdjustmentDetailsResponse[i].adjustment_details.ineligible_employees.length
          ){
            for(var j=0;j<jsonAdjustmentDetailsResponse[i].adjustment_details.ineligible_employees.length;j++){
              ineligibleArray.push({
                effectiveDate : jsonAdjustmentDetailsResponse[i].adjustment_details.effective_date,
                employeeHrisId : jsonAdjustmentDetailsResponse[i].adjustment_details.ineligible_employees[j].employee_hris_id
              });              
            }
  
          }
        }

        setIneligibleEmployees(ineligibleArray); 


        const promotionAdvancementsResponse = await Utils.getData(
          ("/api/promotion_advancement/ranges?year="+year), 
          instance, 
          account
        );    
        setPromotionAdvancements(promotionAdvancementsResponse); 

      }else{
        setUserHasAccess(false);
        setTableData([]);
        setDataIsFetched(true);        
        if(jsonResponse.result=="NOTAUTHORIZED"){
          setAccessMessage("Not authorized.")
        }
      }

      if(managerHrisId){
        const jsonManagerResponse = await Utils.getData(
          ('/api/user/details?employee_hris_id='+managerHrisId), 
          instance, 
          account
        );

        // console.log("jsonManagerResponse", jsonManagerResponse)
        if(jsonManagerResponse && jsonManagerResponse.comp_screen_settings){
          if(jsonManagerResponse.comp_screen_settings.pageSize){
            setPagination({
              pageIndex: 0,
              pageSize: jsonManagerResponse.comp_screen_settings.pageSize
            });
          }
          if(jsonManagerResponse.comp_screen_settings.sorting){
            setSorting(jsonManagerResponse.comp_screen_settings.sorting);
          }
          if(jsonManagerResponse.comp_screen_settings.column_pinning){
            setColumnPinning(jsonManagerResponse.comp_screen_settings.column_pinning);
          }
          if(jsonManagerResponse.comp_screen_settings.column_visibility){
            setColumnVisibility(jsonManagerResponse.comp_screen_settings.column_visibility);
          }
          if(jsonManagerResponse.comp_screen_settings.density){
            setDensity(jsonManagerResponse.comp_screen_settings.density);
          }
        }

        setViewingAs(jsonManagerResponse);


        if(managerHrisId){
          const budgetResponse = await Utils.getData(
            ("/api/budget?include_team=true&manager_hris_id=" + managerHrisId), 
            instance, 
            account
          );
          let selectedBudgetIndex = {};
          let teamBudgetTotal = 0;
          for(let i=0;i<budgetResponse.length;i++){
            if(budgetResponse[i].employee_hris_id == managerHrisId){
              selectedBudgetIndex = i;
            }
            teamBudgetTotal = teamBudgetTotal + parseFloat(budgetResponse[i].budget);
          }
          // teamBudgetTotal = Math.round(teamBudgetTotal * 10) / 10;
          setBudgetObject(budgetResponse[selectedBudgetIndex]); 
          setTeamBudget(teamBudgetTotal); 
        }else{
          // console.log("No Manager ID set, ignoring budget.")
        }

        if(adjustmentStatus === "finalized" && managerHrisId){
          // console.log("props.userAccount", props.userAccount) //props.userAccount.employee_hris_id
          const jsonClosedAdjustmentsResponse = await Utils.getData(
            ('/api/closed?managerHrisId='+managerHrisId), 
            instance, 
            account
          );
          setClosedAdjustmentsData(jsonClosedAdjustmentsResponse);
        }

      }
            
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };
  

  useEffect(() => {
    fetchData();
  }, [showOnlyReports, showAllReports, showOnlyReviews, showMissingRangeReports, hideReviewees, props]);

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }, 500)

    //binds a resize listener for a debounced (less frequent) resize effect
    window.addEventListener('resize', debouncedHandleResize);
    
    //unbinds listener to prevent memory leaks
    return _ => {
      window.removeEventListener('resize', debouncedHandleResize)   
    };
  });

  const handleTotalSpend = (tableDataArray)=> {
    let totalBudget = 0;
    for(let i=0;i<tableDataArray.length;i++){
      totalBudget += parseFloat(tableDataArray[i].amount_to_deduct_from_budget ? tableDataArray[i].amount_to_deduct_from_budget : 0)
    }
    // totalBudget = Math.round(totalBudget * 10) / 10;
    setTotalSpend(totalBudget);
  }

  const isValidAdjustments = (adjustmentsArray) => {
    // console.log("adjustmentsArray", adjustmentsArray);
    let invalidResults = [];

    for(var i=0;i<adjustmentsArray.length;i++){
      if(isNaN(adjustmentsArray[i].percent_increase)){
        invalidResults.push({
          message : "Recommended precentage increase of \"" + adjustmentsArray[i].percent_increase + "\" is not a valid value." 
        })
      }
      if(isNaN(adjustmentsArray[i].adjustment_premium)){
        invalidResults.push({
          message : "Recommended adjustment premium increase of \"" + adjustmentsArray[i].percent_increase + "\" is not a valid value." 
        })
      }      
    }

    return invalidResults;
  }

  const saveRecommendations = (callback)=> {
    let adjustmentStates = [];
    for(var i=0;i<tableData.length;i++){
      //Don't save finalized entries
      if(tableData[i].employee_adjustment_status < 4 || isSuperAdmin){
        adjustmentStates.push({
          employee_adjustment_id : tableData[i].adjustment_id,
          adjustment_uuid : tableData[i].adjustment_uuid,
          employee_hris_id : tableData[i].employee_hris_id,
          change_type : tableData[i].action_1_adjustment_or_promotion,
          percent_increase : tableData[i].action_2_percent_increase ? tableData[i].action_2_percent_increase : 0,
          //These notes are now action 4, this should be renamed/updated
          manager_notes : tableData[i].manager_promotion_adjustment_rationale,
          administrator_notes : tableData[i].administrator_notes,
          adjustment_premium : tableData[i].adjustment_premium ? tableData[i].adjustment_premium : 0,
          selected_promotion_title : tableData[i].selected_promotion_title ? tableData[i].selected_promotion_title : ""
        });
      }
    }

    const isValidResult = isValidAdjustments(adjustmentStates);
    if(isValidResult.length){
      let popupMessage = "";

      for(var i=0;i<isValidResult.length;i++){
        popupMessage = popupMessage + "\n" + isValidResult[i].message;
      }
      
      alert("There is an error in your selections.  Please correct them before savings." + popupMessage);

      return false;
    }else{

      Utils.postData(
        "/api/compensation/adjustment_selection", 
        {
          selections: adjustmentStates
        },
        instance,
        account
      ).then((data) => {
        
        if(callback){
          callback(data)
        }else{
          alert("Compensation selections saved.")
        }
  
      }).catch(()=>{

        alert("There was an error saving the recommendations.  Please check your values and ensure all precentage increases are numeric values before savings.  If you continue to encounter errors, please reach out to \"softwareengineeringsupport@geoengineers.com\"");

      });
  
    }

    return false;
  }
  
  const saveCompensationComment = (adjustment_id, employee_hris_id, comment, callback)=>{
    Utils.postData(
      "/api/compensation/comment", 
      {
        adjustment_id: adjustment_id,
        employee_hris_id: employee_hris_id,
        comment: comment
      },
      instance,
      account
    ).then((data) => {
      
      if(callback){
        callback()
      }else{
        alert("Compensation comment saved.")
      }

    });
  }

  const updateCompensationComment = (comment_id, employee_hris_id, comment, callback)=>{
    Utils.putData(
      "/api/compensation/comment/" + comment_id, 
      {
        comment_id: comment_id,
        employee_hris_id: employee_hris_id,
        comment: comment
      },
      instance,
      account
    ).then((data) => {
      
      if(callback){
        callback()
      }else{
        alert("Compensation comment saved.")
      }

    });
  }



  const saveCompensationHrNote = (adjustment_id, employee_hris_id, note, callback)=>{
    Utils.postData(
      "/api/compensation/note", 
      {
        adjustment_id: adjustment_id,
        employee_hris_id: employee_hris_id,
        note: note
      },
      instance,
      account
    ).then((data) => {
      
      if(callback){
        callback()
      }else{
        alert("Compensation note saved.")
      }

    });
  }


  const editComment = (employeeData, index, adjustmentCommentsJson)=>{
    // console.log("employeeData", employeeData, index, arr);

    openAction3Modal("edit", employeeData, adjustmentCommentsJson, index);

    return false;
  };
  

  const cellStyleHandler = (cell, table) => {
    // console.log("CELL", cell.row.original);
    let rowColor = "#FFF";
    
    if(ineligibleEmployees.find((ineligibleItem)=>{
      return ineligibleItem.employeeHrisId === cell.row.original.employee_hris_id
      && moment(ineligibleItem.effectiveDate).format("YYYY-MM-DD") === moment(cell.row.original.salary_change_effective_date.substring(0,10)).format("YYYY-MM-DD")
    })){
      rowColor = "#DDD"
    }

    if(cell.row.original.status === "Terminated"){
      rowColor = "#F66"
    }

    return ({
      sx: {
        backgroundColor: rowColor
      },
    });
  }

  let adjustmentPromotions = {};
  // console.log("tableData", tableData);
  for(let i=0;i<tableData.length;i++){
    // console.log("promotionAdvancements", promotionAdvancements);
    adjustmentPromotions["adj-"+tableData[i].adjustment_id] = promotionAdvancements.filter((promotionItem)=>{
      return promotionItem.group_id === tableData[i].salary_group_id;
        // && promotionItem.position_title === tableData[i].position_title;
    });
    if(adjustmentPromotions["adj-"+tableData[i].adjustment_id] && adjustmentPromotions["adj-"+tableData[i].adjustment_id].length){
      adjustmentPromotions["adj-"+tableData[i].adjustment_id].unshift({
        promotion_title: "--No Value--"
      });
    }
  }
  // console.log(adjustmentPromotions);

  let columnArray = [
    {
      accessorKey: 'view',
      header: 'View Employee',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      Cell: ({ cell, row }) => {
        return <div><a href={'/employee?id=' + row.original.id}>View</a></div>
      },
      enableEditing: false,
      columnDefType: 'display',
      enablePinning: false      
    },
    {
      accessorKey: 'view_reports',
      header: 'Direct Reports\' Adjustments',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      Cell: ({ cell, row }) => {
        return row.original.total_requests > 0 ? (<div><a href={
          '/compensation?managerHrisId=' + row.original.employee_hris_id
        }>View</a> ({row.original.total_requests})</div>) : "N/A";
      },
      enableEditing: false,
      columnDefType: 'display',
      enablePinning: false
    },
    {
      accessorKey: 'employee_hris_id',
      header: 'Employee Number',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false,
    },
    {
      accessorKey: 'employee_full_name',
      accessorFn: (row) => `${Utils.decodeHTML(row.first_name)} ${Utils.decodeHTML(row.last_name)}`,
      header: 'Employee Name',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false,
    },
    {
      accessorKey: 'position_title',
      header: 'Position',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false
    },
    { 
      accessorKey: 'second_position_title',
      header: 'Second Position',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false
    },    
    {
      accessorKey: 'labor_name',
      header: 'Labor Name',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false
    },
    {
      accessorKey: 'discipline',
      header: 'Discipline',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false
    },    
    {
      accessorFn: (row) => `${Utils.decodeHTML(row.manager_first_name)} ${Utils.decodeHTML(row.manager_last_name)}`,
      header: 'Manager Name',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false
    },
    {
      accessorKey: 'budgeted_office_name',
      header: 'Budgeted Office Name',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false
    },
    {
      accessorKey: 'location',
      header: 'Office Location City',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false
    },
    {
      accessorFn: (row) => `${Utils.maskDateAs("mdy", new Date(row.date_of_hire))}`,
      // accessorKey: 'date_of_hire',
      header: 'Date of Hire',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false
    },
    {
      accessorFn: (row) => `${row.rehire_date ? Utils.maskDateAs("mdy", new Date(row.rehire_date)) : "-"}`,
      // accessorKey: 'rehire_date',
      header: 'Re-hire Date',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false
    },    
    {
      accessorKey: 'status',
      header: 'Status',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false
    },
    {
      accessorKey: 'hours_week',
      header: 'Hours/Week',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false
    },
    {
      accessorFn: (row) => `${Utils.maskDateAs("mdy", new Date(row.position_change_effective_date))}`,
      // accessorKey: 'position_change_effective_date',
      header: 'Current Position Change Effective Date',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false
    },    
    {
      accessorFn: (row) => `${Utils.maskDateAs("mdy", new Date(row.salary_change_effective_date))}`,
      // accessorKey: 'salary_change_effective_date',
      header: 'Current Salary Change Effective Date',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false
    },
    {
      accessorFn: (row) => `${row.hours_week < 40 ? Utils.moneyMask(row.annual_salary) : ""}`,
      // accessorKey: 'current_salary_w_less_than_40',
      header: 'Current Salary (for Employees working less than 40 hours)',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false
    },
    {
      accessorFn: (row) => `${row.manager_premium && row.base_salary && row.base_salary !== "0" ? Utils.moneyMask(row.base_salary) : ""}`,
      // accessorKey: 'current_base_salary',
      header: 'Current Base Salary (For Employees who get Manager Premium)',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false
    },
    {
      accessorFn: (row) => `${Utils.moneyMask(row.current_ft_annualized_salary/(40*52), 0)}`,
      // accessorKey: 'current_hourly_rate',
      header: 'Current Hourly Rate',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false
    },

    {
      header: 'Current Manager Premium',
      accessorFn: (row) => `${(row.manager_premium ? row.manager_premium.toString() : "0")}%`,
      accessorKey: 'manager_premium',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      size: 200,
      enableEditing: false
    },    
    {
      header: 'Current FT Annualized Salary (With Manager Premium)',
      accessorFn: (row) => `${Utils.moneyMask(row.current_ft_annualized_salary, 0)}`,
      accessorKey: 'current_ft_annualized_salary',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      size: 200,
      enableEditing: false
    },
    {
      accessorFn: (row) => `${Math.round(parseFloat(row.current_year_salary_placement_in_range*100))}%`,
      header: 'Current Year Placement in Current Range',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false
    },

    {
      accessorKey: 'action_1_adjustment_or_promotion',
      header: 'Action 1 - Select Adjustment or Promotion from Drop-down List',
      size: 200,
      muiTableHeadCellProps: ({ column }) => ({
        sx: {
          color: 'red',
        },
      }),
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      muiEditTextFieldProps: ({ cell, column, row, table }) => ({
        select: true, //change to select for a dropdown
        children: ["Adjustment", "Promotion"].map((opt) => (
          <MenuItem key={opt} value={opt}>
            {opt}
          </MenuItem>
        )),
        onChange: (event) => {
          // let data = table.getState().tableData; //deprecated
          let data = tableData;
          data[row.index]["action_1_adjustment_or_promotion"] = event.target.value;
          data[row.index] = runSalaryCalculations(data[row.index]);
          setTableData([...data]);
          return false;
        },
      }),
      enableEditing: (row) => {
        // console.log("row.original.deadline_date", row.original.deadline_date);
        
        let deadline = new Date(row.original.deadline_date);
        let isIneligibleEmployee = !!ineligibleEmployees.find((ineligibleItem)=>{
          return ineligibleItem.employeeHrisId === row.original.employee_hris_id
          && moment(ineligibleItem.effectiveDate).format("YYYY-MM-DD") === moment(row.original.salary_change_effective_date.substring(0,10)).format("YYYY-MM-DD")
        });
        return (row.original.employee_adjustment_status === 5 && !isSuperAdmin) || isIneligibleEmployee || (deadline < (new Date()) && !isAdmin) || row.original.employee_adjustment_status === 4 || (row.original.employee_adjustment_status != 1 && !isAdmin) ? false : true
      }
    },
    {
      accessorKey: 'action_2_percent_increase',
      header: 'Action 2 - Enter % Increase ',
      // renderCell: (row) => row.percent_increase ? Math.round(row.percent_increase * 100) / 100 : 0,
      muiTableHeadCellProps: ({ column }) => ({
        sx: {
          color: 'red',
        },
      }),   
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },      
      muiEditTextFieldProps: ({ cell, column, row, table }) => ({
        // inputProps: { 
        //   maxLength: 4 
        // },
        onChange: (event) => {
          // console.log(event.target.value, event.target.value.length)
          let data = tableData;
          data[row.index]["action_2_percent_increase"] = event.target.value;
          data[row.index] = runSalaryCalculations(data[row.index]);          
          const newTableData = [...data];
          setTableData(newTableData);
          handleTotalSpend(newTableData);  
          return false;
        },
      }),
      enableEditing: (row) => {
        let deadline = new Date(row.original.deadline_date);
        let isIneligibleEmployee = !!ineligibleEmployees.find((ineligibleItem)=>{
          return ineligibleItem.employeeHrisId === row.original.employee_hris_id
          && moment(ineligibleItem.effectiveDate).format("YYYY-MM-DD") === moment(row.original.salary_change_effective_date.substring(0,10)).format("YYYY-MM-DD")
        });  
        return (row.original.employee_adjustment_status === 5 && !isSuperAdmin) || 
          isIneligibleEmployee || 
          ((deadline < (new Date()) && !isAdmin) || 
          row.original.employee_adjustment_status === 4 || 
          (row.original.employee_adjustment_status != 1 && !isAdmin)) ? false : true
      }
    },
    {
      accessorKey: 'raw_salary_increase_amount',
      accessorFn: (row) => `${Utils.moneyMask(row.raw_salary_increase_amount)}`,
      header: 'New Salary Increase Amount',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      // muiEditTextFieldProps: ({ cell, column, row, table }) => ({
      //   onChange: (event) => {

      //     const salaryIncreaseAmount = event.target.value;

      //     let data = tableData;
      //     const baseSalary = data[row.index]["base_salary"] ? parseInt(data[row.index]["base_salary"]) : 0;
      //     if(data[row.index]["hours_week"] >= 40 && baseSalary){
      //       data[row.index]["action_2_percent_increase"] = salaryIncreaseAmount / baseSalary;
      //     }else{
      //       if(data[row.index]["annual_salary"]){
      //         data[row.index]["action_2_percent_increase"] = salaryIncreaseAmount / data[row.index]["annual_salary"];            
      //       }else{
      //         data[row.index]["action_2_percent_increase"] = salaryIncreaseAmount / data[row.index]["current_ft_annualized_salary"];            
      //       }
      //     }
          
      //     data[row.index] = runSalaryCalculations(data[row.index]);          

      //     console.log(("data["+row.index+"]"), data[row.index])

      //     const newTableData = [...data];
      //     setTableData(newTableData);
      //     handleTotalSpend(newTableData);  

      //     return false;
      //   },
      // }),
      enableEditing: false,
      // enableEditing: (row) => {
      //   let deadline = new Date(row.original.deadline_date);
      //   let isIneligibleEmployee = !!ineligibleEmployees.find((ineligibleItem)=>{
      //     return ineligibleItem.employeeHrisId === row.original.employee_hris_id
      //     && moment(ineligibleItem.effectiveDate).format("YYYY-MM-DD") === moment(row.original.salary_change_effective_date.substring(0,10)).format("YYYY-MM-DD")
      //   });  
      //   return row.original.employee_adjustment_status === 5 || 
      //     isIneligibleEmployee || 
      //     ((deadline < (new Date()) && !isAdmin) || 
      //     row.original.employee_adjustment_status === 4 || 
      //     (row.original.employee_adjustment_status != 1 && !isAdmin)) ? false : true
      // }      
    },

    {
      accessorKey: 'ft_annualized_salary_recommendation_wo_manager_premium',
      // accessorFn: (row) => `${Utils.moneyMask(Utils.roundTo(row.new_ft_annualized_salary_no_manager_premium_recommendation, 10), 0)}`,
      accessorFn: (row) => `${Utils.moneyMask(row.new_ft_annualized_salary_no_manager_premium_recommendation, 0)}`,
      header: 'New FT Annualized Salary Recommendation (w/o manager premium)',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false,
    },
    {
      accessorKey: 'salary_recommendation_for_employees_lt_40',
      accessorFn: (row) => `${row.new_salary_increase_amount_lt_40 ? Utils.moneyMask(row.new_salary_increase_amount_lt_40) : ""}`,
      header: 'New Salary Recommendation (for employees working less than 40 hours)',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false,
    },

    {
      accessorKey: 'adjustment_premium',
      header: 'Input New Manager Premium% Recommendation',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },      
      muiEditTextFieldProps: ({ cell, column, row, table }) => ({
        onChange: (event) => {
          // let data = table.getState().tableData; //deprecated
          let data = tableData;
          data[row.index]["adjustment_premium"] = event.target.value;
          data[row.index] = runSalaryCalculations(data[row.index]);
          
          const newTableData = [...data];
          setTableData(newTableData);

          handleTotalSpend(newTableData);
          return false;
        },
      }),
      enableEditing: (row) => {
        let deadline = new Date(row.original.deadline_date);
        let isIneligibleEmployee = !!ineligibleEmployees.find((ineligibleItem)=>{
          return ineligibleItem.employeeHrisId === row.original.employee_hris_id
          && moment(ineligibleItem.effectiveDate).format("YYYY-MM-DD") === moment(row.original.salary_change_effective_date.substring(0,10)).format("YYYY-MM-DD")
        });
        return ((row.original.employee_adjustment_status === 5 && !isSuperAdmin) || isIneligibleEmployee || (deadline < (new Date()) && !isAdmin) || row.original.employee_adjustment_status === 4 || (row.original.employee_adjustment_status != 1 && !isAdmin) ? false : true)
      }
    },    
    {
      accessorKey: 'new_ft_annualized_salary_with_manager_premium_recommendation',
      // accessorFn: (row) => `${Utils.moneyMask(Utils.roundTo(parseInt(row.new_ft_annualized_salary_with_manager_premium_recommendation), 10),0)}`,
      accessorFn: (row) => `${Utils.moneyMask(parseInt(row.new_ft_annualized_salary_with_manager_premium_recommendation),0)}`,
      header: 'New FT Annualized Salary (With Manager Premium) Recommendation',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      size: 200,
      enableEditing: false,
    },
    {
      accessorKey: 'current_position_salary_range_minimum',
      accessorFn: (row) => `${Utils.moneyMask(row.current_position_salary_range_minimum, 2)}`,
      header: 'Current Position Salary Range Minimum',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false,
    },
    {
      accessorKey: 'current_position_salary_range_midpoint',
      accessorFn: (row) => `${Utils.moneyMask(row.current_position_salary_range_midpoint, 2)}`,
      header: 'Current Position Salary Range Midpoint',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false
    },
    {
      accessorKey: 'current_position_salary_range_maximum',
      accessorFn: (row) => `${Utils.moneyMask(row.current_position_salary_range_maximum, 2)}`,
      header: 'Current Position Salary Range Maximum',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false,
    },
    // {
    //   accessorKey: 'promotion_title',
    //   accessorFn: (row) => `${row.promotion_title ? row.promotion_title : ""}`,
    //   header: 'Next Level Promotion Position Title',
    //   muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
    //   enableEditing: false
    // },
    {
      accessorKey: 'selected_promotion_title',
      // accessorFn: (row) => `${row.selected_promotion_title || ""}`,
      header: 'Action 3 - Promotion Selection (Optional - Requires "Promotion" in "Action 1")',
      size: 200,
      muiTableHeadCellProps: ({ column }) => ({
        sx: {
          color: 'red',
        },
      }),      
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      muiEditTextFieldProps: ({ cell, column, row, table }) => ({
        select: true, //change to select for a dropdown
        children: adjustmentPromotions && adjustmentPromotions["adj-"+row.original.adjustment_id] ? adjustmentPromotions["adj-"+row.original.adjustment_id].map((opt) => (
          <MenuItem key={opt.promotion_title} value={opt.promotion_title}>
            {opt.promotion_title}
          </MenuItem>
        )) : [],
        onChange: (event) => {
          // console.log("row", row);
          // console.log("adjustmentPromotions", adjustmentPromotions);
          
          let data = tableData;
          data[row.index]["selected_promotion_title"] = event.target.value;
          // console.log(row.original.adjustment_id, row.original)
          const selectedAdjustmentPromotion = adjustmentPromotions && adjustmentPromotions["adj-"+row.adjustment_id] ? adjustmentPromotions["adj-"+row.original.adjustment_id].find((jobTitleItem)=>{
            return jobTitleItem.promotion_title === event.target.value;
          }) : {};
          data[row.index]["promotion_salary_range_minimum"] = selectedAdjustmentPromotion.minimum;
          data[row.index]["promotion_salary_range_midpoint"] = selectedAdjustmentPromotion.midpoint;
          data[row.index]["promotion_salary_range_maximum"] = selectedAdjustmentPromotion.maximum;
          
          // console.log("selectedAdjustmentPromotion", selectedAdjustmentPromotion);
          data[row.index] = runSalaryCalculations(data[row.index]);
          setTableData([...data]);

          return false;
        },
      }),
      enableEditing: (row) => {
        let deadline = new Date(row.original.deadline_date);
        let isIneligibleEmployee = !!ineligibleEmployees.find((ineligibleItem)=>{
          return ineligibleItem.employeeHrisId === row.original.employee_hris_id
          && moment(ineligibleItem.effectiveDate).format("YYYY-MM-DD") === moment(row.original.salary_change_effective_date.substring(0,10)).format("YYYY-MM-DD")
        });
        return ((row.original.employee_adjustment_status === 5 && !isSuperAdmin) || isIneligibleEmployee || (deadline < (new Date()) && !isAdmin) || row.original.employee_adjustment_status === 4 || (row.original.employee_adjustment_status != 1 && !isAdmin) ? false : true)
      }
    },

    {
      accessorKey: 'promotion_salary_range_minimum',
      accessorFn: (row) => {
        const thisPromotion = adjustmentPromotions && adjustmentPromotions["adj-"+row.adjustment_id] ? adjustmentPromotions["adj-"+row.adjustment_id].find((item)=>{
          return item.promotion_title === row.selected_promotion_title
            && item.group_id === row.salary_group_id
        }) : {};
        return thisPromotion && thisPromotion.minimum ? Utils.moneyMask(thisPromotion.minimum, 2) : "N/A";
      },
      header: 'Next Level Promotion Position Salary Range Minimum',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false,
    },
    {
      accessorKey: 'promotion_salary_range_midpoint',
      accessorFn: (row) => {
        const thisPromotion = adjustmentPromotions && adjustmentPromotions["adj-"+row.adjustment_id] ? adjustmentPromotions["adj-"+row.adjustment_id].find((item)=>{
          return item.promotion_title === row.selected_promotion_title
            && item.group_id === row.salary_group_id
        }) : {};
        return thisPromotion && thisPromotion.midpoint ? Utils.moneyMask(thisPromotion.midpoint, 2) : "N/A";
      },
      header: 'Next Level Promotion Position Salary Range Midpoint',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false
    },
    {
      accessorKey: 'promotion_salary_range_maximum',
      accessorFn: (row) => {
        const thisPromotion = adjustmentPromotions && adjustmentPromotions["adj-"+row.adjustment_id] ? adjustmentPromotions["adj-"+row.adjustment_id].find((item)=>{
          return item.promotion_title === row.selected_promotion_title
            && item.group_id === row.salary_group_id
        }) : {};
        return thisPromotion && thisPromotion.maximum ? Utils.moneyMask(thisPromotion.maximum, 2) : "N/A";
      },
      header: 'Next Level Promotion Position Salary Range Maximum',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false,
    },
    {
      accessorFn: (row) => `${Math.round(parseFloat(row.new_current_year_salary_placement_in_range*100))}%`,
      header: 'Final Salary Placement in Range',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false,
    },
    {
      accessorKey: 'edit_action_4',
      header: 'Action 4 - Manager Promotion/Adjustment Rationale',
      size: 500, //large column
      muiTableHeadCellProps: ({ column }) => ({
        sx: {
          color: 'red',
        },
      }),
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },         
      Cell: ({ cell, row }) => {
        
        let deadline = new Date(row.original.deadline_date);

        let isIneligibleEmployee = !!ineligibleEmployees.find((ineligibleItem)=>{
          return ineligibleItem.employeeHrisId === row.original.employee_hris_id
          && moment(ineligibleItem.effectiveDate).format("YYYY-MM-DD") === moment(row.original.salary_change_effective_date.substring(0,10)).format("YYYY-MM-DD")
        });
        
        return <span>
          {
            cell.row.original.comments_array && cell.row.original.comments_array.length ? cell.row.original.comments_array.map((commentItem, index, arr)=>{
              return <div key={Utils.guid()} className='adjustmentComment'>{commentItem}</div>;
            }) : ""
          }          
          {
            (row.original.employee_adjustment_status !== 5 && !isSuperAdmin)
            && !isIneligibleEmployee
            && (deadline > (new Date()) || isAdmin)
            && (
              cell.row.original.employee_adjustment_status === 1  
              || (
                  props 
                  && props.userAccount 
                  && props.userAccount.access_level 
                  && props.userAccount.access_level.indexOf("ADMIN") > -1
                )
            )
            && cell.row.original.employee_adjustment_status !== 4 
            ? (
              <span>
                <a href="#" 
                  onClick={(event)=>{
                    event.preventDefault();

                    const logDataJson = Utils.getData(
                      (
                        '/api/compensation/comments?adjustment_id=' + cell.row.original.adjustment_id
                      ), 
                      instance, 
                      account
                    ).then((adjustmentCommentsJson)=>{

                      openAction3Modal("add", cell.row.original, adjustmentCommentsJson, -1);
                      
                    });
                    
                    return false;
                  }}>
                    View/Comment
                </a>
              </span>
            ) : ""
          }
        </span>;

      },
      enableEditing: false,
      // columnDefType: 'display',
      enablePinning: true
    },
    {
      accessorKey: 'hr_notes',
      header: 'HR Notes',
      size: 500, 
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },         
      Cell: ({ cell, row }) => {
        let deadline = new Date(row.original.deadline_date);
        return <span>
          {
            cell.row.original.notes_array && cell.row.original.notes_array.length ? cell.row.original.notes_array.map((noteItem)=>{
              return <div key={Utils.guid()} className='adjustmentNote'>{noteItem}</div>;
            }) : ""
          }          
          {
            (deadline < (new Date()) || isAdmin)
            && (
              props 
              && props.userAccount 
              && props.userAccount.access_level 
              && props.userAccount.access_level.indexOf("ADMIN") > -1
            )
            && cell.row.original.employee_adjustment_status !== 4 
            // && ineligibleEmployees.indexOf(cell.row.original.employee_hris_id) === -1 
            ? (
              <span>
                <a href="#" 
                  onClick={(event)=>{
                    event.preventDefault();

                    const logDataJson = Utils.getData(
                      (
                        '/api/compensation/notes?adjustment_id=' + cell.row.original.adjustment_id
                      ), 
                      instance, 
                      account
                    ).then((adjustmentNotesJson)=>{

                      openHrNotesModal(cell.row.original, adjustmentNotesJson);
                      
                    });  
                    
                    return false;
                  }}>
                    View HR Notes
                </a>
              </span>
            ) : ""
          }
        </span>;
      },
      enableEditing: false,
      columnDefType: 'display',
      enablePinning: false
    },
    {
      accessorKey: 'adjustment_status',
      header: 'Status',
      Cell: ({ cell, row }) => {
        let adjustmentStatusText = "";
        if(row.original.employee_adjustment_status === 1){
          adjustmentStatusText = "Not submitted"
        }
        if(row.original.employee_adjustment_status === 2){
          adjustmentStatusText = "Submitted"
        }
        if(row.original.employee_adjustment_status === 4){
          adjustmentStatusText = "Finalized"          
        }
        if(row.original.employee_adjustment_status === 5){
          adjustmentStatusText = "Closed"          
        }
        return <div>{adjustmentStatusText}</div>;
      },      
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false,
    },    
    {
      accessorKey: 'status_actions',
      header: 'Submit',
      Cell: ({ cell, row }) => {        
        let statusActionJsx;
        let deadline = new Date(row.original.deadline_date);
        // console.log("deadline", deadline);
        // console.log("isAdmin", isAdmin)

        let isIneligibleEmployee = !!ineligibleEmployees.find((ineligibleItem)=>{
          return ineligibleItem.employeeHrisId === row.original.employee_hris_id
          && moment(ineligibleItem.effectiveDate).format("YYYY-MM-DD") === moment(row.original.salary_change_effective_date.substring(0,10)).format("YYYY-MM-DD")
        });
        // console.log(isIneligibleEmployee, deadline < (new Date()), isAdmin);
        // console.log(!isIneligibleEmployee && (deadline < (new Date()) || isAdmin));

        if(!row.original.is_approved && !row.original.no_approval_needed){
          statusActionJsx = "Not Approved"
        }else if(!isIneligibleEmployee && (deadline > (new Date()) || isAdmin)){
          if(row.original.employee_adjustment_status === 1){

            statusActionJsx = <div>
              <a href="#" onClick={(event)=>{
                event.preventDefault();

                let adjustments = [];
                let submittedUsers = [];
                let confirmationUsers = [];
                for(var i=0;i<tableData.length;i++){
                  if(tableData[i].adjustment_id === cell.row.original.adjustment_id){
                    adjustments.push(tableData[i].adjustment_id);
                    submittedUsers.push(tableData[i].last_name + ', ' +tableData[i].first_name);
                    confirmationUsers.push({
                      adjustmentId : tableData[i].adjustment_id,
                      fullName : (tableData[i].last_name + ', ' +tableData[i].first_name)
                    });
                  }
                }

                // console.log("confirmationUsers", confirmationUsers);
                setAffirmUsersArray(confirmationUsers);
                setLockAdjustmentsData({
                  adjustment_id_list: adjustments,
                  user_list: submittedUsers,
                  contact_admins : true,
                  manager_name : account.username,
                  adjustment_uuid : cell.row.original.adjustment_uuid,
                  manager_hris_id : managerHrisId
                });

                openAffirmModal();

                return false;
              }}>Submit Recommendation</a>
            </div>;
          
          }else{
            if(
              props 
              && props.userAccount 
              && props.userAccount.access_level 
              && props.userAccount.access_level.indexOf("ADMIN") > -1
              && row.original.employee_adjustment_status === 2
            ){
              
              statusActionJsx = <div>
                <a href="#" onClick={(event)=>{
                  event.preventDefault();

                  if(window.confirm("This will REOPEN the selected salary recommendations AND send them an email informing them their adjustments have been re-opened, are you sure you would like to submit this request?")){
                    saveRecommendations(()=>{
                      let adjustments = [];
                      let submittedUsers = [];
                      for(var i=0;i<tableData.length;i++){
                        if(tableData[i].adjustment_id === cell.row.original.adjustment_id){
                          adjustments.push(tableData[i].adjustment_id);
                          submittedUsers.push((tableData[i].last_name + ', ' +tableData[i].first_name));
                        }
                      }

                      Utils.postData(
                        "/api/compensation/unlock_adjustments", 
                        {
                          adjustment_uuid : cell.row.original.adjustment_id,
                          adjustment_id_list: adjustments,
                          user_list: submittedUsers,                      
                        },
                        instance,
                        account
                      ).then((data) => {

                        fetchData();
                        alert("Compensation recommendation re-opened.  The manager has been sent an email asking them to review their compensation requests.")
                  
                      });
                    });
                  }

                  return false;
                }}>Unlock</a>

                &nbsp;-&nbsp;
                
                <a href="#" onClick={(event)=>{
                  event.preventDefault();

                  if(window.confirm("This will FINALIZE the selected salary recommendation, are you sure you would like to submit this request?")){
                    saveRecommendations(()=>{
                      let adjustments = [];
                      let submittedUsers = [];
                      for(var i=0;i<tableData.length;i++){
                        if(tableData[i].adjustment_id === cell.row.original.adjustment_id){
                          adjustments.push(tableData[i].adjustment_id);
                          submittedUsers.push((tableData[i].last_name + ', ' +tableData[i].first_name));
                        }
                      }

                      Utils.postData(
                        "/api/compensation/finalize_adjustments", 
                        {
                          adjustment_uuid : cell.row.original.adjustment_id,
                          adjustment_id_list: adjustments,
                          user_list: submittedUsers,                        
                        },
                        instance,
                        account
                      ).then((data) => {

                        fetchData();
                        alert("Compensation recommendation finalized.  The manager has been sent an email thanking them and instructing them where they may view their finalized recommendations.")
                      });                      
                    });
                  }
                  
                  return false;
                }}>Finalize</a>
              </div>;

            }else{

              statusActionJsx = <div>No Action Needed</div>;

            }
          }
        }else{
          let statusText = "N/A";
          // console.log("isIneligibleEmployee", isIneligibleEmployee);
          if(isIneligibleEmployee){
            statusText = "Not eligible."
          }else if(deadline < (new Date())){
            statusText = "Deadline Passed."
          }
          statusActionJsx = <div>{statusText}</div>;
        }

        return statusActionJsx;
      },      
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      enableEditing: false,
    },
  ];
  
  if(props && props.userAccount && props.userAccount.access_level ? props.userAccount.access_level.indexOf("ADMIN") > -1 : false){
    columnArray.push({
      accessorKey: 'view_log',
      header: 'View Log',
      muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
      Cell: ({ cell, row }) => {
        return <span>
          <a href="#" 
            onClick={(event)=>{
              event.preventDefault();

              const logDataJson = Utils.getData(
                (
                  '/api/log?adjustment_id=' + 
                    cell.row.original.adjustment_id + 
                    '&adjustment_uuid=' + 
                    cell.row.original.adjustment_uuid
                ), 
                instance, 
                account
              ).then((logDataResponse)=>{
                
                // const logData = [];
                openLogModal(cell.row.original, logDataResponse);
                
              });  

              return false;
            }}>
              View Log
          </a>
        </span>;
      },      
      enableEditing: false,
    })    
  }

  for(var i=0;i<columnArray.length;i++){
    columnArray[i].header = "("+(i+1)+") "+columnArray[i].header;
  }

  //should be memoized or stable
  const columns = useMemo(
    () => columnArray,
    [props, ineligibleEmployees],
  );
  
  const tableHeightValue =  window.innerHeight > 220 ? window.innerHeight - 220 : 300;
  const tableHeightCSS = tableHeightValue + "px";

  const devAccount = "jstopchick@geoengineers.com";
  const isSuperAdmin = props && props.userAccount && props.userAccount.access_level && props.userAccount.access_level.indexOf("SUPERADMIN") > -1;
  const isAdmin = props && props.userAccount && props.userAccount.access_level && props.userAccount.access_level.indexOf("ADMIN") > -1;
  const isDevAccount = props && props.userAccount && props.userAccount.email && props.userAccount.email === devAccount;
  const isReviewer = props && props.userAccount && (
    props.userAccount.is_group_reviewer || 
    props.userAccount.is_individual_reviewer || 
    props.userAccount.has_manager_reports
  );

  
  const triggerLink = (linkId)=>{
    instance.acquireTokenSilent({
      scopes: protectedResources["apiCompensation"].scopes,
      account: account
    }).then((msalAuthorization)=>{
      //add the token
      const selectedLink = document.getElementById(linkId).href + 
        "&token=" + (msalAuthorization ? msalAuthorization.accessToken : "")
      
      //set the link handler
      document.getElementById("linkHandler").href = selectedLink;
      //click the link
      document.getElementById("linkHandler").click()
    });
  }


  const saveLayoutSettings = (layoutObject, callback)=>{
    Utils.putData(
      ("/api/manager/"+managerHrisId+"/settings"), 
      layoutObject,
      instance,
      account
    ).then((data) => {
      if(callback){
        callback(data);
      }else{
        alert("Layout settings saved.")
      }
    }).catch(()=>{
      alert("There was an error saving the layout.  If you continue to encounter errors, please reach out to \"softwareengineeringsupport@geoengineers.com\"");
    });
    return false;
  }

  let selectedManagerHrisId;
  if(managerHrisId){
    selectedManagerHrisId = managerHrisId;
  }

  const intialState = { columnPinning : { left: ['employee_full_name'] } }

  // columnPinning: { left: ['email']

  const materialReactTableObject = useMaterialReactTable({
    columns,
    data: tableData,
    initialState: intialState, 
    globalFilterFn: "contains",
    enableStickyHeader: true,
    // enableSorting: true,
    renderTopToolbarCustomActions:({ table }) => {
      const handleShowAllReports = (event) => {            
        setShowAllReports(event.target.checked);
      };
      
      const handleShowOnlyReviews = (event) => {            
        setShowOnlyReviews(event.target.checked);
      };
      
      const handleHideReviewees = (event) => {            
        setHideReviewees(event.target.checked);
      };

      const handleShowOnlyReports = (event) => {            
        setShowOnlyReports(event.target.checked);
      };

      const handleShowMissingRangeReports = (event) => {            
        setShowMissingRangeReports(event.target.checked);
      };

      return <div className="compensationHeader">
          <span className="sectionTableHeader">Employee Salary and Promotion |</span>

          {
            // X / Y --  X / {Utils.moneyMask(budgetObject.budget ? budgetObject.budget : 0)}
            managerHrisId ? 
            <span>
              <span style={{"display":"inline-block", marginLeft:"10px" }}>
                <span>Viewing as: {viewingAs.first_name} {viewingAs.last_name}</span><br />
                <span style={{ border: "1px solid #DDD"}}>
                  Budget [ <span className={budgetObject && totalSpend > budgetObject.budget ? "budgetExceeded" : "budgetNote"}>
                    {Utils.moneyMask(totalSpend ? totalSpend : 0)} / 
                    {Utils.moneyMask(showAllReports ? teamBudget : (budgetObject && budgetObject.budget ? budgetObject.budget : 0))}
                  </span> ]
                </span>
              </span>
              &nbsp;&nbsp;&nbsp;
            </span> : null
          }

          <span style={{ position:"absolute", right:"350px" }}>
            {
              // !isLocked ? (
                <span><button href="#" onClick={(event)=>{
                  event.preventDefault();
                  
                  openInstructionsModal();

                  return false;
                }}>(Help)</button></span>
              // ) : null
            }
            &nbsp;&nbsp;&nbsp;

            {
              !isClosed || isSuperAdmin ? 
              <span>
                <span><button href="#" onClick={(event)=>{
                  event.preventDefault();
                  
                  saveRecommendations();

                  return false;
                }}>Save (without submitting)</button></span>
                &nbsp;&nbsp;&nbsp;
              </span> 
              : null
            }

            {
              (viewingAs.is_approved || viewingAs.no_approval_needed) && !isLocked && !isFinalized && !isClosed && !showAllReports ? 
              <span>
                <span><button onClick={(event)=>{
                  event.preventDefault();
                  
                  let adjustments = [];
                  let submittedUsers = [];
                  let confirmationUsers = [];
                  for(var i=0;i<tableData.length;i++){
                    if((tableData[i].deadline_date && new Date(tableData[i].deadline_date) < (new Date())) || isAdmin){
                      adjustments.push(tableData[i].adjustment_id);
                      submittedUsers.push((tableData[i].last_name + ', ' +tableData[i].first_name));  
                      confirmationUsers.push({
                        adjustmentId : tableData[i].adjustment_id,
                        fullName : (tableData[i].last_name + ', ' +tableData[i].first_name)
                      });
                    }
                  }

                  setAffirmUsersArray(confirmationUsers);
                  setLockAdjustmentsData({
                    adjustment_id_list: adjustments,
                    user_list: submittedUsers,
                    contact_admins : true,
                    manager_name : account.username,
                    adjustment_uuid : adjustmentUuid,
                    manager_hris_id : managerHrisId
                  });

                  openAffirmModal();

                  return false;
                }}>Save & Submit All</button></span>
                &nbsp;&nbsp;&nbsp;
              </span> : null
            }   

            <span>
              <a 
                id="pendingCompensations"
                href={
                  CONFIG.API_HOST + 
                  "/api/compensation/employees?"+
                  '?viewer_email=' +  accounts[0].username + 
                  "&status=" + adjustmentStatus + 
                  "&show_finalized=true" + 
                  "&effective_date=" + effectiveDate + 
                  "&adjustment_id=" + adjustmentId + 
                  "&manager_hris_id=" + (!showOnlyReports && selectedManagerHrisId ? selectedManagerHrisId : "") + 
                  "&only_direct_reports=" + showOnlyReports + 
                  "&show_all_reports=" + (showOnlyReviews ? true : showAllReports) + //show everyone if show only reviews is checked, to get all reviews as well
                  "&recursive_reports=" + (showOnlyReviews ? true : showAllReports) + //show all if show all reports or reviews is clicked
                  "&show_only_reviews=" + showOnlyReviews + 
                  "&missing_ranges=" + showMissingRangeReports +
                  "&hide_reviewees=" + hideReviewees + 
                  "&f=xl" + 
                  "&d=true"
                }
                onClick={(event)=>{
                  event.preventDefault()
                  triggerLink("pendingCompensations");
                  return false;
                }}
              >
                Download Pay Data
              </a>  
            </span>
          </span>

          &nbsp;&nbsp;&nbsp;

          <span className="resetLayoutContainer">
            <Tooltip title="Reset compensation table layout">
              <IconButton
                onClick={(event) => {
                  if(window.confirm("This will reset your compensation table layout to the default.")){
                    let layoutState = {
                      'column_pinning':{
                        'left':['employee_full_name']
                      },
                      'column_visibility':[],
                      'density':'comfortable',
                      'sorting':[],
                      'page_size':10
                    }

                    setPagination({
                      pageIndex: 0,
                      pageSize: layoutState.page_size
                    });
                    setSorting(layoutState.sorting);
                    setColumnPinning(layoutState.column_pinning);
                    setColumnVisibility(layoutState.column_visibility);
                    setDensity(layoutState.density);

                    // setLayoutSettings()
                    saveLayoutSettings(layoutState, ()=>{
                      // window.alert("Layout reset.");
                    })
                  }
                  return false;
                }}
              >
                <GridOffIcon className="resetLayoutButton" sx={{ color: "#666", cursor:"pointer" }} />
              </IconButton>
            </Tooltip>
          </span>

          <span className="saveLayoutContainer">
            <Tooltip title="Save compensation table layout">
              <IconButton
                onClick={(event) => {
                  const tableState = materialReactTableObject.getState();
                  // console.log("TABLE STATE", tableState);
                  let layoutState = {
                    columnPinning : tableState.columnPinning,
                    columnVisibility : tableState.columnVisibility,
                    density : tableState.density,
                    sorting : tableState.sorting,
                    pageSize : (tableState.pagination && tableState.pagination.pageSize ? tableState.pagination.pageSize : 10)
                  };
                  // console.log("layoutState", layoutState);
                  saveLayoutSettings(layoutState, ()=>{
                    window.alert('Layout Saved');
                  })
                  return false;
                }}
              >
                <GridOnIcon className="saveLayoutButton" sx={{ color: "#666", cursor:"pointer" }} />
              </IconButton>
            </Tooltip>
          </span>

          &nbsp;&nbsp;&nbsp;
          
          <span className="quickLayoutContainer">
            <Tooltip title="Set the layout for quick entry">
              <IconButton
                onClick={(event) => {
                  let layoutState = {
                    'column_pinning':{
                      'left':[
                        'employee_full_name', 
                        'action_1_adjustment_or_promotion', 
                        'action_2_percent_increase', 
                        'raw_salary_increase_amount', 
                        'adjustment_premium', 
                        'new_ft_annualized_salary_with_manager_premium_recommendation'
                      ]
                    },
                    'column_visibility':[],
                    'density':'comfortable',
                    'sorting':[],
                    'page_size':10
                  }

                  setPagination({
                    pageIndex: 0,
                    pageSize: layoutState.page_size
                  });
                  setSorting(layoutState.sorting);
                  setColumnPinning(layoutState.column_pinning);
                  setColumnVisibility(layoutState.column_visibility);
                  setDensity(layoutState.density);

                  // setLayoutSettings()
                  saveLayoutSettings(layoutState, ()=>{
                    // window.alert("Layout reset.");
                  });
                  return false;
                }}
              >
                <SpaceDashboardOutlinedIcon className="resetLayoutButton" sx={{ color: "#666", cursor:"pointer" }} />
              </IconButton>
            </Tooltip>
          </span>

          <br />

          <span style={{ marginLeft:"388px" }}>
            {managerHrisId ? 
              <span>
                <input 
                  type="checkbox" 
                  name="showAllReports" 
                  checked={showAllReports} 
                  onChange={(event)=>{
                    handleShowAllReports(event);
                  }} 
                />
                &nbsp;<span>Show All Employee Levels </span>&nbsp;&nbsp;
              </span> : ""
            }

            {
              !showAllReports && !showOnlyReviews && (isSuperAdmin || isDevAccount) ? 
              <span>
                <input 
                  type="checkbox" 
                  name="showMissingRangeReports" 
                  checked={showMissingRangeReports} 
                  onChange={(event)=>{
                    handleShowMissingRangeReports(event);
                  }} 
                />
                &nbsp;<span>Missing ranges? </span>&nbsp;&nbsp;
              </span> : 
              null   
            }

            {
              (isSuperAdmin || isDevAccount) ? 
              <span>
                <input 
                  type="checkbox" 
                  name="hideReviewees" 
                  checked={hideReviewees} 
                  onChange={(event)=>{
                    handleHideReviewees(event);
                  }} 
                />
                &nbsp;<span>Hide Reviewees </span>
              </span> : 
              null   
            }
          </span>          
        </div>;
        
    },
    renderBottomToolbarCustomActions:({ table }) => {
      return <div style={{"marginTop":"-15px", "fontSize":"10pt", "fontWeight":"bold"}}>
        <div style={{ backgroundColor:"#F66" }}>Red employees have left the company, however an adjustment was created for them prior to leaving.</div>
        <div style={{ backgroundColor:"#DDD" }}>Grayed out employees are not eligible for this round of salary adjustments (e.g. employees hired less than 6 months ago).</div>
        <div>Scroll towards the right to complete the Action columns and Manager premium if applicable.</div>
      </div>;
    },
    muiToolbarAlertBannerProps:(
      isError
        ? {
            color: 'error',
            children: 'Error loading data',
          }
        : undefined
    ),
    enableEditing:isEnableEditingOn,
    enableColumnPinning:true,
    editDisplayMode:"table",
    muiTableContainerProps:({ 
      sx: { 
        height: tableHeightCSS,
        visibility: modalInstructionsIsOpen || 
          modalAction3IsOpen || 
          modalHrNotesIsOpen || 
          modalLogIsOpen || 
          modalReviewersIsOpen || 
          modalAffirmIsOpen ? "hidden" : "visible"
      } 
    }),
    rowCount:rowCount,
      
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnPinningChange: setColumnPinning,
    onColumnVisibilityChange: setColumnVisibility,
    onDensityChange: setDensity,

    state: {
      isLoading: isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      pagination:pagination,
      sorting:sorting,
      columnPinning:columnPinning,
      columnVisibility:columnVisibility,
      density:density
    }
  });

  // console.log("props.userAccount", props.userAccount);

  //DO NOT EVER USE USER POSTED OR THIRD PARTY CODE IN THIS, THIS IS RENDERED DYNAMICALLY 
  //AND IS THEREFORE VULNERABLE TO XSS ATTACKS IF ALLOWING THIRD PARTY TEXT
  let noActiveCompensationsText = "";
  if(dataIsFetched && tableData.length === 0){
    noActiveCompensationsText = ("No "+ (adjustmentStatus === "finalized" ? "finalized" : "pending") +" requests found for this manager. ")
    //APPEND CLOSED COMPENSATIONS
    if(adjustmentStatus === "finalized"){
      noActiveCompensationsText = noActiveCompensationsText + "<br /><br/><strong>Past compensation cycles</strong><br /><br />";
      for(let i=0;i<closedAdjustmentsData.length;i++){
        // console.log("closedAdjustmentsData[i]", closedAdjustmentsData[i]);
        noActiveCompensationsText = noActiveCompensationsText + "Effective Date: <a href='/compensation?status=closed&managerHrisId=" + managerHrisId + "&effectiveDate="+closedAdjustmentsData[i].effective_date+"'>"+closedAdjustmentsData[i].effective_date+"</a><br />"
      }
    }
  }

  return dataIsFetched && tableData.length === 0 ? <div dangerouslySetInnerHTML={{ __html : noActiveCompensationsText }} /> : 
    (<div>
      { 
        userHasAccess ?
        <MaterialReactTable 
          table={materialReactTableObject} 
        /> : accessMessage
      }

      <Modal
        isOpen={modalDisclaimerIsOpen}
        onAfterOpen={afterOpenDisclaimerModal}
        onRequestClose={closeDisclaimerModal}
        style={modalStyles}
        contentLabel="Add Job Advacement"
        ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
      >
        <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
          Disclaimer
        </h2>
        <div className="modalBody">
          All information contained within the GeoEngineers Employee Compensation System is confidential. 
          This information must only be shared with managers, specific to their own direct reports.  
        </div>
        <div className="modalFooter">
          <button onClick={closeDisclaimerModal}>Close</button>
        </div>
      </Modal>


      <Modal
        isOpen={modalAffirmIsOpen}
        onAfterOpen={afterOpenAffirmModal}
        onRequestClose={closeAffirmModal}
        style={modalInstructionStyles}
        contentLabel="Affirm submission choices"
        ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
      >
        <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
          Confirm employees ready for submission
        </h2>
        <div className="modalBody">
          By checking these selected users, you acknowledge you have reviewed columns "Action 1",<br /> "Action 2", "Action 3", and "Action 4" and that you have reviewed your changes with your manager before submitting.<br /><br />
          {affirmUsersArray.length ? affirmUsersArray.map((userItem)=>{
            return <span key={Utils.guid()}>
              <input type="checkbox" name={"affirm-"+userItem.adjustmentId} id={"affirm-"+userItem.adjustmentId} />&nbsp;
              {userItem.fullName}
              <br />
            </span>;
          }) : null}
          <br /><br />
        </div>
        <div className="modalFooter">
          <button onClick={closeAffirmModal}>Close</button>
          <button onClick={()=>{
            let userHasConfirmed = true;
            if(!affirmUsersArray.length){
              userHasConfirmed=false;
            }
            for(let i=0;i<affirmUsersArray.length;i++){
              if(!jQuery("#affirm-"+affirmUsersArray[i].adjustmentId).is(':checked')){
                userHasConfirmed=false;
              }
            }

            if(userHasConfirmed){

              //save the recommendations
              saveRecommendations(()=>{
                //lock the recommendations
                if(lockAdjustmentsData.adjustment_id_list.length){
                  Utils.postData(
                    "/api/compensation/lock_adjustments", 
                    lockAdjustmentsData,
                    instance,
                    account
                  ).then((data) => {

                    closeAffirmModal();
                    fetchData();
                    alert("Compensation recommendations saved, locked, and sent to HR.  You may close this page.")

                  });
                }else{
                  alert("No pending adjustments to submit.");
                }
              })

            }else{
              window.alert("You must select all users to submit your recommendations.");
            }

          }}>Submit</button>
        </div>
      </Modal>
      

      <Modal
        isOpen={modalInstructionsIsOpen}
        onAfterOpen={afterOpenInstructionsModal}
        onRequestClose={closeInstructionsModal}
        style={modalInstructionStyles}
        contentLabel="Add Job Advacement"
        ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
      >
        <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
          Instructions
        </h2>
        <div className="modalBody">
          This page allows you as a manager to enter salary recommendations for your team.  
          <br /><br />
          To view an individual employee, click the "View" button on the "View Employee" column.
          <br /><br />
          If your team has any managers, you may view their direct reports as well by clicking 
          "View" on the "Direct Reports' Adjustments" menu.
          <br /><br />
          To set your salary recommendations, enter their adjustment/promotion type in the <b>"Action 1"</b> column.  
          Then enter the <b>increase percent</b> in the <b>"Action 2"</b> column.  If you are promoting a team member, 
          select their promotion title under the <b>"Action 3"</b> column.  If you exceed your budget 
          for this recommendation cycle, enter your rationale in the <b>"Action 4"</b> column.  
          Press <b>"Submit Recommendations"</b> to finalize and send your recommendations to HR.  
          Once submitted, you will not be able to edit your recommendations afterwards. <br />
        </div>
        <div className="modalFooter">
          <button onClick={closeInstructionsModal}>Close</button>
        </div>
      </Modal>

      <Modal
        isOpen={modalAction3IsOpen}
        onAfterOpen={afterOpenAction3Modal}
        onRequestClose={closeAction3Modal}
        style={modalStyles}
        contentLabel="Manager Promotion/Adjustment Rationale"
        ariaHideApp={false}
      >
        <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
          Manager Promotion/Adjustment Rationale
        </h2>
        <div className="modalBody">
          <div className="compensationCommentLog">
            {activeComments && activeComments.length ? activeComments.map((activeCommentItem, index)=>{
              return <div key={Utils.guid()}>
                <span className={"adjustmentCommentItem " + (index === commentsIndex ? "editing" : "")}>{activeCommentItem.first_name} {activeCommentItem.last_name}: </span>
                <span className={"adjustmentCommentItem " + (index === commentsIndex ? "editing" : "")}>{Utils.maskDateAs("mdy", new Date(activeCommentItem.comment_datetime))}</span>
                <span className={"adjustmentCommentItem wide " + (index === commentsIndex ? "editing" : "")}>{(index === commentsIndex ? "(EDITING) " : "")}{activeCommentItem.comment}</span>
                <span className={"adjustmentCommentItem edit " + (index === commentsIndex ? "editing" : "")}>
                  <span className="editCommentLink" onClick={(event)=>{ 
                      // console.log("activeCommentItem", activeCommentItem, index);

                      editComment(modalAction3Item, index, activeComments); 
                  
                      return false; 
                    }}>Edit</span>
                </span>
              </div>;
            }) : ""}
          </div>
          <textarea 
            id='modalAction3Value' 
            className="compensationCommentEntry"
            value={modalAction3Value}
            onChange={(event)=>{
              event.preventDefault();
              setModalAction3Value(event.target.value);
              return false;
            }} 
            style={{
              width: "680px",
              height: "200px"
            }}
          />
        </div>
        <div className="modalFooter">
          <button onClick={(event)=>{
            event.preventDefault();

            if(commentsMode === "edit"){
              
              updateCompensationComment(activeComments[commentsIndex].id, props.userAccount.employee_hris_id, modalAction3Value, ()=>{
                fetchData();              
                closeAction3Modal();
              });

            }else{

              saveCompensationComment(modalAction3Item.adjustment_id, props.userAccount.employee_hris_id, modalAction3Value, ()=>{
                fetchData();              
                closeAction3Modal();
              });

            }
            return false;
          }}>Save</button>
          <button onClick={closeAction3Modal}>Cancel</button>
        </div>
      </Modal>

      <Modal
        isOpen={modalHrNotesIsOpen}
        onAfterOpen={afterOpenHrNotesModal}
        onRequestClose={closeHrNotesModal}
        style={modalStyles}
        contentLabel="HR Notes"
        ariaHideApp={false}
      >
        <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
          HR Notes
        </h2>
        <div className="modalBody">
          <div className="compensationHrNoteLog">
            {activeHrNotes && activeHrNotes.length ? activeHrNotes.map((activeHrNoteItem)=>{
              return <div key={Utils.guid()}>
                <span className="adjustmentNoteItem">{activeHrNoteItem.first_name} {activeHrNoteItem.last_name}: </span>
                <span className="adjustmentNoteItem">{Utils.maskDateAs("mdy", new Date(activeHrNoteItem.note_datetime))}</span>
                <span className="adjustmentNoteItem wide">{activeHrNoteItem.note}</span>
              </div>;
            }) : ""}
          </div>
          <textarea 
            id='modalHrNotesValue' 
            className="compensationHrNoteEntry"
            value={modalHrNotesValue}
            onChange={(event)=>{
              event.preventDefault();
              setModalHrNotesValue(event.target.value);
              return false;
            }} 
            style={{
              width: "600px",
              height: "200px"
            }}
          />
        </div>
        <div className="modalFooter">
          <button onClick={(event)=>{
            event.preventDefault();
            const newTableData = tableData;
            for(var i=0;i<newTableData.length;i++){
              if(newTableData[i].adjustment_id === modalHrNotesItem.adjustment_id){
                newTableData[i].administrator_notes = modalHrNotesValue;
              }
            }
            setTableData(newTableData);

            saveCompensationHrNote(modalHrNotesItem.adjustment_id, props.userAccount.employee_hris_id, modalHrNotesValue, ()=>{
              fetchData();              
              closeHrNotesModal();
            });
            return false;
          }}>Save</button>
          <button onClick={closeHrNotesModal}>Cancel</button>
        </div>
      </Modal>

      
      <Modal
        isOpen={modalLogIsOpen}
        onAfterOpen={afterOpenLogModal}
        onRequestClose={closeLogModal}
        style={modalStyles}
        contentLabel="Adjustment request log"
        ariaHideApp={false}
      >
        <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
          Adjustment Log for {selectedAdjustment.first_name} {selectedAdjustment.last_name}
        </h2>
        <div className="modalBody adjustmentModal">
          <div>
            <span className='logUser'>Editor</span>
            <span className='logDate'>Date</span>
            <span className='logAction'>Action</span>
            <span className='logDetails'>Details</span>
          </div>
          {adjustmentLogs.map((adjustmentLogItem)=>{
            return <div>
              <span className='logUser'>{adjustmentLogItem.packet.logged_in_user}</span>
              <span className='logDate'>{Utils.maskDateAs("mdy", new Date(adjustmentLogItem.event_datetime))}</span>
              <span className='logAction'>{adjustmentLogItem.packet.event}</span>
              <span className='logDetails'><a href="#" onClick={(event)=>{
                event.preventDefault();

                let displayText = "";
                for(var key in adjustmentLogItem.packet){
                  let valueText = "";
                  if(typeof adjustmentLogItem.packet[key] === "object"){
                    for(var subkey in adjustmentLogItem.packet[key]){
                      valueText = valueText + subkey + ": " + adjustmentLogItem.packet[key][subkey] + "\n"
                    }
                  }else{
                    valueText = adjustmentLogItem.packet[key];
                  }
                  displayText = displayText + key + ": " + valueText + "\n"
                }

                alert(displayText);
                
                return false;
              }}>View</a></span>              
            </div>;
          })}
        </div>
        <div className="modalFooter">
          <button onClick={closeLogModal}>Close</button>
        </div>
      </Modal>

    
      <Modal
        isOpen={modalReviewersIsOpen}
        onAfterOpen={afterOpenReviewersModal}
        onRequestClose={closeReviewersModal}
        style={modalStyles}
        contentLabel="Manage Reviewees"
        ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
      >
        <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
          Viewing Reviewers
        </h2>
        <div className="modalBody employeeListReviewersModalBody">
          {selectedReviewers ? selectedReviewers.map((reviewerItem)=>{
            return <div key={Utils.guid()}>{reviewerItem.reviewer_last_name}, {reviewerItem.reviewer_first_name}</div>
          }) : null}
        </div>
        <div className="modalFooter">
          <button onClick={closeReviewersModal}>Close</button>
        </div>
      </Modal>   

      <a href="#" id="linkHandler" style={{ visibility:"hidden" }}></a>
    </div>);
};

export default Compensation;