import React, { useState, useMemo, useEffect }  from 'react';
import Modal from 'react-modal';
import { useMsal, useAccount } from "@azure/msal-react";
import { MaterialReactTable } from 'material-react-table';
import jQuery from 'jquery';
import * as Utils from '../lib/utils';

window.jQuery = jQuery;
require("jsgrid");

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const debounce = function(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}


const Adjustments = () => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [dimensions, setDimensions] = useState({ 
      height: window.innerHeight,
      width: window.innerWidth
    });
    
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);    
    const [rowCount, setRowCount] = useState(0);

    const [modalEmployeeListIsOpen, setEmployeeListIsOpen] = useState(false);
    const [adjustmentEmployeeList, setAdjustmentEmployeeList] = useState([]);

    const [adjustmentsData, setAdjustmentsData] = useState({});

    const urlParams = Utils.getParams();
    const effectiveDate = urlParams.effective_date;
    const adjustmentStatus = urlParams.status;

    let subtitle;


    function openEmployeeListModal() {
      setEmployeeListIsOpen(true);
    }
    function afterOpenEmployeeListModal() {
      // references are now sync'd and can be accessed.
      // subtitle.style.color = '#f00';
    }
    function closeEmployeeListModal() {
      setEmployeeListIsOpen(false);
    }
  
  
    useEffect(() => {
      const debouncedHandleResize = debounce(function handleResize() {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth
        })
      }, 500)

      //binds a resize listener for a debounced (less frequent) resize effect
      window.addEventListener('resize', debouncedHandleResize);
      
      //unbinds listener to prevent memory leaks
      return _ => {
        window.removeEventListener('resize', debouncedHandleResize)   
      };
    });


    const fetchData = async () => {
        if (!adjustmentsData.length) {
          setIsLoading(true);
        } else {
          setIsRefetching(true);
        }
    
        try {
    
          const jsonAdjustmentsResponse = await Utils.getData(
            ('/api/employee_adjustment?effective_date='+effectiveDate+'&status='+adjustmentStatus), 
            instance, 
            account
          );
          setAdjustmentsData(jsonAdjustmentsResponse);
          setRowCount(jsonAdjustmentsResponse.length);
    
        } catch (error) {
          setIsError(true);
          console.error(error);
          return;
        }
    
        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
    };
    
    
    useEffect(() => {
        fetchData();
    }, []);


      
    const closeAdjustmentByAdjustmentId = (adjustmentId) =>{
      if(window.confirm("Are you sure you would like to close this adjustment?")){
        Utils.postData(
          "/api/employee_adjustment/close?adjustment_id=" + adjustmentId,
          {},
          instance, 
          account
        ).then((data) => {
  
          fetchData();
  
          if(data && data.result && data.result ==="FAILED"){
            alert(data.message);
          }else{
            alert("Adjustment successfully closed");
          }
  
        });
        
      }
      return false;
    }

      
    const cellStyleHandler = (cell) => {
      // console.log("CELL", cell.row.original);
      let rowColor = "#FFF";
      if(cell.row.original.status === "Terminated"){
        rowColor = "#F66"
      }
      return ({
        sx: {
          backgroundColor: rowColor
        },
      });
    }


    //should be memoized or stable
    const columns = useMemo(
      () => [
        {
            accessorKey: 'view_adjustment',
            header: 'View Adjustment',
            muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
            Cell: ({ cell, row }) => {
                return <div><a href={'/compensation?adjustmentId='+ row.original.id + (adjustmentStatus === "finalized" ? "&status=finalized" : "") + (adjustmentStatus === "closed" ? "&status=closed" : "")}>View</a></div>
            },
            enableEditing: false,
            columnDefType: 'display',
            enablePinning: false
        },         
        {
            accessorFn: (row)=>`${Utils.decodeHTML(row.last_name) + ', ' + Utils.decodeHTML(row.first_name)}`,
            header: 'Employee',
            muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
            enableEditing: false
        },
        {
            accessorFn: (row)=>`${
              Utils.decodeHTML(row.manager_last_name) + ', ' + Utils.decodeHTML(row.manager_first_name)
            }`,
            header: 'Manager',
            muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
            Cell: ({ cell, row }) => {
              return <div>
                  <a href={
                    '/compensation?managerHrisId='+ row.original.manager_hris_id + (adjustmentStatus === "finalized" ? "&status=finalized" : "")}>
                    {
                      Utils.decodeHTML(row.original.manager_last_name) + ', ' + 
                      Utils.decodeHTML(row.original.manager_first_name)
                    }
                  </a>
                </div>
            },            
            enableEditing: false
        }, 
        {
          header: 'Close',
          muiTableBodyCellProps: ({ cell, table }) => { return cellStyleHandler(cell, table); },
          Cell: ({ cell, row }) => {
            return <div>
                <a href="#" onClick={(event)=>{
                  closeAdjustmentByAdjustmentId(row.original.id)
                  return false;
                }}>Close</a>
              </div>;
          },            
          enableEditing: false
      },
      ],
      [],
    );


    const tableHeightValue =  window.innerHeight > 183 ? window.innerHeight - 183 : 300;
    const tableHeightCSS = tableHeightValue + "px";

    return <div>
        <Modal
          isOpen={modalEmployeeListIsOpen}
          onAfterOpen={afterOpenEmployeeListModal}
          onRequestClose={closeEmployeeListModal}
          style={modalStyles}
          contentLabel="Adjustment Employees"
          ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
        >
          <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
            Viewing Adjustment Employees
          </h2>
          <div className="modalBody" style={{ maxHeight: "300px", overflowY: "scroll" }}>
            {adjustmentEmployeeList.map((employeeItem)=>{
              return <div key={Utils.guid()}>
                <span>{Utils.decodeHTML(employeeItem.last_name)}, {Utils.decodeHTML(employeeItem.first_name)}</span>
              </div>
            })}
          </div>
          <div className="modalFooter">
            <button onClick={closeEmployeeListModal}>Close</button>
          </div>
        </Modal> 
          
        <MaterialReactTable 
          renderTopToolbarCustomActions={({ table }) => {
            return <div>
              <span className="sectionTableHeader">
                {adjustmentStatus ==="review" ? "Submitted" : Utils.capitalizeFirstLetter(adjustmentStatus)} Adjustments Effective {effectiveDate}</span>
                &nbsp;&nbsp;&nbsp;             
            </div>
          }} 
          columns={columns} 
          data={adjustmentsData}
          globalFilterFn="contains" 
          enableStickyHeader
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: 'error',
                  children: 'Error loading data',
                }
              : undefined
          }
          renderBottomToolbarCustomActions={({ table }) => {
            return <div style={{"marginTop":"-15px", "fontSize":"10pt", "fontWeight":"bold"}}>
              <div style={{ backgroundColor:"#F66" }}>Red employees have left the company, however an adjustment was created for them prior to leaving.</div>
            </div>
          }}
          muiTableContainerProps={{ sx: { height: tableHeightCSS } }}
          rowCount={rowCount}
          editDisplayMode="row"
          // onEditingRowSave={handleSaveRow}        
          state={{
            isLoading,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            tableData: adjustmentsData
          }}
        />
      </div>;
};

export default Adjustments;