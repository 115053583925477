import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { protectedResources } from "../config/authConfig";

///////////////////////////////////////////////////////////////////

export var capitalizeFirstLetter = (stringValue) => { 
	return stringValue[0].toUpperCase() + stringValue.slice(1); 
};

///////////////////////////////////////////////////////////////////

const s4 = function () {
	return Math.floor((1 + Math.random()) * 0x10000)
		.toString(16)
		.substring(1);
};

export var guid = function () {
	return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
		s4() + '-' + s4() + s4() + s4();
};

///////////////////////////////////////////////////////////////////

export function hexToRgb(hex) {
	// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
	var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	hex = hex.replace(shorthandRegex, function(m, r, g, b) {
		return r + r + g + g + b + b;
	});
  
	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? {
		r: parseInt(result[1], 16),
		g: parseInt(result[2], 16),
		b: parseInt(result[3], 16)
	} : null;
}

///////////////////////////////////////////////////////////////////

function componentToHex(c) {
	var hex = c.toString(16);
	return hex.length === 1 ? "0" + hex : hex;
}

export function rgbToHex(r, g, b) {
	return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function getSpacer() {
	return <div className="spacer">&nbsp;</div>;
}

export function getParams() {
	var pairs = window.location.search.substring(1).split("&"),
		obj = {},
		pair,
		i;

	for ( i in pairs ) {
		if ( pairs[i] === "" ) continue;

		pair = pairs[i].split("=");
		obj[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );
	}

	return obj;
}

export function copy(objectItem) {
	return objectItem ? JSON.parse(JSON.stringify(objectItem)) : null;
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function roundTo(num, nearestTens) {
	if(!parseFloat(num)){
		console.log("Value provided to 'roundTo' function is not a number");
		return "";
	}
	if(!nearestTens || !parseInt(nearestTens)){
		console.log("Value provided to 'nearestTens' must be a tens value (10,100,1000,etc)");
		return "";
	}
	return Math.round(num / nearestTens) * nearestTens;
}

export const decodeHTML = (html) => {
	var txt = document.createElement('textarea');
	txt.innerHTML = html;
	return txt.value;
};

export const moneyMask = (value, trailingDec) => {
	const decimalPlaces = trailingDec !== undefined && trailingDec !== null ? trailingDec : 2;
	// console.log("decimalPlaces", decimalPlaces);
	if(value===undefined || value===null){
		// console.log("VALUE IS UNDEFINED");
		return "$0";
	}
	if(typeof value === "number"){ value = value.toString() }
	// console.log("MONEY value", value);
	value = value.replace(',', '');
	//	value = value.replace('.', '').replace(',', '').replace(/\D/g, '')
  
	const options = { minimumFractionDigits: decimalPlaces }
	const result = new Intl.NumberFormat('en-US', options).format(
	  parseFloat(value).toFixed(decimalPlaces)
	)
  
	// console.log(result)

	return '$' + result
}

export const maskDateAs = (mask, date, divider) => {
	// console.log("type", typeof date, date, mask)
	if(typeof date ==="string"){
		console.log("maskDateAs requires a date object.  Date is of type " +typeof date);
		return "";
	}
	var mm = date.getMonth() + 1; // getMonth() is zero-based
	var dd = date.getDate();
  
	if(mask === "ymd"){
		return [
			date.getFullYear(), (divider || "-"),
			addZ(mm), (divider || "-"),
			addZ(dd)
	   	].join('');
	}
	if(mask === "mdy"){
		return [
			addZ(mm), (divider || "/"),
			addZ(dd), (divider || "/"),
			date.getFullYear()
		].join('');
	}
};


export const addZ =(n) => {return n<10? '0'+n:''+n;}


// const runFetch = async(url, data, method) => {
// 	// Default options are marked with *
// 	const response = await fetch(url, {
// 		method: method, // *GET, POST, PUT, DELETE, etc.
// 		mode: 'cors', // no-cors, *cors, same-origin
// 		cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
// 		credentials: 'same-origin', // include, *same-origin, omit
// 		headers: {
// 		  'Content-Type': 'application/json'
// 		  // 'Content-Type': 'application/x-www-form-urlencoded',
// 		},
// 		redirect: 'follow', // manual, *follow, error
// 		referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
// 		body: JSON.stringify(data) // body data type must match "Content-Type" header
// 	});
// 	return response;
// }

// export async function putData(url = '', data = {}) {
// 	return runFetch(url, data, "PUT");
// }

// export async function postData(url = '', data = {}) {
// 	return runFetch(url, data, "POST");
// }

// export async function deleteData(url = '', data = {}) {
// 	return runFetch(url, data, "DELETE");
// }

const authFetch = async({path, method, headers = new Headers(), data, instance, account, resource = 'apiCompensation', signal}) => {
    const options = {};
    try {
		let isFile = false;
        const authResp = await instance.acquireTokenSilent({
            scopes: protectedResources[resource].scopes,
            account: account
        })
        const bearer = `Bearer ${authResp.accessToken}`;
        headers.append("Authorization", bearer);
        options.headers = headers;
		options.method = method ? method : 'GET';
		if(options.method === "FILE"){
			options.method="POST";
			isFile=true;
		}
		if (signal) { options.signal = signal }
		if (data && !isFile) { options.body = JSON.stringify(data) }
		if (data && isFile) { options.body = data }
        let response = fetch(path, options);
        return response;
    
    } catch(err) {
		console.log('err', err)
        if (err instanceof InteractionRequiredAuthError) {
            if (account) {
                let authRespRetry = await instance.acquireTokenRedirect({
                    scopes: protectedResources.apiCompensation.scopes,
                })
                options.headers.set('Authorization', `Bearer ${authRespRetry.accessToken}`);
                let response = fetch(path, options)
                return response;
            }
        } else if (err.name === 'AbortError'){
			console.info(`Aborted call to ${path}`)
		} else {
			Promise.reject(err)
		}
    }
}

export async function getData(path = '', instance, account, isRaw) {
	const controller = new AbortController();
	const signal = controller.signal;
	let resp = await authFetch({path, instance, account, signal});
	let returnData = !isRaw ? await resp.json() : resp;
	if (resp.status !== 200) throw Error(returnData.message);
	return returnData;	
}

export async function putData(path = '', data = {}, instance, account) {
	const controller = new AbortController();
	const signal = controller.signal;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const resp = await authFetch({path, method: "PUT", data: data, headers, instance, account, signal});
	if (resp.status !== 200){
		throw new Error(`Error posting to ${path}, response: ${JSON.stringify(resp)}`)
	}
	let returnData = await resp.json();
	return returnData;	
}

export async function postData(path = '', data = {}, instance, account) {
	const controller = new AbortController();
	const signal = controller.signal;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const resp = await authFetch({path, method: "POST", data: data, headers, instance, account, signal});
	if (resp.status !== 200){
		throw new Error(`Error posting to ${path}, response: ${JSON.stringify(resp)}`)
	}
	let returnData = await resp.json();
	return returnData;
}

export async function deleteData(path = '', instance, account) {
	const controller = new AbortController();
	const signal = controller.signal;
	let resp = await authFetch({path, method: "DELETE", instance, account, signal});
	if (resp.status !== 200) throw Error(resp);
	let returnData = await resp.json();
	return returnData;	
}

export async function postFile(path = '', data = {}, instance, account) {
	const controller = new AbortController();
	const signal = controller.signal;
	const headers = new Headers();
	const resp = await authFetch({path, method: "FILE", data: data, headers, instance, account, signal});
	if (resp.status !== 200){
		throw new Error(`Error posting to ${path}, response: ${JSON.stringify(resp)}`)
	}
	// let returnData = await resp.json();
	return resp;
}
