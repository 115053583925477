import React  from 'react';
import jQuery from 'jquery';
import * as Utils from '../lib/utils';

window.jQuery = jQuery;
require("jsgrid");
  
const Support = () => {
    return <div style={{ padding: "10px" }}>
      <span>
        <span style={{ fontWeight: "bold" }}>GeoEngineers Employee Compensation System (ECS)</span> was developed by the software engineering team at GeoEngineers.<br />
      </span>
      <div>&nbsp;</div>
      <div>
        <span style={{ fontWeight: "bold" }}>Download the ECS Manager instruction guide:</span><br />
        <a href="/GeoEngineers Inc. Employee Compensation System (ECS) - Managers Instruction Guide.pdf">GeoEngineers Inc. Employee Compensation System (ECS) - Managers Instruction Guide</a>
      </div>
      <div>&nbsp;</div>
      <span>
        Request new features or report any issues at
      </span>
      <span>
        &nbsp;<a href="mailto://softwareengineeringsupport@geoengineers.com">softwareengineeringsupport@geoengineers.com</a>
      </span>
    </div>;
};

export default Support;