import React, { useState, useMemo, useEffect }  from 'react';
import { useMsal, useAccount } from "@azure/msal-react";
import {
  BrowserRouter as Router,
  Navigate,
  Routes,
  Route
} from "react-router-dom";

import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { msalConfig } from "../config/authConfig";

import * as Utils from '../lib/utils';

import Home from "../routes/home";
import HR from "../routes/hr";
import Employee from "../routes/employee";
import AdminReports from "../routes/adminReports";
import EmployeeReports from "../routes/employeeReports";
import SalaryHistory from "../routes/salaryHistory";
import Budget from "../routes/budget";
import EmployeeList from "../routes/employeeList";
import Managers from "../routes/managers";
import Reviewers from "../routes/reviewers";
import Compensation from "../routes/compensation";
import Adjustments from "../routes/adjustments";
// import Pending from "../routes/pending";
import SalaryGroups from "../routes/salaryGroups";
import SalaryGroupRanges from "../routes/salaryGroupRanges";
import SalaryGroupLocations from "../routes/salaryGroupLocations";
import PromotionAdvancement from "../routes/promotionAdvancement";
import Approvals from "../routes/approvals";
import Location from "../routes/location";
import Support from "../routes/support"

import { SignOutMenuItem } from "../components/signOutMenuItem";

import LogoImg from '../images/geo_logo.dcfac9cb-compressed.svg';

const pca = new PublicClientApplication(msalConfig);

const RouterScreen = () => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [isOpen, setIsOpen] = useState(false);
  const [userAccount, setUserAccount] = useState(null);
  const [isUserAccountFetched, setIsUserAccountFetched] = useState(false);
  
  // console.log("account",account)

  const fetchData = async () => {
    try {

      if(account){
        const jsonUserResponse = await Utils.getData(
          ('/api/user/details?email='+account.username), 
          instance, 
          account
        );
        setUserAccount(jsonUserResponse);
        setIsUserAccountFetched(true);

        // console.log(window.location)
        if(window.location.pathname == "" || window.location.pathname == "/"){
          if(jsonUserResponse && jsonUserResponse.access_level && jsonUserResponse.access_level.indexOf("ADMIN") > -1){
            window.location = "/hr"
          }else{
            window.location = "/compensation?managerHrisId="+jsonUserResponse.employee_hris_id;
          }  
        }
        
      }else{
        console.log("Login not set.")
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  useEffect(() => {
    fetchData();
  }, [account]);

  // console.log("userAccount", userAccount);

  const getNavMenu = () => {
    const menuClass = `dropdown-menu${isOpen ? " show" : ""}`;
    
    return <div id="menu" className="dropdown col-sm-6" style={{ textAlign:"right" }} onClick={()=>{
      setIsOpen(!isOpen)
    }}>
      <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style={{ margin:"10px 20px 0px", left: "auto", right: "40px"}}>
        Menu
      </button>
      <ul id="menu-dropdown" className={menuClass} aria-labelledby="dropdownMenuButton1" style={{ left:"auto", right:"20px" }}>
        <li><span style={{"marginLeft":"25px", borderBottom:"1px solid #DDD"}}>v0.3.23</span></li>

        {
          userAccount && userAccount.access_level && userAccount.access_level.indexOf("ADMIN") > -1 ? (<div>
            {/* <li><a className="dropdown-item" href="/home">Home</a></li> */}
            <li><a className="dropdown-item" href="/employeeList">Employee List</a></li>
            <li><a className="dropdown-item" href="/managers">Managers</a></li>
            <li><a className="dropdown-item" href="/hr">HR</a></li>
            <li><a className="dropdown-item" href="/reviewers">Reviewers</a></li>
            <li><a className="dropdown-item" href="/budget">Budgets</a></li>
            <li><a className="dropdown-item" href="/adminReports">Admin Reports</a></li>
            <li><a className="dropdown-item" href="/location">Locations</a></li>
            <li><a className="dropdown-item" href="/salaryGroups">Salary Groups</a></li>    
          </div>) : null
        }

        <li><a className="dropdown-item" href="/employeeReports">Employee Reports</a></li>
        <li><a className="dropdown-item" href="/approvals">Approvals</a></li>
        <li><a className="dropdown-item" target="_BLANK" href="GeoEngineers%20Inc.%20Employee%20Compensation%20System%20(ECS)%20-%20Managers%20Instruction%20Guide.pdf">ECS Manager Instructions</a></li>
        <li><a className="dropdown-item" href="/support">Support</a></li>    
        <SignOutMenuItem />
      </ul>
    </div>;
  }

  const onCompSystemAuthorized = (onAuthorized, onUnauthorized) => {
    if(userAccount && userAccount.access_level && userAccount.access_level.indexOf("ADMIN") > -1){
      return onAuthorized;
    }else{
      return onUnauthorized ? onUnauthorized : <div>{isUserAccountFetched ? "Unauthorized" : ""}</div>;
    }
  }

  const isUserAdmin = userAccount && userAccount.access_level && userAccount.access_level.indexOf("ADMIN") > -1;
  // const userHasAdjustment = userAccount && userAccount.adjustment_uuid;

  return (
    <Router>
      <div className="headerBar">
        <div className="row main-header" style={{ width: "100%", margin: "0", position:"absolute" }}>
          <div 
            id='geo-logo' 
            className="col-sm-6" 
            style={{
              cursor: "pointer"
            }}
            onClick={()=>{
              window.location = "/";
            }}
          >
          
          <img src={LogoImg} style={{width: "150px", margin: "0px 0px 5px 0px"}} alt={"GeoEngineers Logo"} />
            &nbsp;<span style={{fontSize: "14pt"}}>Employee Compensation System (ECS)</span>
          </div>

          <MsalAuthenticationTemplate>
            {getNavMenu()}
          </MsalAuthenticationTemplate>

        </div>
      </div>

      {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
      <div style={{
        position:"absolute",
        top:"70px",
        width: "100%"
      }}>
        <Routes>
          <Route exact path="/home" element={
            <div>
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <Home/>
              </MsalAuthenticationTemplate>
              <UnauthenticatedTemplate>
                <span>You are not currently authenticated, please login to continue.</span>
              </ UnauthenticatedTemplate>
            </div>
          } />

          <Route exact path="/employeeList" element={
              <div>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                  {onCompSystemAuthorized(<EmployeeList />)}
                </MsalAuthenticationTemplate>
                <UnauthenticatedTemplate>
                  <span>You are not currently authenticated, please login to continue.</span>
                </ UnauthenticatedTemplate>              
              </div>
          } />

          <Route exact path="/employee" element={
              <div>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                  <Employee userAccount={userAccount} />
                </MsalAuthenticationTemplate>
                <UnauthenticatedTemplate>
                  <span>You are not currently authenticated, please login to continue.</span>
                </ UnauthenticatedTemplate>              
              </div>
          } />

          <Route exact path="/managers" element={
              <div>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                  {onCompSystemAuthorized(<Managers />)}
                </MsalAuthenticationTemplate>
                <UnauthenticatedTemplate>
                  <span>You are not currently authenticated, please login to continue.</span>
                </ UnauthenticatedTemplate>              
              </div>
          } />

          <Route exact path="/reviewers" element={
              <div>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                  {onCompSystemAuthorized(<Reviewers />)}
                </MsalAuthenticationTemplate>
                <UnauthenticatedTemplate>
                  <span>You are not currently authenticated, please login to continue.</span>
                </ UnauthenticatedTemplate>              
              </div>
          } />

          <Route exact path="/compensation" element={
              <div>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                  <Compensation userAccount={userAccount} />
                </MsalAuthenticationTemplate>
                <UnauthenticatedTemplate>
                  <span>You are not currently authenticated, please login to continue.</span>
                </ UnauthenticatedTemplate>              
              </div>
          } />

          <Route exact path="/salaryHistory" element={
              <div>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                  {onCompSystemAuthorized(<SalaryHistory/>)}
                </MsalAuthenticationTemplate>
                <UnauthenticatedTemplate>
                  <span>You are not currently authenticated, please login to continue.</span>
                </ UnauthenticatedTemplate>              
              </div>
          } />

          <Route exact path="/hr" element={
            <div>
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                {onCompSystemAuthorized(<HR/>)}
              </MsalAuthenticationTemplate>
              <UnauthenticatedTemplate>
                <span>You are not currently authenticated, please login to continue.</span>
              </ UnauthenticatedTemplate>              
            </div>
          } />

          <Route exact path="/adjustments" element={
              <div>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                  {onCompSystemAuthorized(<Adjustments/>)}
                </MsalAuthenticationTemplate>
                <UnauthenticatedTemplate>
                  <span>You are not currently authenticated, please login to continue.</span>
                </ UnauthenticatedTemplate>              
              </div>
          } />
          
          <Route exact path="/budget" element={
              <div>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                  {onCompSystemAuthorized(<Budget/>)}
                </MsalAuthenticationTemplate>
                <UnauthenticatedTemplate>
                  <span>You are not currently authenticated, please login to continue.</span>
                </ UnauthenticatedTemplate>              
              </div>
          } />

          <Route exact path="/adminReports" element={
              <div>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                  {onCompSystemAuthorized(<AdminReports/>)}
                </MsalAuthenticationTemplate>
                <UnauthenticatedTemplate>
                  <span>You are not currently authenticated, please login to continue.</span>
                </ UnauthenticatedTemplate>              
              </div>
          } />

          <Route exact path="/employeeReports" element={
              <div>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                  <EmployeeReports userAccount={userAccount} />
                </MsalAuthenticationTemplate>
                <UnauthenticatedTemplate>
                  <span>You are not currently authenticated, please login to continue.</span>
                </ UnauthenticatedTemplate>              
              </div>
          } />

          <Route exact path="/salaryGroups" element={
              <div>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                  {onCompSystemAuthorized(<SalaryGroups/>)}
                </MsalAuthenticationTemplate>
                <UnauthenticatedTemplate>
                  <span>You are not currently authenticated, please login to continue.</span>
                </ UnauthenticatedTemplate>              
              </div>
          } />

          <Route exact path="/salaryGroupRanges" element={
              <div>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                  {onCompSystemAuthorized(<SalaryGroupRanges/>)}
                </MsalAuthenticationTemplate>
                <UnauthenticatedTemplate>
                  <span>You are not currently authenticated, please login to continue.</span>
                </ UnauthenticatedTemplate>              
              </div>
          } />

          <Route exact path="/salaryGroupLocations" element={
              <div>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                  {onCompSystemAuthorized(<SalaryGroupLocations/>)}
                </MsalAuthenticationTemplate>
                <UnauthenticatedTemplate>
                  <span>You are not currently authenticated, please login to continue.</span>
                </ UnauthenticatedTemplate>              
              </div>
          } />

          <Route exact path="/promotionAdvancement" element={
              <div>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                  {onCompSystemAuthorized(<PromotionAdvancement/>)}
                </MsalAuthenticationTemplate>
                <UnauthenticatedTemplate>
                  <span>You are not currently authenticated, please login to continue.</span>
                </ UnauthenticatedTemplate>              
              </div>
          } />


          <Route exact path="/location" element={
              <div>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                  {onCompSystemAuthorized(<Location/>)}
                </MsalAuthenticationTemplate>
                <UnauthenticatedTemplate>
                  <span>You are not currently authenticated, please login to continue.</span>
                </ UnauthenticatedTemplate>              
              </div>
          } />

          <Route exact path="/approvals" element={
              <div>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                  {<Approvals userAccount={userAccount} />}
                </MsalAuthenticationTemplate>
                <UnauthenticatedTemplate>
                  <span>You are not currently authenticated, please login to continue.</span>
                </ UnauthenticatedTemplate>              
              </div>
          } />

          <Route exact path="/support" element={
              <div>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                  <Support />
                </MsalAuthenticationTemplate>
                <UnauthenticatedTemplate>
                  <span>You are not currently authenticated, please login to continue.</span>
                </ UnauthenticatedTemplate>              
              </div>
          } />

          <Route path="*" element={
            <div>
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                {
                  !userAccount ? 
                  <Home/> : 
                  (
                    !isUserAdmin ? <div></div> : null
                  )
                }
              </MsalAuthenticationTemplate>
              <UnauthenticatedTemplate>
                <span>You are not currently authenticated, please login to continue.</span>
              </ UnauthenticatedTemplate>
            </div>
          } />
        </Routes>
      </div> 

      <div id="footer" className="container-fluid">GeoEngineers 2021</div>
    </Router>
  );
}

export default RouterScreen;
