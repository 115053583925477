import React, { useState, useMemo, useEffect }  from 'react';
import Modal from 'react-modal';
import { useMsal, useAccount } from "@azure/msal-react";
import { MaterialReactTable } from 'material-react-table';
import jQuery from 'jquery';
import * as Utils from '../lib/utils';
import { protectedResources } from "../config/authConfig";
import CONFIG from "../config/index.js";

import "react-datetime/css/react-datetime.css";
// import "../index.css";

window.jQuery = jQuery;
require("jsgrid");

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const debounce = function(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}

const Managers = () => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [dimensions, setDimensions] = useState({ 
      height: window.innerHeight,
      width: window.innerWidth
    });
    
    const [tableData, setTableData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);    
    const [rowCount, setRowCount] = useState(0);

    const [selectedManager, setSelectedManager] = useState(0);
    const [selectedDelegates, setSelectedDelegates] = useState(null);
    const [selectedAddManager, setSelectedAddManager] = useState(0);
    const [modalDelegateIsOpen, setModalDelegateIsOpen] = useState(false);

    let subtitle;


    const triggerLink = (linkId, noToken)=>{
      instance.acquireTokenSilent({
        scopes: protectedResources["apiCompensation"].scopes,
        account: account
      }).then((msalAuthorization)=>{
        //add the token
        let selectedLink = document.getElementById(linkId).href
        if(!noToken){
          selectedLink = selectedLink + "&token=" + (msalAuthorization ? msalAuthorization.accessToken : "")
        } 
        
        //set the link handler
        document.getElementById("linkHandler").href = selectedLink;
        
        //click the link
        document.getElementById("linkHandler").click()
      });
    }  
    

    function openDelegateModal(managerObject, delegatesObject) {
      // console.log("managerObject", managerObject);

      setSelectedManager(managerObject);
      setSelectedDelegates(delegatesObject)
      setModalDelegateIsOpen(true);
    }     
    function afterOpenDelegateModal() {
      // references are now sync'd and can be accessed.
      // subtitle.style.color = '#f00';
    }
    function closeDelegateModal() {
      setModalDelegateIsOpen(false);
    }
    


    useEffect(() => {
      const fetchData = async () => {
        if (!tableData.length) {
          setIsLoading(true);
        } else {
          setIsRefetching(true);
        }
  
        try {

          const jsonResponse = await Utils.getData(
            ('/api/manager'), 
            instance, 
            account
          );

          setTableData(jsonResponse);
          setRowCount(jsonResponse.length);
        } catch (error) {
          setIsError(true);
          console.error(error);
          return;
        }
        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
      };
      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      const debouncedHandleResize = debounce(function handleResize() {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth
        })
      }, 500)

      //binds a resize listener for a debounced (less frequent) resize effect
      window.addEventListener('resize', debouncedHandleResize);
      
      //unbinds listener to prevent memory leaks
      return _ => {
        window.removeEventListener('resize', debouncedHandleResize)   
      };
    });

    //should be memoized or stable
    const columns = useMemo(
      () => [
        {
          accessorKey: 'view_employee',
          header: 'View Employee',
          Cell: ({ cell, row }) => {
            // console.log("cell", cell, "row", row);
            return <div><a href={'/employee?id=' + row.original.id}>View</a></div>
          },
          enableEditing: false,
          columnDefType: 'display',
          enablePinning: false
        },               
        {
          accessorKey: 'employee_hris_id',
          header: 'Employee Number',
          enableEditing: false
        },
        {
          accessorFn: (row) => `${Utils.decodeHTML(row.first_name)}`,
          accessorKey: 'first_name',
          header: 'First Name',
          enableEditing: false
        },
        {
          accessorFn: (row) => `${Utils.decodeHTML(row.last_name)}`,
          accessorKey: 'last_name',
          header: 'Last Name',
          enableEditing: false
        },
        {
          accessorKey: 'download_reports',
          header: 'Download Salaries Report',
          Cell: ({ cell, row }) => {    
            console.log("row", row, "cell", cell);        
            return <div>
              <a 
                id={"changesAndPromotions" + row.original.employee_hris_id}
                onClick={(event)=>{
                  event.preventDefault()
                  triggerLink("changesAndPromotions" + row.original.employee_hris_id);
                  return false;
                }}
                href={CONFIG.API_HOST + '/api/manager/teamReport/' + row.original.employee_hris_id + "?d=true"}
              >
                Download
              </a>
            </div>;
          },
          enableEditing: false,
          columnDefType: 'display',
          enablePinning: false
        }, 
        {
          accessorKey: 'view_delegates',
          header: 'Delegates',
          Cell: ({ cell, row }) => {            
            return <div>
              <a href="#" onClick={(event)=>{
                event.preventDefault();
                // console.log("cell", cell, "row", row);

                Utils.getData(
                  ('/api/manager/' + cell.row.original.id + '/delegate'), 
                  instance, 
                  account
                ).then((jsonSelectedDelegates)=>{

                  openDelegateModal(cell.row.original, jsonSelectedDelegates);
                
                });

                return false;
              }}>Manage</a>
            </div>;
          },
          enableEditing: false,
          columnDefType: 'display',
          enablePinning: false
        }, 
        {
          accessorKey: 'no_approval_needed',
          header: 'Manager may approve submissions without approval',
          enableEditing: false,
          Cell: ({ cell, row }) => {            
            return <div>
              <input 
                type="checkbox" 
                name="requiresManagerApproval" 
                id="requiresManagerApproval"
                defaultChecked={row.original.no_approval_needed}
                onChange={(event)=>{
                  // console.log("onChange EVENT", event, event.target.checked, row.original)
                  updateSubmissionApproval(row.original.employee_hris_id, event.target.checked);
                }}

              />
            </div>;
          },          
        },
      ],
      [],
    );


    const addNewDelegate = ()=>{
      // console.log("SAVING delegates", selectedAddManager);
      // console.log("selected Delegates", selectedDelegates);
      // console.log("selectedManager", selectedManager)

      const isDelegateAlreadySelected = selectedDelegates.find((delegateItem)=>{
        return delegateItem.delegate_id == selectedAddManager;
      });
      // console.log("isDelegateAlreadySelected", isDelegateAlreadySelected);
      if(isDelegateAlreadySelected){
        alert("This delegate has already been added for his manager.");
        return false;
      }

      if(!selectedAddManager){
        alert("Please select a manager from the dropdown list to continue.");
        return false;
      }

      Utils.putData(
        ('/api/manager/'+selectedManager.id+'/delegate/'+selectedAddManager),
        {}, 
        instance, 
        account
      ).then((result)=>{
        // console.log("result", result);

        Utils.getData(
          ('/api/manager/' + selectedManager.id + '/delegate'), 
          instance, 
          account
        ).then((jsonSelectedDelegates)=>{

          setSelectedDelegates(jsonSelectedDelegates);
        
        });

      })

      return false;
    };

    const updateSubmissionApproval = (managerHrisId, noApprovalRequired)=>{

      Utils.putData(
        ('/api/manager/'+managerHrisId+"/settings"),
        {
          noApprovalNeeded : noApprovalRequired
        }, 
        instance, 
        account
      ).then((result)=>{
        // console.log("result", result);

      })
      return false;
    };
    
    const handleSelectManager = (option)=> {
      // console.log("option", option);
      const selectedManager = option.value
      setSelectedAddManager(selectedManager);
      return false;
    };


    // console.log("tableData", tableData);
    const tableHeightValue =  window.innerHeight > 183 ? window.innerHeight - 183 : 300;
    const tableHeightCSS = tableHeightValue + "px";

    return <div>
      <Modal
        isOpen={modalDelegateIsOpen}
        onAfterOpen={afterOpenDelegateModal}
        onRequestClose={closeDelegateModal}
        style={modalStyles}
        contentLabel="Manage Delegates"
        ariaHideApp={false} // ignores need for "Modal.setAppElement('#yourAppElement');"
      >
        <h2 className="modalTitle" ref={(_subtitle) => (subtitle = _subtitle)}>
          Manage Delegates for {selectedManager.first_name} {selectedManager.last_name}
        </h2>
        <div className="modalBody">
          {
            selectedDelegates && selectedDelegates.length ? selectedDelegates.map((delegateItem)=>{
              return <div key={Utils.guid()}>
                  <span className="delegateLabel">{Utils.decodeHTML(delegateItem.first_name)}</span>
                  <span className="delegateLabel">{Utils.decodeHTML(delegateItem.last_name)}</span>
                  <span className="delegateLabel"><a href="#" onClick={(event)=>{
                    event.preventDefault();

                    Utils.deleteData(
                      "/api/manager/" + selectedManager.id + "/delegate/" + delegateItem.delegate_id, 
                      instance, 
                      account
                    ).then((data) => {
                
                      Utils.getData(
                        ('/api/manager/' + selectedManager.id + '/delegate'), 
                        instance, 
                        account
                      ).then((jsonSelectedDelegates)=>{
              
                        setSelectedDelegates(jsonSelectedDelegates);
                      
                      });
              
                    });                    

                    return false;
                  }}>Remove</a></span>
                </div>;
            }) : null
          }
          <div>&nbsp;</div>
          <div>
            <select name='managers_list' value={selectedAddManager} onChange={(event)=>{
              event.preventDefault();
              // console.log("event", event.target.value)
              handleSelectManager(event.target);
              return false;
            }}>
              <option key={Utils.guid()} value="0">-- None Selected --</option>
              {tableData ? tableData.map((manager_item)=>{
                return <option key={Utils.guid()} value={manager_item.id}>{Utils.decodeHTML(manager_item.last_name)}, {Utils.decodeHTML(manager_item.first_name)}</option>
              }) : null}
            </select>&nbsp;
            <a href="#" onClick={(event)=>{
              event.preventDefault();
              
              addNewDelegate();

              return false;
            }}>Add As Delegate</a>
          </div>
        </div>
        <div className="modalFooter">
          <button onClick={closeDelegateModal}>Close</button>
        </div>
      </Modal>

      <MaterialReactTable 
        renderTopToolbarCustomActions={({ table }) => {
          return <div>
            <span className="sectionTableHeader">Managers List</span>
            &nbsp;&nbsp;&nbsp;             
          </div>
        }}            
        columns={columns} 
        data={tableData}
        globalFilterFn="contains" 
        enableStickyHeader
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: 'error',
                children: 'Error loading data',
              }
            : undefined
        }        
        muiTableContainerProps={{ sx: { height: tableHeightCSS } }}
        rowCount={rowCount}
        state={{
          isLoading,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
          tableData: tableData
        }}
      />
  
      <a href="#" id="linkHandler" style={{ visibility:"hidden" }}></a>
    </div>;
};

export default Managers;